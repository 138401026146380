import React, { Component, location, history } from 'react';
import { observer } from "mobx-react";
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import { withFirestore, FirestoreCollection } from 'react-firestore';

import { withFirebase } from 'firebase';


// import { observer } from 'mobx-react';
import SideBar from '../../SideBar/SideBar';
import HeaderBackend from '../HeaderBackend/HeaderBackend';
import PhotoElements from '../PhotoElements/PhotoElements';
import PhotoOverlay from '../PhotoOverlay/PhotoOverlay';
import PhotoOverlayOptions from '../PhotoOverlay/PhotoOverlayOptions';
import ListSettings  from '../ListSettings/ListSettings';


const PhotoPage = observer(
  class PhotoPage extends Component {

    // holds the timer for setTimeout and clearInterval
    movement_timer = null;

    // the number of ms the window size must stay the same size before the
    // dimension state variable is reset
    RESET_TIMEOUT = 100;

    constructor(props) {
      super(props);
      this.setSnapshot = this.setSnapshot.bind(this);
      this.hidePhotoOverlay = this.hidePhotoOverlay.bind(this);
      this.hidePhotoOverlayOptions = this.hidePhotoOverlayOptions.bind(this);
      this.handleListState = this.handleListState.bind(this);
      this.toggleSelectModus = this.toggleSelectModus.bind(this);
      this.deleteSelected = this.deleteSelected.bind(this);
      this.stopDeleteModus = this.stopDeleteModus.bind(this);
      this.snapshotsReloaded = this.snapshotsReloaded.bind(this);
      this.reloadSnapshots = this.reloadSnapshots.bind(this);
      this.state = {
        isOverlayVisible: false,
        isOverlayOptionsVisible: false,
        snapshot: undefined,
        overlayWidth: 0,
        overlayHeight: 0,
        selectModus: false,
        deleteOn: false,
        reloadSnapshots: false,
        listType: "tiles", // [tiles, small, list]
      };

      this.targetRef = React.createRef();
    }

    snapshotsReloaded(){
      this.setState({reloadSnapshots: false});
    }

    reloadSnapshots(){
      this.setState({reloadSnapshots: true});
    }

    componentDidMount() {
      const { match: { params } } = this.props;
      window.analytics.page('my-photos');

      if(params.sid){
        this.downloadSnapshot(params.sid);
      }

      // add resizing event listener
      window.addEventListener('resize', ()=> {
        clearInterval(this.movement_timer);
        this.movement_timer = setTimeout(this.updateOverlaySize(), this.RESET_TIMEOUT);
      });
      this.updateOverlaySize();

    }

    toggleSelectModus(){
      this.setState({ selectModus: !this.state.selectModus });
    }

    deleteSelected(){
      this.setState({ selectModus: false, deleteOn: true });
    }

    // TODO: REFACTOR
    stopDeleteModus(){
      this.setState({deleteOn: false});
    }

    async downloadSnapshot(sid){
      const { firestore } = this.props;
      const currSnapshot = await firestore.collection('Snapshots').doc(sid).get();
      this.setSnapshot(currSnapshot);
    }

    updateOverlaySize(){
      if(this.targetRef && this.targetRef.current){
        const currentWidth = this.targetRef.current.offsetWidth;
        this.setState({
          overlayWidth: currentWidth,
          overlayHeight: window.innerHeight
        });
      }
    }



    hidePhotoOverlay(event){
      // event.preventDefault();
      if (event.target === event.currentTarget) {
        this.setState({ isOverlayVisible: false });
      }
    }

    hidePhotoOverlayOptions(event){
      // event.preventDefault();
      if (event.target === event.currentTarget) {
        this.setState({ isOverlayOptionsVisible: false });
      }
    }

    setSnapshot(snapshot){

      if(snapshot.data().status === "completed" && !snapshot.data().isDraft){
        this.setState({
          isOverlayVisible: true,
          snapshot: snapshot
        });
      }else if(snapshot.data().status === "completed" && snapshot.data().isDraft){
        this.setState({
          isOverlayOptionsVisible: true,
          snapshot: snapshot
        });
      }

      window.analytics.track(
        'Look at Photo',{
          sid: snapshot.id,
          environmentId: snapshot.data().metadata.environmentId,
          environmentName: snapshot.data().metadata.sceneName,
          user: snapshot.data().uid,
          oid: snapshot.data().oid
        }
      );




      // window.analytics.track({
      //   event: 'Look at Photo',
      //   userId: this.props.isSignedIn ? this.props.firebaseUser.uid : undefined,
      //   properties: {
      //     sid: snapshot.id,
      //     environmentId: snapshot.data().metadata.environmentId,
      //     environmentName: snapshot.data().metadata.sceneName,
      //     user: snapshot.data().uid,
      //     oid: snapshot.data().oid
      //   }
      // });
    }

    handleListState(newListState){
      window.analytics.track('List View Change', { newState: newListState });
      this.setState({ listType: newListState });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
      if(this.props.orgSearch && !prevProps.orgSearch){
        window.analytics.page('org-photos');
      }
      // if(this.props.firebaseUser){
      //   const idTokenResult = await this.props.firebaseUser.getIdTokenResult();
      //   console.log(idTokenResult.claims);
      // }
      //const user = this.props.firebase.auth().currentUser;
      // const idTokenResult = await user.getIdTokenResult();

    }


    // interaction
    render() {

      return (
        <div className="backend-container">
          <SideBar active={(this.props.orgSearch ? "org-photos" : "photos")}
                   setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
                   joyRideStepIndex={this.props.joyrideStepIndex}
                   joyrideRunning={this.props.joyrideRunning} />
          <div className="backend-body-container" ref={this.targetRef}>
            <HeaderBackend title={"Photos"} user={this.props.firebaseUser} firebase={this.props.firebase} handleUserUpdate={ this.props.handleUserUpdate} org={this.props.org} />
            <ListSettings listType={this.state.listType} onPress={this.handleListState} toggleSelectModus={this.toggleSelectModus} selectModus={this.state.selectModus} isSelectModusVisible={!this.props.orgSearch} deleteSelected={this.deleteSelected}/>
          { this.props.isSignedIn ?
            (
              <div>
                <PhotoElements user={this.props.firebaseUser} reload={this.state.reloadSnapshots} snapshotsReloadedFunction={this.snapshotsReloaded} onPress={this.setSnapshot} listType={this.state.listType} org={this.props.org} orgSearch={this.props.orgSearch} firebase={this.props.firebase} store={this.props.store} selectModus={this.state.selectModus} isSelectModusVisible={!this.props.orgSearch} isOrg={this.props.orgSearch} deleteOn={this.state.deleteOn} stopDeleteModus={this.stopDeleteModus}  />
                <PhotoOverlay  isVisible={this.state.isOverlayVisible} snapshot={this.state.snapshot}  hideFunction={this.hidePhotoOverlay} width={this.state.overlayWidth} height={this.state.overlayHeight}  />
                {this.state.isOverlayOptionsVisible && (<PhotoOverlayOptions  isVisible={this.state.isOverlayOptionsVisible} reloadSnapshotsFunction={this.reloadSnapshots} org={this.props.org} snapshot={this.state.snapshot} rendercost={this.props.rendercost} hideFunction={this.hidePhotoOverlayOptions} width={this.state.overlayWidth} height={this.state.overlayHeight}  />) }

              </div>
            ) : (
              <div className="div-block-20">
                <div className="text-block-30">Ups. You are not signed in!</div>
                <Link to="/login" className="regular-button greyed">
                  <div>Sign In</div>
                </Link>
              </div>
            )}
            </div>
      </div>
      );
    }
  }
);





export default withFirestore(PhotoPage);
