
import React, { Component, location, history } from 'react';
import avatar from '../../../images/noun_User_103879.svg';
import { Route, Link } from "react-router-dom";

import {
  SearchBox,
} from 'react-instantsearch-dom';
import './Nav.css';
import UserMenu from '../../photos/UserMenu/UserMenu';

import Categories from '../categories/Categories';
import logo from "../../../images/zerolens-logo.svg";
import hamburger_menu from "../../../images/noun_menu_500623_333333.svg";

const DARK_MODE = false;

class Nav extends Component {
  render() {
    return (
      <div className="nav-bar-container">
        <div data-collapse="medium" data-animation="default" data-duration="400" className={"navbar-2 w-nav " + (DARK_MODE ? " dark-mode " : "")}>
          <div className="hamburger-menu w-embed" onClick={() => {this.props.openMenu()}}>
            <span dangerouslySetInnerHTML={{__html: "<svg fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" data-name=\"Layer 1\" viewbox=\"0 0 100 100\" x=\"0px\" y=\"0px\"><title>basic uı ux </title><path d=\"M62.19,56.25a6.25,6.25,0,0,0,0-12.5H12.75a6.25,6.25,0,0,0,0,12.5Z\"></path><path d=\"M93.5,17.11a6.28,6.28,0,0,0-6.25-6.25H12.75a6.25,6.25,0,0,0,0,12.5h74.5A6.32,6.32,0,0,0,93.5,17.11Z\"></path><path d=\"M12.75,89.14H45.3a6.25,6.25,0,0,0,0-12.5H12.75a6.25,6.25,0,0,0,0,12.5Z\"></path></svg>"}} />
          </div>
          <Link to="/" className="w-nav-brand">
            <div className={"menu-logo w-embed"  + (DARK_MODE ? " dark-mode " : "")}>
              <span dangerouslySetInnerHTML={{__html: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewbox=\"0 0 100.986 30.358\">\n" +
                "  <g id=\"logo_blue-01\" transform=\"translate(0)\">\n" +
                "    <g id=\"Group_5\" data-name=\"Group 5\" transform=\"translate(37.668 5.927)\">\n" +
                "      <g id=\"Group_4\" data-name=\"Group 4\">\n" +
                "        <path id=\"Path_1\" data-name=\"Path 1\" d=\"M121.38,18a6.98,6.98,0,1,0,6.98,6.98A6.984,6.984,0,0,0,121.38,18Zm0,10.471a3.49,3.49,0,1,1,3.49-3.49A3.492,3.492,0,0,1,121.38,28.471Z\" transform=\"translate(-114.4 -18)\" fill=\"currentColor\"></path>\n" +
                "        <circle id=\"Ellipse_1\" data-name=\"Ellipse 1\" cx=\"3.49\" cy=\"3.49\" r=\"3.49\" transform=\"translate(3.49 17.451)\" fill=\"currentColor\"></circle>\n" +
                "      </g>\n" +
                "    </g>\n" +
                "    <path id=\"Path_2\" data-name=\"Path 2\" d=\"M10.8,21.535c-.3.329-.724.79-1.218,1.35-.527.56-1.054,1.185-1.646,1.877S6.75,26.178,6.157,26.935s-1.12,1.482-1.613,2.173h6.388v3.1H0V30c.362-.593.757-1.251,1.251-1.943S2.272,26.672,2.8,25.948c.527-.691,1.087-1.383,1.613-2.041s1.021-1.251,1.482-1.778H.3V19H10.767v2.535Z\" transform=\"translate(0 -12.744)\" fill=\"currentColor\"></path>\n" +
                "    <path id=\"Path_3\" data-name=\"Path 3\" d=\"M37.7,25.046a7.843,7.843,0,0,1,.527-3.062,6.528,6.528,0,0,1,1.416-2.206,5.751,5.751,0,0,1,2.009-1.317A6.425,6.425,0,0,1,43.989,18a5.805,5.805,0,0,1,4.445,1.712,7.115,7.115,0,0,1,1.646,5.071,5.582,5.582,0,0,1-.033.724c-.033.263-.033.494-.066.691H41.552a2.532,2.532,0,0,0,1.087,1.844,4.246,4.246,0,0,0,2.568.691,10.135,10.135,0,0,0,2.009-.2,9.811,9.811,0,0,0,1.613-.461l.494,3.029a5.082,5.082,0,0,1-.79.3,8.047,8.047,0,0,1-1.12.263c-.4.066-.856.132-1.317.2a9.959,9.959,0,0,1-1.416.066,8.129,8.129,0,0,1-3.1-.527,5.767,5.767,0,0,1-3.457-3.622A8.336,8.336,0,0,1,37.7,25.046ZM46.491,23.6a4.152,4.152,0,0,0-.165-.922,2.3,2.3,0,0,0-.428-.79,2.592,2.592,0,0,0-.724-.593,2.21,2.21,0,0,0-1.087-.23,2.67,2.67,0,0,0-1.087.2,2.165,2.165,0,0,0-.757.56,2.23,2.23,0,0,0-.461.823,4.5,4.5,0,0,0-.23.955Z\" transform=\"translate(-25.287 -12.073)\" fill=\"currentColor\"></path>\n" +
                "    <path id=\"Path_4\" data-name=\"Path 4\" d=\"M92.068,21.789c-.329-.1-.724-.165-1.185-.263a6.775,6.775,0,0,0-1.449-.132c-.23,0-.527.033-.856.066a5.974,5.974,0,0,0-.724.132V31.667H84.1V19.188a19.65,19.65,0,0,1,2.371-.659,14.548,14.548,0,0,1,3.062-.329,6.109,6.109,0,0,1,.724.033c.3.033.56.066.856.1a7.841,7.841,0,0,1,.856.165,7.169,7.169,0,0,1,.724.2Z\" transform=\"translate(-56.409 -12.207)\" fill=\"currentColor\"></path>\n" +
                "    <path id=\"Path_5\" data-name=\"Path 5\" d=\"M170.135,19.723a9.209,9.209,0,0,1-2.634-.362,4.243,4.243,0,0,1-1.613-.922,2.883,2.883,0,0,1-.79-1.416,8.306,8.306,0,0,1-.2-1.91V.593L168.588,0V14.389a7.013,7.013,0,0,0,.066.889,1.311,1.311,0,0,0,.3.691,1.553,1.553,0,0,0,.626.461,2.974,2.974,0,0,0,1.087.23Z\" transform=\"translate(-110.604)\" fill=\"currentColor\"></path>\n" +
                "    <path id=\"Path_6\" data-name=\"Path 6\" d=\"M186.9,25.046a7.843,7.843,0,0,1,.527-3.062,6.528,6.528,0,0,1,1.416-2.206,5.751,5.751,0,0,1,2.009-1.317A6.425,6.425,0,0,1,193.189,18a5.805,5.805,0,0,1,4.445,1.712,7.115,7.115,0,0,1,1.646,5.071,5.578,5.578,0,0,1-.033.724c-.033.263-.033.494-.066.691h-8.5a2.532,2.532,0,0,0,1.087,1.844,4.246,4.246,0,0,0,2.568.691,10.135,10.135,0,0,0,2.009-.2,9.81,9.81,0,0,0,1.613-.461l.494,3.029a5.081,5.081,0,0,1-.79.3,8.047,8.047,0,0,1-1.12.263c-.428.066-.856.132-1.317.2a9.959,9.959,0,0,1-1.416.066,8.129,8.129,0,0,1-3.1-.527,5.767,5.767,0,0,1-3.457-3.622A10.533,10.533,0,0,1,186.9,25.046Zm8.791-1.449a4.153,4.153,0,0,0-.165-.922,2.3,2.3,0,0,0-.428-.79,2.592,2.592,0,0,0-.724-.593,2.21,2.21,0,0,0-1.087-.23,2.67,2.67,0,0,0-1.087.2,2.165,2.165,0,0,0-.757.56,2.23,2.23,0,0,0-.461.823,4.5,4.5,0,0,0-.23.955Z\" transform=\"translate(-125.36 -12.073)\" fill=\"currentColor\"></path>\n" +
                "    <path id=\"Path_7\" data-name=\"Path 7\" d=\"M233.3,18.857a18.325,18.325,0,0,1,2.469-.527,19.285,19.285,0,0,1,3.161-.23,7.7,7.7,0,0,1,2.8.428,4.151,4.151,0,0,1,1.778,1.251,4.7,4.7,0,0,1,.922,1.91,9.471,9.471,0,0,1,.263,2.47V31.6h-3.754v-6.98a5.388,5.388,0,0,0-.461-2.568,1.987,1.987,0,0,0-1.778-.757,7.732,7.732,0,0,0-.856.033q-.445.049-.79.1V31.6H233.3V18.857Z\" transform=\"translate(-156.482 -12.14)\" fill=\"currentColor\"></path>\n" +
                "    <path id=\"Path_8\" data-name=\"Path 8\" d=\"M280.377,28.8a3.658,3.658,0,0,0,1.449-.2.767.767,0,0,0,.428-.79.988.988,0,0,0-.56-.79,8.369,8.369,0,0,0-1.679-.757,11.947,11.947,0,0,1-1.58-.691,4.748,4.748,0,0,1-1.218-.856,3.988,3.988,0,0,1-.79-1.152,4.115,4.115,0,0,1-.263-1.646,3.5,3.5,0,0,1,1.383-2.93,5.955,5.955,0,0,1,3.787-1.087,13.057,13.057,0,0,1,2.3.2,9.986,9.986,0,0,1,1.745.461l-.659,2.9a8.887,8.887,0,0,0-1.416-.4A7.033,7.033,0,0,0,281.6,20.9c-1.185,0-1.745.329-1.745.988a1.228,1.228,0,0,0,.066.4.934.934,0,0,0,.3.329c.165.1.362.23.626.362s.593.263.988.428a15.631,15.631,0,0,1,2.041.889,4.975,4.975,0,0,1,1.251.955,3.1,3.1,0,0,1,.659,1.152,4.817,4.817,0,0,1,.2,1.449,3.362,3.362,0,0,1-1.482,2.963,7.432,7.432,0,0,1-4.149.988,12.172,12.172,0,0,1-2.93-.3A11.091,11.091,0,0,1,275.8,31l.626-3.029a10.167,10.167,0,0,0,1.943.593A8.552,8.552,0,0,0,280.377,28.8Z\" transform=\"translate(-184.988 -12.006)\" fill=\"currentColor\"></path>\n" +
                "  </g>\n" +
                "</svg>"}} />
            </div>
          </Link>
          <nav role="navigation" className="nav-menu w-nav-menu">
            <div className="menu-center-container">
              <SearchBox
                className={"login-text-field menu-search w-input"  + (DARK_MODE ? " dark-mode " : "")}
                autoFocus={true}
                searchAsYouType={false}
                translations={{
                  placeholder: 'Search product templates',
                }}
              />
            </div>
            <div className="menu-right-container">
              <Link to="/my-photos" className={"menu-image-link-container w-inline-block"  + (DARK_MODE ? " dark-mode " : "")}>
                <div className="username">
                  <div className="html-embed-3 w-embed">
                    <span dangerouslySetInnerHTML={{__html: "<svg height=\"20px\" width=\"20px\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" enable-background=\"new 0 0 100 100\" xml:space=\"preserve\"><path d=\"M87.895,14.474h-75.79C8.188,14.474,5,17.661,5,21.579v56.842c0,3.918,3.188,7.104,7.105,7.104h75.79  c3.918,0,7.104-3.188,7.104-7.104V21.579C95,17.661,91.813,14.474,87.895,14.474z M74.279,53.826  c-1.055-1.117-2.467-1.732-3.979-1.732c-1.512,0-2.925,0.615-3.979,1.732L55.584,65.215l-18.205-19.86  c-1.044-1.14-2.45-1.767-3.958-1.767c-1.508,0-2.914,0.627-3.958,1.767L9.737,66.875V21.579c0-1.307,1.062-2.369,2.368-2.369h75.79  c1.307,0,2.367,1.062,2.367,2.369v49.198L74.279,53.826z\"></path><circle cx=\"72.736\" cy=\"36.737\" r=\"8.526\"></circle></svg>"}} />
                  </div>
                  <div>My Photos</div>
                </div>
              </Link>
              {
                this.props.user
                  ? (
                    <div className={'inline-flex hover-show-user-menu'}>
                      <UserMenu firebase={this.props.firebase} handleUserUpdate={this.props.handleUserUpdate} org={this.props.org} handleUserUpdate={ this.props.handleUserUpdate}/>
                      <div className="user-image-char w-inline-block">
                        <div>{this.props.user.displayName ? this.props.user.displayName.charAt(0).toUpperCase() : ""}</div>
                      </div>
                    </div>
                  )
                  : (
                    <div className="div-block">
                      <Link to="/login" className="header-menu-sign-in w-inline-block">
                        <div className="sign-in-main-menu-normal">Sign In</div>
                      </Link><Link to="/signup" className="sign-up-container w-inline-block">
                      <img src={avatar} alt="" className="sign-in-user-image" />
                      <div className="sign-in-main-menu">Sign Up for Free</div></Link>
                    </div>
                  )
              }

            </div>
          </nav>
          <div className="menu-button w-nav-button">
            <div className="w-icon-nav-menu"></div>
          </div>
        </div>
        <Categories categories={this.props.cms.main} />
      </div>
    )
  }
}

export default Nav;
