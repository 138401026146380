import React, { Component, location, history } from 'react';
import {
  SearchBox,
} from 'react-instantsearch-dom';
import '../page_elements/hero/Hero.css';




class FeedHeading extends Component {
  render() {


    return this.props.isVisible ? (
      <div className="container">
        <h1 style={{textTransform: 'capitalize', marginTop: '2.5rem', marginBottom: '2rem'}}>{this.props.query} Environments</h1>
      </div>
    ) : (
      <div></div>
    )
  }
}

export default FeedHeading;
