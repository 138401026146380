import React, { Component, location, history } from 'react';
import { observer } from "mobx-react"
import  { Redirect } from 'react-router-dom'



// import { observer } from 'mobx-react';
const ListSettings = observer(
  class ListSettings extends Component {


    // interaction
    render() {
      return (
        <div className="list-settings-sort-container">
          <div className="list-view-types-container">
            <div className={ "tiles-size-icon w-embed " + (this.props.listType === "tiles" ? "active" : "" )} onClick={ () => {this.props.onPress("tiles")}}>
              <span dangerouslySetInnerHTML={{__html: "<svg id=\"Layer_1\" width=\"20px\" height=\"20px\" fill=\"currentColor\" data-name=\"Layer 1\"\n" +
                "                   xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 68 68\"><title>noun_tile_normal</title>\n" +
                "                <rect className=\"cls-1\" x=\"38\" width=\"30\" height=\"30\" rx=\"7.06\" ry=\"7.06\"></rect>\n" +
                "                <rect className=\"cls-1\" width=\"30\" height=\"30\" rx=\"7.06\" ry=\"7.06\"></rect>\n" +
                "                <rect className=\"cls-1\" x=\"38\" y=\"38\" width=\"30\" height=\"30\" rx=\"7.06\" ry=\"7.06\"></rect>\n" +
                "                <rect className=\"cls-1\" y=\"38\" width=\"30\" height=\"30\" rx=\"7.06\" ry=\"7.06\"></rect>\n" +
                "              </svg>" }} />
            </div>
            <div className={"tiles-size-icon w-embed "  + (this.props.listType === "small" ? "active" : "" )} onClick={ () => {this.props.onPress("small")}}>
              <span dangerouslySetInnerHTML={{__html: "<svg id=\"Layer_1\" width=\"20px\" height=\"20px\" fill=\"currentColor\" data-name=\"Layer 1\"\n" +
                "                   xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 67.6 67.7\"><title>noun_tiles_many</title>\n" +
                "                <rect x=\"18.1\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"18.1\" y=\"18\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect y=\"18\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"53.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"35.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"53.3\" y=\"18\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"35.3\" y=\"18\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"18.1\" y=\"35.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect y=\"35.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"18.1\" y=\"53.4\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect y=\"53.4\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"53.3\" y=\"35.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"35.3\" y=\"35.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"53.3\" y=\"53.4\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect x=\"35.3\" y=\"53.4\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "              </svg>" }} />
            </div>
            <div className={"tiles-size-icon w-embed " + (this.props.listType === "list" ? "active" : "" )} onClick={ () => {this.props.onPress("list")}} >
              <span dangerouslySetInnerHTML={{__html: "<svg id=\"Layer_1\" width=\"20px\" height=\"20px\" fill=\"currentColor\" data-name=\"Layer 1\"\n" +
                "                   xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 67.6 67.7\"><title>noun_list</title>\n" +
                "                <rect width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect y=\"18\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect y=\"35.3\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <rect y=\"53.4\" width=\"14.3\" height=\"14.3\" rx=\"3.4\" ry=\"3.4\"></rect>\n" +
                "                <path\n" +
                "                  d=\"M81.43,16.26A3.43,3.43,0,0,0,80.1,16H37.4A3.37,3.37,0,0,0,34,19.4v7.5a3.37,3.37,0,0,0,3.4,3.4H80.1A3.43,3.43,0,0,0,81.43,30,3.36,3.36,0,0,0,83.5,26.9V19.4A3.36,3.36,0,0,0,81.43,16.26Z\"\n" +
                "                  transform=\"translate(-15.9 -16)\"></path>\n" +
                "                <path\n" +
                "                  d=\"M81.43,34.26A3.43,3.43,0,0,0,80.1,34H37.4A3.37,3.37,0,0,0,34,37.4v7.5a3.37,3.37,0,0,0,3.4,3.4H80.1A3.43,3.43,0,0,0,81.43,48,3.36,3.36,0,0,0,83.5,44.9V37.4A3.36,3.36,0,0,0,81.43,34.26Z\"\n" +
                "                  transform=\"translate(-15.9 -16)\"></path>\n" +
                "                <path\n" +
                "                  d=\"M81.43,51.56a3.43,3.43,0,0,0-1.33-.26H37.4A3.37,3.37,0,0,0,34,54.7v7.5a3.37,3.37,0,0,0,3.4,3.4H80.1a3.43,3.43,0,0,0,1.33-.26A3.37,3.37,0,0,0,83.5,62.2V54.7A3.36,3.36,0,0,0,81.43,51.56Z\"\n" +
                "                  transform=\"translate(-15.9 -16)\"></path>\n" +
                "                <path\n" +
                "                  d=\"M81.43,69.66a3.43,3.43,0,0,0-1.33-.26H37.4A3.37,3.37,0,0,0,34,72.8v7.5a3.37,3.37,0,0,0,3.4,3.4H80.1a3.43,3.43,0,0,0,1.33-.26A3.37,3.37,0,0,0,83.5,80.3V72.8A3.37,3.37,0,0,0,81.43,69.66Z\"\n" +
                "                  transform=\"translate(-15.9 -16)\"></path>\n" +
                "              </svg>" }} />
            </div>
          </div>
          {this.props.isSelectModusVisible && (
            <div className="list-sort-container">
              {this.props.selectModus && (<div className="delete-photos-button w-embed" onClick={() => {this.props.deleteSelected()}}>
                <span dangerouslySetInnerHTML={{__html: '<svg height="100%" width="100%" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 98 98" style="enable-background:new 0 0 98 98;" xml:space="preserve"><title>31</title><g><path d="M40.8,37.9c-1.7,0-3,1.3-3,3V68c0,1.7,1.3,3,3,3c1.6-0.1,2.9-1.4,3-3V40.9C43.7,39.3,42.4,38,40.8,37.9z"></path><path d="M57.2,37.9c-1.6,0.1-2.9,1.4-3,3V68c0.1,1.6,1.4,2.9,3,3c1.7,0,3-1.3,3-3V40.9C60.2,39.2,58.9,37.9,57.2,37.9z"></path><path d="M83.1,23.1H65.8v-2.9c0-4.4-3.6-8-8-8H40.2c-4.4,0-8,3.6-8,8v2.9H14.9c-1.7,0-3,1.3-3,3c-0.1,1.6,1.2,2.9,2.8,3   c0.1,0,0.1,0,0.2,0h5.3l3.2,49.2c0.2,4.2,3.7,7.6,8,7.5h35.2c4.3,0.1,7.8-3.3,8-7.5l3.2-49.2h5.3c1.6,0.1,2.9-1.2,3-2.8   c0-0.1,0-0.1,0-0.2C86.1,24.4,84.8,23.1,83.1,23.1L83.1,23.1z M38.2,20.2c0-1.1,0.9-2,2-2h17.6c1.1,0,2,0.9,2,2v2.9H38.2V20.2z    M68.6,77.9c0,1-0.8,1.9-1.9,1.9c0,0-0.1,0-0.1,0H31.4c-1,0.1-1.9-0.7-2-1.8c0,0,0-0.1,0-0.1l-3.1-48.8h45.4L68.6,77.9z"> </path></g> </svg>'}} />
              </div>)}
              <div className={"select-photo-button " + (this.props.selectModus ? "active" : "")} onClick={() => {this.props.toggleSelectModus()}}>{this.props.selectModus ? "cancel" : "select"}</div>
            </div>
          )}

        </div>
      );
    }
  }
);





export default (ListSettings);
