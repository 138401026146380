import React, { Component, location, history } from 'react';



const userHandler = class userHandler extends Component {

  async componentDidUpdate(prevProps, prevState, snapshot){
    if(this.props.user && !prevProps.user){
      console.log('FIRED::::', this.props.user);
      console.log('FIRED::::', this.props.user.uid);
      var currUser = this.props.user;
      const idTokenResult = await currUser.getIdTokenResult();
      const customClaims = idTokenResult.claims;
      currUser.claims = customClaims;
      this.props.handleUserUpdate(currUser);
    }else if(prevProps){
      // this.props.handleUserUpdate(undefined);
    }
  }


  render() {
    return(
      <div >
      </div>
    )

  }
};


export default userHandler;
