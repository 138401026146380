import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import CorncerIcon from './../../../images/corners_rect.svg';
import { CSSTransition } from 'react-transition-group';
import { Dropdown } from 'semantic-ui-react'
import { joyRideStepSettings } from '../../helpers/joyrideHelpers/joyrideHelpers';



function gcd_two_numbers(x, y) {
  if ((typeof x !== 'number') || (typeof y !== 'number'))
    return false;
  x = Math.abs(x);
  y = Math.abs(y);
  while(y) {
    var t = y;
    y = x % y;
    x = t;
  }
  return x;
}

const options = [
  { key: 'user', text: 'Account', icon: 'user' },
  { key: 'settings', text: 'Settings', icon: 'settings' },
  { key: 'sign-out', text: 'Sign Out', icon: 'sign out' },
]


function getIconForItem(quality, format){
  if(!format && !quality){return ""}
  if(quality === "web"){
    return "window maximize outline"
  }else if(quality === "print"){
    return "print"
  }else if(quality === "social_media"){
    var returnValue = "mobile alternate";
    returnValue = format.includes("ig") ? "instagram" : returnValue;
    returnValue = format.includes("instagram") ? "instagram" : returnValue;

    returnValue = format.includes("fb") ? "facebook" : returnValue;
    returnValue = format.includes("facebook") ? "facebook" : returnValue;

    returnValue = format.includes("pinterest") ? "pinterest" : returnValue;
    return returnValue;
  }
}

function getBestLargestAreaFitForCanvasByRatio(ratio, canvasWidth, canvasHeight){
  let height = canvasHeight;
  let width = ratio[0] / ratio[1] * canvasHeight;
  let orientation = 'portrait';

  if(width > canvasWidth){
    width = canvasWidth;
    height = ratio[1] / ratio[0] * canvasWidth;
    orientation = 'landscape';
  }

  return { width: width, height: height, orientation: orientation };
}

const MIN_TOP_MARGIN = 40;
const MIN_BOTTOM_MARGIN = 90;
const MIN_LEFT_MARGIN = 40;
const MIN_RIGHT_MARGIN = 40;

const CameraOverlay = observer(class CameraOverlay extends Component {
  imgEl = React.createRef();



  state = {
    gridOn: false,
    isLandscape: false,
    topBarHeight: 0,
    bottomBarHeight: 0,
    leftBarWidth: 0,
    rightBarWidth: 0,
    cameraWidth: 0,
    cameraHeight: 0,
    darken: false,
    imageWidth: 0,
    imageHeight: 0,
    originalWidth: 0,
    originalHeight: 0,
    maxWidth: 0
  }


  orientation = "landscape";

  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
    this.mouseEnter = this.mouseEnter.bind(this);
    this.mouseLeave = this.mouseLeave.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);

    // this.cameraPress = this.cameraPress.bind(this);

  }

  // cameraPress(){
  //   console.log('camera pressed: we are here');
  //   this.props.onPress(this.cameraWidth, this.cameraHeight, this.orientation)
  // }


  toggleGrid(){
    window.analytics.track('Environment Settings Change', { option: "Grid", value: !this.state.gridOn });
    this.setState({ gridOn: !this.state.gridOn });
  }

  // holds the timer for setTimeout and clearInterval
  movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  RESET_TIMEOUT = 100;


  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.x !== this.props.x || prevProps.y !== this.props.y){
      this.updateRatio();
    }
    if(prevProps && prevProps.grid !== this.props.grid || !prevProps && this.props.grid){
      this.setState({ gridOn: this.props.grid });
    }
  }

  handleFormatChange(value){
    this.props.handleGeneralSettings('format', value.join(","));
  }

  componentDidMount() {
    // add resizing event listener
    window.addEventListener('resize', ()=> {
      clearInterval(this.movement_timer);
      this.movement_timer = setTimeout(this.updateRatio(), this.RESET_TIMEOUT);
    });
    this.updateRatio();
  }

  handleCameraReset(){
    console.log('JOYRIDE STEP INDEX: ', this.props.joyRideStepIndex );
    if(this.props.joyrideRunning){
      if(this.props.joyRideStepIndex === joyRideStepSettings.templateResetCameraId ){
        this.props.setJoyRideStepByIndex(joyRideStepSettings.templateResetCameraId + 1);
      }
    }
    this.props.unityContent.send("CameraController", "resetView");
    window.analytics.track('Environment Settings Change', { option: "Camera Reset", value: true });
  }

  updateRatio(){
    if(this.targetRef && this.targetRef.current){
      const currentWidth = this.targetRef.current.offsetWidth - MIN_LEFT_MARGIN - MIN_RIGHT_MARGIN;
      const currentHeight = this.targetRef.current.offsetHeight - MIN_TOP_MARGIN - MIN_BOTTOM_MARGIN;
      const bestFit = getBestLargestAreaFitForCanvasByRatio([this.props.x, this.props.y], currentWidth, currentHeight);

      const topBarHeight = (currentHeight - bestFit.height) / 2 + MIN_TOP_MARGIN;
      const bottomBarHeight = (currentHeight - bestFit.height) / 2 + MIN_BOTTOM_MARGIN;
      const leftBarWidth = (currentWidth - bestFit.width) / 2 + MIN_LEFT_MARGIN;
      const rightBarWidth = (currentWidth - bestFit.width) / 2 + MIN_RIGHT_MARGIN;

      // this.cameraWidth = bestFit.width;
      // this.cameraHeight = bestFit.height;
      this.orientation = bestFit.orientation;

      this.updateImageRatio(this.state.originalWidth, this.state.originalHeight);

      this.setState({
        isLandscape: bestFit.orientation === 'landscape',
        topBarHeight: topBarHeight,
        bottomBarHeight: bottomBarHeight,
        leftBarWidth: leftBarWidth,
        rightBarWidth: rightBarWidth,
        cameraWidth: bestFit.width,
        cameraHeight: bestFit.height
      });

      this.props.setOverflow(bottomBarHeight-topBarHeight);

    }
  }

  updateImageRatio(naturalWidth, naturalHeight){
    var width = 0;
    var height = 0;


    const maxWidth = this.targetRef.current.offsetWidth - MIN_LEFT_MARGIN - MIN_RIGHT_MARGIN;

    console.log('MAXWIDTH: ', maxWidth);
    const maxHeight = this.targetRef.current.offsetHeight - MIN_TOP_MARGIN - MIN_BOTTOM_MARGIN;

    const aspect = naturalHeight/naturalWidth;
    console.log('ASPECT: ', aspect);

    const bestFit = getBestLargestAreaFitForCanvasByRatio([naturalWidth, naturalHeight], maxWidth, maxHeight);

    bestFit.width = bestFit.width ;
    bestFit.height = bestFit.height;

    console.log('BEST FIT: ', bestFit);
    // this.containerRef.current.offsetWidth = bestFit.width;
    this.setState({
      originalWidth: naturalWidth,
      orginalHeight: naturalHeight,
      imageWidth: bestFit.width,
      imageHeight: bestFit.height,
      maxWidth: this.targetRef.current.offsetWidth,
      maxHeight: this.targetRef.current.offsetHeight,
    });

  }

  mouseEnter(){
    this.props.mouseEnter();
    this.setState({darken: true});
  }

  mouseLeave(){
    this.props.mouseLeave();
    this.setState({darken: false});
  }

  handleCameraPress(){
    this.props.onPress(this.state.cameraWidth, this.state.cameraHeight, this.orientation, this.state.bottomBarHeight - this.state.topBarHeight);

    if(this.props.joyrideRunning){
      if(this.props.joyRideStepIndex === joyRideStepSettings.templateTakePhotoId ){
        this.props.setJoyRideStepByIndex(joyRideStepSettings.templateTakePhotoId + 1);
      }
    }

  }


  render() {
    const { onPress, mouseEnter, mouseLeave } = this.props;

    const gcdAspectRatio = this.props.aspect  && this.props.aspect.x && this.props.aspect.y ? gcd_two_numbers(Math.max(this.props.aspect.x, this.props.aspect.y), Math.min(this.props.aspect.x, this.props.aspect.y)) : undefined;


    return (
      <div className="cam-viewer-container" ref={this.targetRef}>
        <CSSTransition
          in={this.props.isShowingPreview}
          timeout={400}
          classNames="hideanimation"
          unmountOnExit
        >
          <img src={this.props.preview} style={{position: "absolute", width: `${this.state.imageWidth}px`, height: this.state.imageHeight, left: ((this.state.maxWidth - this.state.imageWidth)/2), top: ((this.state.maxHeight-MIN_BOTTOM_MARGIN-MIN_TOP_MARGIN-this.state.imageHeight)/2 + MIN_TOP_MARGIN) }} ref={this.imgEl}
               onLoad={() => {this.updateImageRatio(this.imgEl.current.naturalWidth, this.imgEl.current.naturalHeight);}} />
        </CSSTransition>
        <CSSTransition
          in={this.props.isShowingUI}
          timeout={300}
          classNames="hideanimation"
          unmountOnExit
        >
          <div className="cam-viewer-container" >
            <div className={"save-area-cam-viewer " + (this.state.darken ? "darken" : "")} style={{ height: this.state.topBarHeight || 0 }} ></div>
            <div className="cam-viewer-save-area-middle" style={{positon: "relative"}}>
              <div className={"save-area-cam-viewer sides "  + (this.state.darken ? "darken" : "")} style={{ width: this.state.leftBarWidth || 0 }} > </div>
              <div className="aspect-container">
                <img src={CorncerIcon} alt="" className="corner-image top-left" />
                <div className={"grid-container " + (this.state.gridOn ? "" : "hidden")} >
                  <div className="grid-horizontal"> </div>
                  <div className="grid-horizontal lines"> </div>
                  <div className="grid-horizontal"> </div>
                </div>
                <img src={CorncerIcon} alt="" className="corner-image top-right" />
                  <img src={CorncerIcon} alt="" className="corner-image bottom-left" /><img
                  src={CorncerIcon} alt="" className="corner-image bottom-right" />
                  <div className={"grid-container vert "  + (this.state.gridOn ? "" : "hidden")} >
                    <div className="grid-vert"> </div>
                    <div className="grid-vert lines"> </div>
                    <div className="grid-vert"> </div>
                  </div>
              </div>
              <div className={"save-area-cam-viewer sides "  + (this.state.darken ? "darken" : "")} style={{ width: this.state.rightBarWidth }}></div>
            </div>
            <div className={ "save-area-cam-viewer bottom "  + (this.state.darken ? "darken" : "")} style={{ height: this.state.bottomBarHeight }}>
              <div className="cornerblocks"></div>
              <div className="camera-viewer-inputs">
                <div className="grid-button left" onClick={() => {this.handleCameraReset()}}>
                  <span dangerouslySetInnerHTML={{__html: "<svg height='20px' width='20px'  fill=\"#ffffff\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" enable-background=\"new 0 0 100 100\" xml:space=\"preserve\"><g><polygon fill=\"#ffffff\" points=\"64.999,92 65,56 35,56 34.999,92 8,92 8,39.52 50,8.72 92,39.52 92,92  \"></polygon><path fill=\"#ffffff\" d=\"M50,12.44l39,28.6V89H67.999L68,59l0-6h-6H38h-6l0,6l-0.001,30H11V41.04L50,12.44 M50,5L5,38v57h32.999   L38,59h24l-0.001,36H95V38L50,5L50,5z\"></path></g></svg>" }} />
                </div>

                <div className="camer-button-container" onClick={ this.handleCameraPress.bind(this) } onMouseEnter={this.mouseEnter} onMouseLeave={this.mouseLeave}>
                  <div className="div-block-7">
                    <span dangerouslySetInnerHTML={{__html: "<svg fill=\"#ffffff\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" enable-background=\"new 0 0 100 100\" xml:space=\"preserve\"><path fill=\"#ffffff\" d=\"M50,38c-8.271,0-15,6.729-15,15s6.729,15,15,15s15-6.729,15-15S58.271,38,50,38z\"></path><path fill=\"#ffffff\" d=\"M86,23H72c-1.392,0-3.723-0.966-4.708-1.95l-3.343-3.343C61.836,15.594,57.989,14,55,14H45  c-2.989,0-6.836,1.594-8.95,3.708l-3.343,3.343C31.723,22.034,29.392,23,28,23H14c-4.962,0-9,4.038-9,9v45c0,4.962,4.038,9,9,9h72  c4.962,0,9-4.038,9-9V32C95,27.038,90.962,23,86,23z M50,74c-11.58,0-21-9.42-21-21s9.42-21,21-21s21,9.42,21,21S61.58,74,50,74z\"></path></svg>" }} />
                  </div>
                </div>
                {this.props.rendercost &&
                  (<Dropdown
                    className={"aspectRatioDrowpdownViewer"}
                    onChange={this.handleFormatChange}
                    upward floating pointing
                    scrolling
                    icon={null}
                    trigger={<div className="grid-button right w-embed">{gcdAspectRatio && (<div className={"text-block-47"}>{this.props.aspect.x/gcdAspectRatio}:{this.props.aspect.y/gcdAspectRatio}</div>)}
                  </div>}>
                    <Dropdown.Menu>
                    {Object.keys(this.props.rendercost.sizesOptions).map((quality) =>
                      (quality !== "draft") && (
                        <>
                        <Dropdown.Header content={quality.split("_").join(" ").toUpperCase()} />
                        <Dropdown.Divider />
                          { this.props.rendercost.sizesOptions[quality].map((format) =>
                            <Dropdown.Item
                              icon={getIconForItem(quality, format.value)}
                              active={this.props.aspectRatio == format.value && quality == this.props.quality}
                              onClick={() => {this.handleFormatChange([quality, format.value])}}
                              value={[quality, format.value]}
                              text={format.text} />
                          )}
                        </>
                      )
                    )}
                    </Dropdown.Menu>
                  </Dropdown>)
                }


              </div>
              <div className="cornerblocks"></div>
            </div>
          </div>
        </CSSTransition>
      </div>
    );
  }
});


export default CameraOverlay;


