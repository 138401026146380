import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';


function getBestLargestAreaFitForCanvasByRatio(ratio, canvasWidth, canvasHeight){
  let height = canvasHeight;
  let width = ratio[0] / ratio[1] * canvasHeight;
  let orientation = 'portrait';

  if(width > canvasWidth){
    width = canvasWidth;
    height = ratio[1] / ratio[0] * canvasWidth;
    orientation = 'landscape';
  }

  return { width: width, height: height, orientation: orientation };
}

const AspectRatio = observer(class AspectRatio extends Component {
  constructor(props) {
    super(props);
    this.targetRef = React.createRef();
  }


  state = {
    isLandscape: false,
    barWidth: 1,
    barHeight: 1,
  };

  // holds the timer for setTimeout and clearInterval
  movement_timer = null;

  // the number of ms the window size must stay the same size before the
  // dimension state variable is reset
  RESET_TIMEOUT = 100;


  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps !== this.props){
      this.updateRatio();
    }
  }

  componentDidMount() {
    // add resizing event listener
    window.addEventListener('resize', ()=> {
      clearInterval(this.movement_timer);
      this.movement_timer = setTimeout(this.updateRatio(), this.RESET_TIMEOUT);
    });
    this.updateRatio();
  }

  updateRatio(){
    const currentWidth = this.targetRef.current.offsetWidth;
    const currentHeight = this.targetRef.current.offsetHeight;
    const bestFit = getBestLargestAreaFitForCanvasByRatio([this.props.x, this.props.y], currentWidth, currentHeight);


    const barWidth = bestFit.orientation === 'landscape' ? bestFit.width : ( (currentWidth - bestFit.width) / 2 );
    const barHeight = bestFit.orientation === 'landscape' ? ( (currentHeight - bestFit.height) / 2 )  : currentHeight;

    this.setState({
      isLandscape: bestFit.orientation === 'landscape',
      barWidth: barWidth,
      barHeight: barHeight
    });
  }

  render() {
    return (
      <div className="aspect-ratio" ref={this.targetRef}>
        <div className={"aspect-landscape " + (this.state.isLandscape ? 'hidden' : '')}>
          <div className="aspect-landscape-side left" style={{ width: this.state.barWidth, height: this.state.barHeight }}></div>
          <div className="aspect-landscape-side right" style={{ width: this.state.barWidth, height: this.state.barHeight }}></div>
        </div>
        <div className={"aspect-portrait " + (this.state.isLandscape ? '' : 'hidden')} >
          <div className="aspect-portrait-side top" style={{ width: this.state.barWidth, height: this.state.barHeight }}></div>
          <div className="aspect-portrait-side" style={{ width: this.state.barWidth, height: this.state.barHeight }}></div>
        </div>
      </div>
    );
  }
});


export default AspectRatio;
