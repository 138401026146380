import React, { Component, location, history } from 'react';

const ViewerSettingsMultiLevelDropdownSetting = class ViewerSettingsDropdownSetting extends Component {
  render() {
    return (
      <div className="settings-container-settings">
        <div className="settings-text">{this.props.title}</div>
        <div className="form-inside-creator w-form">
          <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <select id="resolution-2" name="resolution-2" data-name="Resolution 2"
                    className="select-inside-creator w-select"
                    value={this.props.value}
                    onChange={(e) => {this.props.handleChange(e.target.value)}}
            >
              {Object.keys(this.props.options).map((quality) =>
                (quality !== "draft") && (
                  <optgroup label={quality.split("_").join(" ").toUpperCase()}>
                    { this.props.options[quality].map((format) =>
                      <option value={[quality, format.value]}>{format.text}</option>
                    )}
                  </optgroup>
                )
              )}
            </select>
          </form>
        </div>
      </div>
    );
  }
};


export default ViewerSettingsMultiLevelDropdownSetting;
