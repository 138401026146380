export function convertModifierTextToTitleWithoutColor(modifierText) {
  modifierText = modifierText.split(" COLOR");
  modifierText = modifierText[0].split(" Color");
  modifierText = modifierText[0].split(" color");
  return modifierText[0];
}

export function returnRecColorsFromProductsInSnapshot(newSnapshot, oldSnapshot){
  if(newSnapshot !== oldSnapshot){
    // constProductArray
  }
}
