import React, { Component, location, history } from 'react';
import { FirestoreDocument, withFirestore } from 'react-firestore';



const CmsHandler = class CmsHandler extends Component {

  async componentDidMount(){
    const { firestore } = this.props;
    firestore.doc('CMS/Main').onSnapshot(snapshot => {
      var CMS = { main: snapshot.data() };
      this.props.handleCmsUpdate(CMS);
      console.log('CMS: ', CMS);
    });
    firestore.doc('CMS/RenderCost').onSnapshot(snapshot => {
      var CMS = snapshot.data();
      CMS.sizesOptions = {};


      for (var topLevelProperty in CMS.sizes) {
        var options = [];
        for(var secondLevelProperty in CMS.sizes[topLevelProperty]){
          var currOption =   CMS.sizes[topLevelProperty][secondLevelProperty];
          currOption.value = secondLevelProperty;
          options.push(currOption);
        }
        CMS.sizesOptions[topLevelProperty] = options;
      }

      console.log('Rendercost: ', CMS);

      this.props.handleCmsUpdateRenderCost(CMS);
    });
    firestore.doc('CMS/PricingCMS').onSnapshot(snapshot => {
      var CMS = snapshot.data();
      this.props.handlePricingCMS(CMS);
      console.log('PricingCms: ', CMS);
    });
  }


  render() {
    return(
      <div >
      </div>
    )

  }
};


export default withFirestore(CmsHandler);
