import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import InfiniteScroll from 'react-infinite-scroller';
import SVG from 'react-inlinesvg';
import ReactTimeAgo from 'react-time-ago';



// import { observer } from 'mobx-react';
const NUMBER_OF_DOCS = 10;


const ProductElements = observer(
  class ProductElements extends Component {


    currLastRef = null;
    stillNewSnapshots = true;

    constructor(props){
      super(props);

      this.state = {
        elements: [],
        stillNewSnapshots: true,
        waitForPrevRequestToFinish: false,
        active: undefined
      };
    }

    formatProductObject(product){
      var activeProduct = product.data() || {};
      activeProduct.id = product.id;
      return activeProduct;
    }

    setProductById(id){
      var filteredProducts = this.state.elements.filter(product => product.id === id);
      var activeProduct = {}
      if(filteredProducts[0]){
        activeProduct = this.formatProductObject(filteredProducts[0]);
      }
      this.props.setActiveProduct(activeProduct);
    }

    fetchSnapshots(page){
      var self = this;
      const { firestore } = this.props;

      // console.log('USER: ', this.props.firebase.auth().currentUser.getIdTokenResult.idTokenResult.claims);
      const user = this.props.firebase.auth().currentUser;
      const idTokenResult = user.getIdTokenResult().then((idTokenResult) => {

        const currUID = idTokenResult.claims.oid ? idTokenResult.claims.oid : "lU2GJwBHgpfseqJVzIkMtjyd3Cw2";

        if(this.stillNewSnapshots && !this.state.waitForPrevRequestToFinish){
          this.setState({ waitForPrevRequestToFinish: true });
          var currElementsArray = self.state.elements;
          if(!this.currLastRef){

            firestore.collection('Products').where("oid", "==", currUID).where("isLive", "==", true).orderBy('name', 'asc').limit(NUMBER_OF_DOCS).get().then((snapshot) => {
              snapshot.docs.forEach((doc) => {
                var currDoc = doc.data();
                currDoc.id = doc.id;
                currElementsArray.push(doc);
              });
              this.currLastRef = snapshot.docs[snapshot.docs.length - 1];

              this.setState({ elements: currElementsArray, stillNewSnapshots: snapshot.docs.length < NUMBER_OF_DOCS ? false : true, waitForPrevRequestToFinish: false });

              // setting active product to first product in que
              var activeProduct = {}
              if(currElementsArray[0]){
                activeProduct = this.formatProductObject(currElementsArray[0]);
              }
              this.props.setActiveProduct(activeProduct);

              this.waitForPrevRequestToFinish = false;
            });
          }else {
            firestore.collection('Products').where("oid", "==", currUID).where("isLive", "==", true).orderBy('name', 'asc').startAfter(this.currLastRef).limit(NUMBER_OF_DOCS).get().then((snapshot) => {
              snapshot.docs.forEach((doc) => {
                var currDoc = doc.data();
                currDoc.id = doc.id;
                currElementsArray.push(doc);

              });
              this.currLastRef = snapshot.docs[snapshot.docs.length - 1];
              this.setState({ elements: currElementsArray, stillNewSnapshots: snapshot.docs.length < NUMBER_OF_DOCS ? false : true, waitForPrevRequestToFinish: false });
            });
          }
        }

      });



    }



    // interaction
    render() {
      return (
        <div className="backend-photos-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.fetchSnapshots.bind(this)}
            hasMore={ (this.state.stillNewSnapshots && !this.state.waitForPrevRequestToFinish )}
            loader={<div className="loader" key={0}>Loading ...</div>}
          >
              {this.state.elements.map( (snapshot, i) => (

                <div className={"single-product-container " + ( (this.props.activeProduct && this.props.activeProduct.id === snapshot.id) ? ' active ' : '') } key={snapshot.id} onClick={() => { this.setProductById(snapshot.id) }}>
                  <img src={snapshot.data().preview} alt="" className="product-preview-image-element" />
                    <div className="product-information-container">
                      <div className="product-property-container text">
                        <div className="text-block-52">{snapshot.data().name}</div>
                        <div className="text-block-53"><ReactTimeAgo date={(snapshot.data().createdAt ? Date.parse(snapshot.data().createdAt) : new Date())} locale={"en"}/></div>
                      </div>
                      <div className="product-property-container">
                        <div className="div-block-59">
                          <div className="div-block-61">
                            <div className="html-embed-7 w-embed">
                              <SVG  src= './svgLoader/color_pallette.svg' />
                            </div>
                          </div>
                          <div className="div-block-60">
                            <div className="text-block-54">Colors</div>
                            <div className="product-colors-container">
                              { (snapshot.data().productColorRecommondation || []).map(color => (
                                      <div className="product-single-color-swatch" style={{backgroundColor: color}}></div>
                                    )
                                  )
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              ))}
          </InfiniteScroll>

        </div>
      );
    }
  }
);





export default withFirestore(ProductElements);

