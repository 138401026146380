import React, { Component, location, history } from 'react';
import Iframe from 'react-iframe';


import SideBar from '../SideBar/SideBar';
import HeaderBackend from '../photos/HeaderBackend/HeaderBackend';

const Docs = class Docs extends Component {

  componentDidMound(){
    window.analytics.page('Help');
  }

  render() {
    return (
      <div className="backend-container">
        <SideBar active={"docs"} />
        <div className="backend-body-container">
          <HeaderBackend title={"Help"} user={this.props.firebaseUser} firebase={this.props.firebase} handleUserUpdate={this.props.handleUserUpdate} org={this.props.org}/>
          <Iframe url="https://zerolens.com/docs/plattform/help"
                  width="100%"
                  height="90%"
                  id="myId"
                  className="docsContainer"
                  display="initial"
                  position="relative"/>
        </div>
      </div>
    );
  }
};


export default Docs;
