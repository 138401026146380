import React, { Component, location, history } from 'react';
import { Dimmer, Loader, Segment, Button } from 'semantic-ui-react';
import CircleLoaderCheckmark from './CircleLoaderCheckmark';
import CircleLoaderChecmkark from './CircleLoaderCheckmark';
import './LoadingCircleOverlay.css';
import { Progress } from 'semantic-ui-react'



const LoadingCircleOverlay = class LoadingCircleOverlay extends Component {

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.isCompleted !== false && this.props.isCompleted){

    }
  }

  render() {
    return (
      <Dimmer active={this.props.show} disabled={!this.props.show} inverted={this.props.isInverted}>
        {this.props.progress > 0 ?
          (<Progress percent={Math.round(this.props.progress * 100) || 0} color='green-zerolens' progress />)
            :
          (<CircleLoaderCheckmark isComplete={this.props.isCompleted}/>)
        }
        <p className={"circle-overlay-text " + (this.props.isInverted ? 'inverted' : '') }>{this.props.title}</p>
        {this.props.showReturnButton && (<Button onClick={this.props.hideFunction} inverted className={ (this.props.isCompleted ? '' : 'hide' ) } >{this.props.hideButtonText}</Button>)}
      </Dimmer>
    );
  }
};


export default LoadingCircleOverlay;
