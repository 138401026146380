import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';

import GearIcon from '../../../images/noun_Settings_2650515.svg';
import ViewerSettingsHeader from '../ViewerSettingsHeader';
import ViewerSettingsDropdownSetting from './ViewerSettingsDropdownSetting';
import ViewerSettingsMultiLevelDropdownSetting from './ViewerSettingsMultiLevelDropdownSetting';

import ViewerSettingsToggleSetting from './ViewerSettingsToggleSetting';

const PhotoSettings = observer(class PhotoSettings extends Component {
  constructor(props){
    super(props);
    this.handleDofChange = this.handleDofChange.bind(this);
    this.changeAutoFocus = this.changeAutoFocus.bind(this);
    this.handleFOVChange = this.handleFOVChange.bind(this);

  }


  handleDofChange(value){
    this.props.handleGeneralSettings('dof', value);
  }


  changeAutoFocus(){
    this.props.handleGeneralSettings('autofocus', !this.props.viewerState.autofocus);
  }

  handleFOVChange(value){
    this.props.handleGeneralSettings('FOV', value);
  }


  render() {

    return(
      this.props.rendercost ? (
        <div className={(this.props.isActive ? '' : 'hidden')}>

          <h3 className="side-panel-inner-heading">Photo Options</h3>
          <div className="scrollable-container">
            <ViewerSettingsToggleSetting title={"Autofocus"} isActive={this.props.viewerState.autofocus} onPress={this.changeAutoFocus} />
            <ViewerSettingsDropdownSetting value={this.props.viewerState.dof} options={this.props.settingsOptions.dofOptions} title={"Depth Of Field"} handleChange={this.handleDofChange} />
          </div>

        </div>
      ) : (<div></div>)
    )
  }
});


export default PhotoSettings;
