import React, { Component, location, history } from 'react';
import { Route, Link, withRouter } from "react-router-dom";
import { Placeholder } from 'semantic-ui-react';


import './Categories.css';

const DARK_MODE = false;


class Categories extends Component {
  render() {
    return (
      <div className={"categories-header"  + (DARK_MODE ? " dark-mode " : "")}>
        <div className="home">
          <Link to="/" className={"categorie-link-container w-inline-block " + (DARK_MODE ? " dark-mode " : "") + (this.props.location.pathname === "/" ? " active": "")  }>
            <div className="text-block-32">Suggested</div>
          </Link>
          <div className="vertical-spacer"></div>
        </div>
        <div className="scroll-horiztonal-categories">
          { this.props.categories.Categories ?
            (this.props.categories.Categories.map( (category) => (
              <Link to={category.link} className={"categorie-link-container w-inline-block"  + (DARK_MODE ? " dark-mode " : "") + (this.props.location.pathname === ("/" + category.link) ? " active": "")  }>
                <div className="text-block-32">{category.title}</div>
              </Link>
            ) )) : (
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>)
          }

          <div className={"div-block-26"  + (DARK_MODE ? " dark-mode " : "")}></div>
        </div>
      </div>
    )
  }
}

export default withRouter(Categories);
