
import React, { Component, location, history } from 'react';
import { Route, Link } from "react-router-dom";
import { Placeholder, Segment, Dimmer, Loader } from 'semantic-ui-react';





class Nav extends Component {
  render() {
    return (
      <div className="fullscreen-loader"><Segment className="fullscreen-loader">
        <Dimmer active>
          <Loader size='massive'>Loading zerolens Studio</Loader>
        </Dimmer>
      </Segment>
      </div>
    )
  }
}

export default Nav;
