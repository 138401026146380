import React, { Component, location, history } from 'react';
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import axios from 'axios';
import { Loader, Segment } from 'semantic-ui-react'


import { observer } from "mobx-react";


// import { observer } from 'mobx-react';

const ErrorMessageSignUp = observer(
  class ErrorMessageSignUp extends Component {
    // interaction
    render() {

      return (
        <div className={"error-container "}>
          <div className={ "error-message" + (this.props.isSuccess ? " success-color " : "") + (this.props.isError ? "" : "hidden")}>
            <div>{this.props.errorMessage}</div>
          </div>
        </div>
      );
    }
  }
);





export default ErrorMessageSignUp;
