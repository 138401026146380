import React, { Component, location, history } from 'react';
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";

import { observer } from "mobx-react";
import { withFirestore } from 'react-firestore';


// import { observer } from 'mobx-react';

const Login = observer(
  class Login extends Component {

    state = {
      email: "",
      password: "",
      errorMessage: "",
      isError: false
    }

    handleEmailChange(e) {
      this.setState({ email: e.target.value });
    };

    handlePasswordChange(e) {
      this.setState({ password: e.target.value });
    };

    async handleLogin(){
      try {
        const user = await this.props.firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password);
        window.analytics.identify(user.uid, {
          name: user.displayName,
          email: user.email
        });
        window.analytics.track('Login');
        this.setState({ isError: false });
      } catch (error) {
        this.setState({ isError: true, errorMessage: error.message });
      }
    }

    componentDidMount(){


      if (this.props.user){
        window.analytics.alias(this.props.user.uid);

        window.analytics.identify(this.props.user.uid, {
          name: this.props.user.displayName,
          email: this.props.user.email
        });
        window.analytics.track('Sign In');

      }


      // this.props.firebase.auth().onAuthStateChanged((user) => {
      //   console.log('auth change');
      //   if (user) {
      //     console.log('USER: ', user);
      //     this.props.handleUserUpdate(user);
      //   } else {
      //     this.props.handleUserUpdate(undefined);
      //   }
      // });


    }

    componentDidUpdate(prevProps, prevState, snapshot){
      if (this.props.firebaseUser){
        window.analytics.identify(this.props.firebaseUser.uid, {
          name: this.props.firebaseUser.displayName,
          email: this.props.firebaseUser.email
        });
        window.analytics.alias(this.props.firebaseUser.uid);
      }
    }

    // interaction
    render() {

      if (this.props.firebaseUser && this.props.firebaseUser.uid){

        return (<Redirect to='/'  />)
      }

      return (
        <div className="full-height">
          <div className="login-panel-right">
            <div className="login-panel-right-header-copy">
              <Link to="/" className="logo-login-container w-inline-block"><img
              src="images/logo_white.svg" alt="" /></Link>
              <Link to="/signup" className="link-block w-inline-block">
                <div className="text-block-6">Sign Up</div>
              </Link>
            </div>
            <div className="div-block-17">
              <div className="div-block-18">
                <h1 className="heading-3">Sign in to <strong className="bold-text">zerolens</strong></h1>
                <div className="w-form">
                  <form id="email-form" name="email-form" data-name="Email Form" onSubmit={ (e) => {e.preventDefault(); this.handleLogin()}}>
                    <input type="text"
                           className="login-text-field w-input"
                           maxLength="256" name="name"
                           data-name="Name"
                           placeholder="E-mail adress"
                           id="name"
                           onChange={ (e) => { this.handleEmailChange(e)  } } />
                    <div className="div-block-19">
                      <input type="password" className="login-text-field w-input"
                             maxLength="256" name="password" data-name="Email"
                             placeholder="Password" id="password" required=""
                             onChange={ (e) => { this.handlePasswordChange(e) } } />
                      <Link to="/password-reset" className="forgot w-button">Forgot?</Link>
                    </div>
                    <input type="submit" value="Sign in" data-wait="Please wait..."
                           className="regular-button full-width bold w-button" onClick={ () => { this.handleLogin() } } />
                  </form>

                  <div className="error-container " >
                    <div className={ "error-message" + (this.state.isError ? "" : "hidden")}>
                      <div>{this.state.errorMessage}</div>
                    </div>
                  </div>
                </div>
                <div className="text-block-7">Don&#x27;t have an account?
                   <Link to="/signup" className="link"> Sign up</Link> now, or sign
                  in with
                </div>
                <div className="social-login-buttons"><div className="social-login w-inline-block" onClick={ this.props.signInWithGoogle }><img
                  src="images/1024px-Google__G__Logo.svg.png" alt="" className="social-logo large" />
                  <div className="text-block-8">Google</div></div></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);





export default (Login);
