/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { Component, location, history, useState } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore } from 'react-firestore';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';


const MaterialPicker = observer(class MaterialPicker extends Component {


  handleClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
    <div onClick={this.handleClick} style={{width: '230px'}}>
      <div className="material-container">
        { this.props.materialOptions.map(material => (
          <div className={"single-material-container " + (material.assetbundle == this.props.material.options.changeableMaterialID ? "active" : "" )} onClick={() => { this.props.onChange(material, this.props.index) }}>
            <img src={material.preview} alt="" className={"material-preview " + (false ? 'active' : '')} />
            <div className="material-name">{material.name}</div>
            <Dimmer className="round-dimmer" active={material.isLoading} disabled={!material.isLoading} inverted>
              <Loader inverted content={Math.round((material.progress || 0) * 100) + " %"} />
            </Dimmer>
          </div>
        ))}
      </div>
        <div className={"close-color-picker"} onClick={() => {this.props.onClose()}}><div className={"close-color-picker-font-element"}>x close</div></div>
    </div>
  )};
});


export default MaterialPicker;
