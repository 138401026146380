import { observable, decorate } from 'mobx';

class EnvStore {
  isViewerVisible = false;
  currentEnvId = "";
  isUnityAlreadyLoaded = false;
  user = undefined;
  currentEnvironmentPreview = '';
  currEnvironmentName = '';
  viewerMode = 'normal';
  currentSnapshotId = "";
  shouldBeClosed = false;

}
decorate(EnvStore, {
  isViewerVisible: observable,
  currentEnvId: observable,
  isUnityAlreadyLoaded: observable,
  user: observable,
  currentEnvironmentPreview: observable,
  currEnvironmentName: observable,
  viewerMode: observable,
  currentSnapshotId: observable,
  shouldBeClosed: observable
});


export default EnvStore;
