import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';

import GearIcon from '../../../images/noun_Settings_2650515.svg';
import ViewerSettingsHeader from '../ViewerSettingsHeader';
import ViewerSettingsDropdownSetting from './ViewerSettingsDropdownSetting';
import ViewerSettingsMultiLevelDropdownSetting from './ViewerSettingsMultiLevelDropdownSetting';

import ViewerSettingsToggleSetting from './ViewerSettingsToggleSetting';

const ViewerSettingsGeneral = observer(class ViewerMenu extends Component {
  constructor(props){
    super(props);
    this.handleAspectRatioChange = this.handleAspectRatioChange.bind(this);
    this.handleDofChange = this.handleDofChange.bind(this);
    this.handleImageQualityChange = this.handleImageQualityChange.bind(this);
    this.changeGravity = this.changeGravity.bind(this);
    this.changeAutoFocus = this.changeAutoFocus.bind(this);
    this.handleFOVChange = this.handleFOVChange.bind(this);
    this.handleFormatChange = this.handleFormatChange.bind(this);

  }

  handleAspectRatioChange(value){
    this.props.handleGeneralSettings('aspectRatio', value);
  }

  handleImageQualityChange(value){
    this.props.handleGeneralSettings('quality', value);
  }

  handleDofChange(value){
    this.props.handleGeneralSettings('dof', value);
  }

  changeGravity(){
    this.props.handleGeneralSettings('gravity-option', !this.props.viewerState.gravity);
  }

  changeAutoFocus(){
    this.props.handleGeneralSettings('autofocus', !this.props.viewerState.autofocus);
  }

  handleFOVChange(value){
    this.props.handleGeneralSettings('FOV', value);
  }

  handleFormatChange(value){
    this.props.handleGeneralSettings('format', value);
  }

  resetComposition(){
    this.props.unityContent.send("CameraController", "resetViewAndComposition");
  }

  render() {

    return(
      this.props.rendercost ? (
        <div className={(this.props.isActive ? '' : 'hidden')}>

          <h3 className="side-panel-inner-heading">General Settings</h3>
          <div className="scrollable-container">
            <ViewerSettingsToggleSetting title={"Gravity"} isActive={this.props.viewerState.gravity} onPress={this.changeGravity} />
            <ViewerSettingsMultiLevelDropdownSetting value={"draft"} value={`${this.props.viewerState.quality},${this.props.viewerState.aspectRatio}`}  options={this.props.rendercost.sizesOptions} title={"Format"} handleChange={this.handleFormatChange} />
            <div className="delete-obj-control-container">

              <div className="delete-button-object-2" onClick={ () => { this.resetComposition() } } >
                <div className="svg-inherit delete w-embed">
              <span dangerouslySetInnerHTML={{__html: "<svg fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" style=\"enable-background:new 0 0 100 100;\" xml:space=\"preserve\"><path d=\"M88.6,50c0-21.3-17.3-38.6-38.6-38.6S11.4,28.7,11.4,50S28.7,88.6,50,88.6S88.6,71.3,88.6,50z M38.3,67.7l-6-6L44,50  L32.3,38.3l6-6L50,44l11.7-11.7l6,6L56,50l11.7,11.7l-6,6L50,56L38.3,67.7z\"></path></svg>\n" +
                "            "}} />
                </div>
                <div className="delete-button-obj-text">Reset Photo to Start</div>
              </div>
            </div>


          </div>

      </div>
      ) : (<div></div>)
    )

  }
});


export default ViewerSettingsGeneral;
