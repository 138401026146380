import React, { Component, location, history } from 'react';

import { connectSearchBox } from 'react-instantsearch-dom';
import { observer } from 'mobx-react/dist/mobx-react';


const CustomSearchBox = class CustomSearchBox extends Component {

  componentWillReceiveProps(nextProps){
    // write your logic here or call to the common method
    if(nextProps.currentSearch != this.props.currentSearch){
      this.props.refine(nextProps.currentSearch);
    }
  }

  render(){
    const { currentRefinement, isSearchStalled, refine, props } = this.props;
    return(
      <form noValidate action="" role="search" className={'relative'} >
        <div className={"remove-search w-embed " + ( !((this.props.currentSearch && this.props.currentSearch.length)  > 0) ? "hidden" : "")} onClick={() => { this.props.onChangeFunction('') }}>
          <span dangerouslySetInnerHTML={{__html: "<svg fill=\"currentColor\" xmlns:dc=\"http://purl.org/dc/elements/1.1/\" xmlns:cc=\"http://creativecommons.org/ns#\" xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\" xmlns:svg=\"http://www.w3.org/2000/svg\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:sodipodi=\"http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd\" xmlns:inkscape=\"http://www.inkscape.org/namespaces/inkscape\" version=\"1.1\" x=\"0px\" y=\"0px\" viewbox=\"0 0 100 100\"><g transform=\"translate(0,-952.36218)\"><path style=\"text-indent:0;text-transform:none;direction:ltr;block-progression:tb;baseline-shift:baseline;color:#000000;enable-background:accumulate;\" d=\"m 50,959.36216 c -23.71271,0 -42.9999999,19.2873 -42.9999999,43.00004 0,23.7127 19.2872899,43 42.9999999,43 23.71271,0 43,-19.2873 43,-43 0,-23.71274 -19.28729,-43.00004 -43,-43.00004 z m 0,6 c 20.47007,0 37,16.5299 37,37.00004 0,20.4701 -16.52993,37 -37,37 -20.47007,0 -37,-16.5299 -37,-37 0,-20.47014 16.52993,-37.00004 37,-37.00004 z m -16.08298,20.525 c -1.29838,1.1881 -1.22208,3.3717 -0.16702,4.4438 l 12,12.03124 -12,12 c -1.23147,1.0753 -1.3077,3.2134 -0.15595,4.3737 1.15175,1.1603 3.29034,1.0998 4.3747,-0.1237 L 50,1006.581 l 12.03125,12.0312 c 1.08436,1.2235 3.22295,1.284 4.3747,0.1237 1.15175,-1.1603 1.07552,-3.2984 -0.15595,-4.3737 l -12,-12 12,-12.03124 c 1.20661,-1.7486 1.01825,-3.1117 -0.19728,-4.4695 -1.4578,-1.0133 -2.777,-0.6467 -4.02147,0.2195 L 50,998.11216 37.96875,986.08096 c -1.41963,-1.1802 -2.66743,-0.9405 -4.05173,-0.1938 z\" fill=\"#ffffff\" fill-opacity=\"1\" stroke=\"none\" marker=\"none\" visibility=\"visible\" display=\"inline\" overflow=\"visible\"></path></g></svg>" }} />
        </div>


        <input
          type="search"
          className={"text-field-2 w-input"}
          value={this.props.currentSearch || ""}
          onChange={event => {refine(event.currentTarget.value); this.props.onChangeFunction(event.currentTarget.value)}}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          placeholder={"search for objects to add"}
        />
      </form>
    )
  }
};

export default connectSearchBox(CustomSearchBox);
