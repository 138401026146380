import React, { Component, history } from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/empty-setup.webflow.css';
import './css/dark-mode.css'
import './css/custom.css'
import App from './App';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import firebaseAppNew from "firebase/app";
import "firebase/auth";
import {
  FirebaseAuthProvider,
  FirebaseAuthConsumer,
  IfFirebaseAuthed,
  IfFirebaseAuthedAnd
} from "@react-firebase/auth";
import { FirestoreProvider } from 'react-firestore';
import withFirebaseAuth from 'react-with-firebase-auth';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { withCookies, Cookies } from 'react-cookie';
import JavascriptTimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';


import { joyRideStyle, joyRideStepSettings, joyRideTemplates } from './components/helpers/joyrideHelpers/joyrideHelpers';


import PhotoPage from './components/photos/PhotoPage/PhotoPage';
import ProductsPage from './components/photos/ProductsPage/ProductsPage';
import Docs from './components/Docs/Docs';
import UserHandler from './userHandler';
import CmsHandler from './cmsHandler';
import OrganizationHandler from './organizationHandler';
import NewOrganization from './components/signup/NewOrganization';
import Signup from './components/signup/SignUp';
import PasswordReset from './components/signup/PasswordReset';
import Login from './components/login/Login';
import Settings from './components/Settings/Settings';
import Success from './components/Success/Success';

import PageLoader from './components/helpers/PageLoader/PageLoader';
import  { Redirect } from 'react-router-dom';
import {Elements, StripeProvider} from 'react-stripe-elements';
import EnvironmentStore from './stores/EnvStore';
import NotSupported from './components/helpers/NotSupported/NotSupported';
import Viewer from './components/viewer/Viewer';
import ViewerMini from './components/viewer/viewer_elements/ViewerMini';
import { useLocalStore } from 'mobx-react' // 6.x or mobx-react-lite@1.4.0
import ViewerContext from './context/ViewerContext';
import { observer } from 'mobx-react/dist/mobx-react';
import { steps } from './functions/interactive-tour/steps';
import { openTutorialTemplate } from './components/helpers/joyrideHelpers/joyrideHelpers';
import axios from 'axios/index';

const DARK_MODE = false;

JavascriptTimeAgo.locale(en);


const config = {
  apiKey: "AIzaSyAlUdkNLuEe12ESwftW9t64U47pzXTSV20",
  authDomain: "pixelstore-4ec98.firebaseapp.com",
  databaseURL: "https://pixelstore-4ec98.firebaseio.com",
  projectId: "pixelstore-4ec98",
  storageBucket: "pixelstore-4ec98.appspot.com",
  messagingSenderId: "291896244314",
  appId: "1:291896244314:web:04d82d05590945e2c90063"
};


const firebaseApp = firebase.initializeApp(config);

// init main store for the whole app. can be injected into any child component from here on with: @inject("store")


class IndexApp extends Component  {



  checkBrowserSupport(){

// Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';

// Chrome 1 - 71
    var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);


    return(isChrome || isFirefox);
  }


  constructor(props) {
    super(props);
    const { cookies } = this.props;

    this.updateUser = this.updateUser.bind(this);
    this.updateCms = this.updateCms.bind(this);
    this.updateOrganization = this.updateOrganization.bind(this);
    this.updateCmsRenderCost = this.updateCmsRenderCost.bind(this);
    this.updatePricingCMS = this.updatePricingCMS.bind(this);
    this.updateStore = this.updateStore.bind(this);
    this.previousLocation = this.props.location;
    this.setDarkMode = this.setDarkMode.bind(this);
    this.setJoyRideStepByIndex = this.setJoyRideStepByIndex.bind(this);
    this.advanceJoyRideStepIndex = this.advanceJoyRideStepIndex.bind(this);
    this.restartJoyRideTour = this.restartJoyRideTour.bind(this);
    //this.renderRedirectOrganization = this.renderRedirectOrganization.bind(this);

    this.state = {
      user: "not defined",
      redirect: false,
      cms: { main: {} },
      organization: undefined,
      rendercost: undefined,
      pricingCMS: undefined,
      browserSupport: undefined,
      store: new EnvironmentStore(),
      darkMode: cookies.get("darkMode") == 'true',
      run: false, // run joyride
      stepIndex: 0
    };
  }


  componentWillUpdate() {

  }

  updateStore(currStore) {
    this.setState({store: currStore});
  }

  setDarkMode(){
    const { cookies } = this.props;
    cookies.remove('darkMode', { path: '/' });
    cookies.set('darkMode', !this.state.darkMode, { path: '/' });
    this.setState({ darkMode: !this.state.darkMode });
  }

  componentDidMount(){
    const { cookies } = this.props;

    // check browser support
    this.setState({browserSupport: this.checkBrowserSupport()});
    // check current user
    // console.log('constructor here: ', cookies.get("user_state"));

    // console.log('CURRENT USER: ', firebaseAppNew.auth().currentUser);


    firebaseAppNew.auth().onAuthStateChanged((user) => {
      console.log('CURRENT USER: auth change');
      if (user) {
        console.log('CURRENT USER:  USER: ', user);
        this.updateUser(user);
      } else {
        this.updateUser(undefined);
      }
    });
  }

  async setUserState(user, state){
    const { cookies } = this.props;

    if(user.claims.state !== state){
      cookies.set('user_state', state, { path: '/' });

      user.claims.state = state;
      this.updateUser(user);

      const response = await axios.post(
        '/api/changestate/' + user.uid,
        {  state: state},
        { headers: { 'Content-Type': 'application/json' } }
      );
    }
  }

  async updateUser(user){
    const { cookies } = this.props;

    var redirect = false;
    var startJoyRoyride = false;
    if(user){
      var claims = await firebaseAppNew.auth().currentUser.getIdTokenResult();
      user.claims = claims.claims;
      user.oid = claims.claims.oid;
      redirect = ( claims.claims.state === "createOrganization" || !user.oid || !claims.claims.state);
      console.log('current state:  redirect: ', redirect);
      if(redirect){this.setState({redirect: true})};
      startJoyRoyride = ( claims.claims.state === "orgCreated" && (  !(cookies.get("user_state") && cookies.get("user_state") != 'created' ) )  );
      console.log('current state: ', claims.claims.state);
      if(user.oid){window.analytics.identify(user.uid, {name: user.displayName, email: user.email, oid: user.oid, retool_org: "https://zerolens.tryretool.com/apps/Organizations?#oid="+user.oid+"=1"})};
    }
    this.setState({ user: user, redirect: redirect, run: startJoyRoyride });
  }

  updateCms(cms){
    this.setState({cms: cms});
  }

  updateOrganization(organization){
      this.setState({organization: organization});
  }

  updateCmsRenderCost(rendercost){
    this.setState({rendercost: rendercost});
  }

  updatePricingCMS(pricingCms){
    this.setState({pricingCMS: pricingCms});
  }

  handleJoyrideCallback = data => {
    const { action, index, status, type, lifecycle } = data;

    console.log('ACTION: ', data);
    if(action == 'close'){
      this.setState({ run: false });
      this.setUserState(this.state.user, 'interactiveTutorialCompleted');
    }

    if(lifecycle === 'beacon'){
      this.setState({ stepIndex: 0, run: false });
    }else{

      if(this.state.stepIndex === joyRideStepSettings.templateOpenId ){
        openTutorialTemplate(this.state.store, this.updateStore, this.props.history)
      }


      if(this.state.stepIndex === joyRideStepSettings.templateClickId){
        var link = document.getElementById(joyRideStepSettings.templateIdPrefix + joyRideTemplates[1].objectID);
        if(link){
          link.click();
        }
      }

      if( [EVENTS.TARGET_NOT_FOUND].includes(type)) {
        console.log('NOT FOUND:');
        setTimeout(() => {
          this.setState({ stepIndex: index });

        }, 150);
      }

      if ([EVENTS.STEP_AFTER].includes(type)) {
        // Update state to advance the tour
        this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
      }
      else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
        // Need to set our running state to false, so we can restart if we click start again.
        this.setState({ run: false });
        this.setUserState(this.state.user, 'interactiveTutorialCompleted');
      }
    }



    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  setJoyRideStepByIndex(index, isDelay){
    if(isDelay){
      setTimeout(
        () => {
          this.setState({ stepIndex: index });
        }, 250);
    }else {
      this.setState({ stepIndex: index });
    }

  }

  restartJoyRideTour(){
    this.setState({ stepIndex: 0, run: true});
  }

  advanceJoyRideStepIndex(){
    this.setState({ stepIndex: this.state.stepIndex + 1 });
  }

  // componentDidMount(){
  //   console.log('this user', this.state.user);
  // }
  //
  // renderRedirectOrganization = () => {
  //   var redirect = false;
  //   if(this.state.user){
  //     redirect = this.state.user.claims.state === "createOrganization";
  //     this.setState({redirect: redirect});
  //   }
  // }

  render() {

    console.log(`LOCATION: ${JSON.stringify(this.props.location)}, prevLocation: ${JSON.stringify(this.previousLocation)}`);

    return(
        <FirebaseAuthProvider firebase={firebaseAppNew} {...config}>
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              return (
                <div className={"body " + ((this.state.darkMode) ? ("dark-mode") : "")}>

                  <StripeProvider apiKey="pk_live_omqshk433kpTpF5yUYjSGOyW00RTINPAAn">
                    <Elements>
                      <ViewerContext.Provider value={{ store: this.state.store, updateStore: this.updateStore }}>
                        <ViewerContext.Consumer>
                          {({store, updateStore}) => (
                            <FirestoreProvider firebase={firebase}>
                              {/*<UserHandler user={user} isSignedIn={isSignedIn} firebase={firebase} handleUserUpdate={ this.updateUser} />*/}
                              <OrganizationHandler user={this.state.user} handleOrgUpdate={ this.updateOrganization } />
                              <CmsHandler user={user} handleCmsUpdate={ this.updateCms} handleCmsUpdateRenderCost={this.updateCmsRenderCost} handlePricingCMS={this.updatePricingCMS} />
                                 <Joyride
                                   steps={steps}
                                   run={this.state.run}
                                   continuous={true}
                                   callback={this.handleJoyrideCallback}
                                   stepIndex={this.state.stepIndex}
                                   styles={joyRideStyle}
                                   disableOverlayClose={true}
                                   hideBackButton={true}
                                   showSkipButton={false}
                                   spotlightClicks={true}
                                   disableCloseOnEsc={true}
                                   disableOverlay={false}
                                   disableScrolling={true}

                                 />

                                <Viewer isVisible={store.isViewerVisible} store={store} environmentId={store.currentEnvId}  user={this.state.user} viewerMode={store.viewerMode} snapshotId={store.currentSnapshotId} rendercost={this.state.rendercost} org={this.state.organization} cms={this.state.cms} updateStore={updateStore} joyrideRunning={this.state.run} joyrideStepIndex={this.state.stepIndex} setJoyRideStepByIndex={this.setJoyRideStepByIndex} restartJoyRideTour={this.restartJoyRideTour} />

                                {/*<Route exact path={["/sid/:sid", "/template/:templateID"]} component={<h1>THIS SHOULD SHOW</h1>} />*/}
                                {/*<Route exact path={["/sid/:sid", "/template/:templateID"]} component={<Viewer isVisible={true} store={store} environmentId={store.currentEnvId}  user={this.props.firebaseUser} viewerMode={store.viewerMode} snapshotId={store.currentSnapshotId} rendercost={this.state.rendercost} org={this.state.organization} cms={this.state.cms} updateStore={updateStore} />} />*/}


                                <ViewerMini isVisible={!store.isViewerVisible && (store.currentEnvId || store.currentSnapshotId )} store={store} updateStore={updateStore} /*tourContext={tourContext}*//>
                                { this.state.redirect &&  (<Redirect to='/new-organization' />) }
                                { this.state.run &&  !this.state.redirect && this.state.stepIndex < 2 &&  (<Redirect to='/' />) }
                                { this.state.user &&  !this.state.redirect && (window.location.pathname == "/login" ||  window.location.pathname == "/signup" || window.location.pathname == "/signup/" ) &&  (<Redirect to='/' />)}
                                { (!this.state.user &&  !this.state.redirect && window.location.pathname != "/login" && window.location.pathname != "/signup" && window.location.pathname != "/password-reset" && window.location.pathname != "/signup/" ) &&  (<Redirect to='/login' />) }
                                {!this.state.browserSupport && (<NotSupported />)}
                                { this.state.user === "not defined" ? (<PageLoader />) : (
                                    <Switch /*location={this.location && this.location.pathname && this.location.pathname.split("sid")[1] ? this.previousLocation : this.location}*/ >
                                      <Route exact path="/my-photos" render={(props) => <PhotoPage {...props} title={'photos | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} rendercost={this.state.rendercost} firebase={firebaseAppNew} handleUserUpdate={this.updateUser} cms={this.state.cms} org={this.state.organization} joyrideRunning={this.state.run} joyrideStepIndex={this.state.stepIndex} setJoyRideStepByIndex={this.setJoyRideStepByIndex} /*tourContext={tourContext}*//> }/>
                                      <Route exact path="/my-photos/:sid" render={(props) => <PhotoPage {...props} title={'photos | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} rendercost={this.state.rendercost} firebase={firebaseAppNew} handleUserUpdate={this.updateUser} cms={this.state.cms} org={this.state.organization} /*tourContext={tourContext}*//> }/>
                                      <Route exact path="/org-photos" render={(props) => <PhotoPage {...props} title={'photos | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} rendercost={this.state.rendercost} firebase={firebaseAppNew} orgSearch={true} handleUserUpdate={this.updateUser} cms={this.state.cms} org={this.state.organization}/*tourContext={tourContext}*/ /> }/>
                                      <Route exact path="/products" render={(props) => <ProductsPage {...props} title={'products | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} rendercost={this.state.rendercost} firebase={firebaseAppNew} handleUserUpdate={this.updateUser} cms={this.state.cms} org={this.state.organization} /*tourContext={tourContext}*//> }/>
                                      <Route exact path="/login" render={(props) => <Login {...props} title={'photos | zerolens'} firebaseUser={isSignedIn} user={this.state.user} firebase={firebaseAppNew} cms={this.state.cms} handleUserUpdate={this.updateUser} signInWithGoogle={() => {
                                        const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                                        firebase.auth().signInWithPopup(googleAuthProvider);
                                      }} />} /*tourContext={tourContext}*/ />
                                      <Route exact path="/password-reset" render={(props) => <PasswordReset {...props} title={'password reset | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} firebase={firebaseAppNew} cms={this.state.cms} handleUserUpdate={this.updateUser} /*tourContext={tourContext}*//> }/>
                                      <Route exact path="/new-organization" render={(props) => <NewOrganization {...props} title={'new Organization | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} firebase={firebaseAppNew} cms={this.state.cms} handleUserUpdate={this.updateUser} /*tourContext={tourContext}*//> }/>
                                      <Route exact path="/signup" render={(props) => <Signup {...props} title={'Sign Up | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} firebase={firebaseAppNew} cms={this.state.cms} handleUserUpdate={this.updateUser} signUpWithGoogle={() => {
                                        const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
                                        firebase.auth().signInWithPopup(googleAuthProvider);
                                      }} /> } /*tourContext={tourContext}*//>

                                      <Route exact path="/docs" component={() => {
                                        window.location.href = 'https://zerolens.webflow.io/university-post/first-steps';
                                        return null;
                                      }}/>
                                      <Route exact path="/settings" render={(props) => <Settings {...props} title={'Settings | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} firebase={firebaseAppNew} handleUserUpdate={this.updateUser} cms={this.state.cms} org={this.state.organization} pricingCMS={this.state.pricingCMS} darkMode={this.state.darkMode} changeDarkMode={this.setDarkMode} /> }/>
                                      <Route exact path="/success" render={(props) => <Success {...props} title={'Success | zerolens'} isSignedIn={isSignedIn} firebaseUser={this.state.user} firebase={firebaseAppNew} handleUserUpdate={this.updateUser} cms={this.state.cms} org={this.state.organization} pricingCMS={this.state.pricingCMS} /*tourContext={tourContext}*/ /> }/>

                                      <Route exact path="/dev" render={(props) => <App {...props} title={'the virtual photostudio | zerolens'} feed="dev_zerolens_feed" firebase={firebaseAppNew}firebaseUser={this.state.user} cms={this.state.cms} org={this.state.organization} handleUserUpdate={this.updateUser} rendercost={this.state.rendercost} handleUserUpdate={this.updateUser} store={store} updateStore={updateStore} />}    />
                                      <Route path="/sid" render={(props) => <App {...props} title={'the virtual photostudio | zerolens'} feed="zerolens_feed" firebase={firebaseAppNew} firebaseUser={this.state.user} cms={this.state.cms} org={this.state.organization} handleUserUpdate={this.updateUser} rendercost={this.state.rendercost} handleUserUpdate={this.updateUser}  store={store} updateStore={updateStore} />}  />
                                      <Route path="/template" render={(props) => <App {...props} title={'the virtual photostudio | zerolens'} feed="zerolens_feed" firebase={firebaseAppNew} firebaseUser={this.state.user} cms={this.state.cms} org={this.state.organization} handleUserUpdate={this.updateUser} rendercost={this.state.rendercost} handleUserUpdate={this.updateUser} store={store} updateStore={updateStore} />}  />
                                      <Route exact path={["/", "/search", "/search/:search", "/:search"]} render={(props) => <App {...props} title={'the virtual photostudio | zerolens'} feed="zerolens_feed" firebase={firebaseAppNew} firebaseUser={this.state.user} cms={this.state.cms} org={this.state.organization}  handleUserUpdate={this.updateUser} rendercost={this.state.rendercost} handleUserUpdate={this.updateUser} store={store} updateStore={updateStore} joyrideRunning={this.state.run} advanceJoyRideStepIndex={this.setJoyRideStepByIndex} />}  />
                                    </Switch>
                                  )}
                            </FirestoreProvider>
                            )}
                        </ViewerContext.Consumer>
                      </ViewerContext.Provider>
                    </Elements>
                  </StripeProvider>
                </div>

              )
            }}
            </FirebaseAuthConsumer>
        </FirebaseAuthProvider>
    )
  }
}





export default withRouter(withCookies(IndexApp));
