import React, { Component, location, history, useRef } from 'react';
import Masonry from 'react-masonry-css';
import { joyRideTemplates, joyRideStepSettings } from './joyrideHelpers';

const breakpointColumnsObj = {
  default: 3,
  950: 2,
  700: 2,
  500: 1
};

export default  (setHitPreview, setNewHitsArray, advanceJoyRideStepIndex) => {

  var hits = joyRideTemplates;

  return (
    <div className="container" id={"sheperd-grid-container"} style={{ marginBottom: "-35px" }} >
      <div className="search-panel">
        <div className="search-panel__results">
          <div>
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {hits.map( (hit) => (

                <div id={joyRideStepSettings.templateIdPrefix + hit.objectID} className="image-element-class" key={hit.objectID} onClick={() => {
                  setHitPreview(hit);
                  setNewHitsArray(hits);
                  advanceJoyRideStepIndex(3);
                }}>
                  <div className={'image-element-hover'}></div>
                  <img src={hit.preview} className="masonry-grid-image" />
                </div>
              ))}
            </Masonry>
          </div>
        </div>
      </div>
    </div>
  );
}
