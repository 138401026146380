import React, { Component, location, history } from 'react';
import { observer } from "mobx-react";
import {RemoveScroll} from 'react-remove-scroll';
import { withFirestore } from 'react-firestore';
import { Route, Link } from "react-router-dom";
import { Dimmer, Loader, Image, Segment, Button } from 'semantic-ui-react';
import axios from 'axios/index';
import CircleLoaderCheckmark from '../../helpers/CircleLoaderCheckmark';



// import { observer } from 'mobx-react';

const MIN_PADDING_TOP = 30;
const MIN_PADDING_BOTTOM = 30 + 72;
const MIN_PADDING_LEFT = 30;
const MIN_PADDING_RIGHT = 30;

function getBestLargestAreaFitForCanvasByRatio(ratio, canvasWidth, canvasHeight){
  // if witdh > height ==> ratio > 1
  let width = 0;
  let height = 0;

  // console.log('RATIO: ', ratio);
  // console.log('canvas width: ', canvasWidth);
  // console.log('canvas height: ', canvasHeight);
  if(ratio > 1){
    height = canvasHeight;
    width = canvasHeight / ratio;

    if(width > canvasWidth){
      width = canvasWidth;
      height = canvasWidth * ratio;
    }
  }else{
    width = canvasWidth;
    height = canvasWidth * ratio;

    if(height > canvasHeight){
      height = canvasHeight;
      width = canvasHeight / ratio;
    }
  }

  return { width: width, height: height }


}

const PhotoOverlayOptions = observer(
  class PhotoOverlayOptions extends Component {
    imgEl = React.createRef();


    state = {
      containerWidth: 0,
      imageHeight: 0,
      rating: undefined,
      selectedOption: this.props.snapshot.data().image.quality,
      selectedRenderCost: this.props.rendercost.quality[this.props.snapshot.data().image.quality][this.props.snapshot.data().image.aspect],
      isSendingSnapshotData: false,
      loadingCircleText: "Waiting",
      isCompleted: false
    }

    constructor(props) {
      super(props);
    }

    handleSnapshotCreation(){
        let snapshotData = this.props.snapshot.data();



        snapshotData.image.quality = this.state.selectedOption;
        snapshotData.isDraft = false;
        snapshotData.status = "created";
        snapshotData.draftId = this.props.snapshot.id;


      const currentRenderCost = this.state.selectedRenderCost;
      const currBalance = this.props.org ? this.props.org.credits : 0;


      if (currBalance > currentRenderCost) {

        this.setState({
          isSendingSnapshotData: true,
          loadingCircleText: "Sending Data..... Your high quality photo will be ready soon. " + currentRenderCost + " Credits will be subtracted from your balance.",
          showReturnButton: false,
          isCompleted: false
        });

        axios.post('/api/snapshot/', snapshotData, { withCredentials: true })
          .then((response) => {
            this.setState({
              isSendingSnapshotData: true,
              isCompleted: true,
              showReturnButton: true,
            });

            window.analytics.track('New Snapshot', {
              aspect: snapshotData.image.aspect,
              quality: snapshotData.image.quality,
              credits: currentRenderCost,
              creditBalance: currBalance,
              oid: snapshotData.oid,
              draftId: snapshotData.draftId,
              isCurrentSnapshotDraft: false,
            });

            this.props.reloadSnapshotsFunction();
          })
          .catch((error) => {
            console.log('error: ', error);
            this.setState({
              isSendingSnapshotData: true,
              loadingCircleText: "AN ERROR OCCURED - Please try again later",
              showReturnButton: true,
            });

            this.props.reloadSnapshotsFunction();
          });
      }else{
        this.setState({
          isSendingSnapshotData: true,
          loadingCircleText: "YOU DON'T HAVE ENOUGH CREDITS TO BUY THIS PHOTO. PLEASE TOP UP YOUR BALANCE",
          showReturnButton: true,
        });
      }
    }

    componentDidUpdate(prevProps, prevState, snapshot){
      if(prevProps !== this.props){
        // this.updateRatio();
        if(prevProps.snapshot !== this.props.snapshot){
          this.setState({ rating: undefined });
        }
      }
    }



    setRating(rating){
      const { firestore } = this.props;
      firestore.collection('Snapshots').doc(this.props.snapshot.id).update({ rating: rating }).then(() => {
        this.setState({ rating: rating });
      });
    }

    updateRatio(naturalWidth, naturalHeight){
      var width = 0;
      var height = 0;
      if(this.props.snapshot){
        const snapshotHeight = this.props.snapshot.data().fovCalc.height;
        const snapshotWidth = this.props.snapshot.data().fovCalc.width;

        const maxWidth = this.props.width > (MIN_PADDING_LEFT + MIN_PADDING_RIGHT ) ? (this.props.width - MIN_PADDING_LEFT - MIN_PADDING_RIGHT) : 0;
        const maxHeight = this.props.height > (MIN_PADDING_TOP + MIN_PADDING_BOTTOM ) ? (this.props.height - MIN_PADDING_TOP - MIN_PADDING_BOTTOM) : 0;
        const aspect = snapshotHeight/snapshotWidth;

        const bestFit = getBestLargestAreaFitForCanvasByRatio(aspect, maxWidth, maxHeight);

        bestFit.width = bestFit.width > naturalWidth ? naturalWidth : bestFit.width;
        bestFit.height = bestFit.height > naturalHeight ? naturalHeight : bestFit.height;

        // this.containerRef.current.offsetWidth = bestFit.width;

        this.setState({
          containerWidth: bestFit.width,
          imageHeight: bestFit.height
        });
      }

    }


    handleOptionChange = changeEvent => {
      this.setState({
        selectedOption: changeEvent.target.value,
        selectedRenderCost: this.props.rendercost.quality[changeEvent.target.value][this.props.snapshot.data().image.aspect]
      });
    };

    // interaction
    render() {
      return (

        <RemoveScroll className={"react-portal " + (this.props.isVisible ? 'show' : 'hidden')} enabled={this.props.isVisible}>
          {this.state.isSendingSnapshotData ? (
            <div className="credits-viewer-container-copy photos-overlay-container" >
                <CircleLoaderCheckmark isComplete={this.state.isCompleted}/>
                <h4 style={{color: "white"}}>{this.state.loadingCircleText}</h4>
                {this.state.showReturnButton && (<Button onClick={(event) => {this.props.hideFunction(event)} } inverted >RETURN</Button>)}
            </div>
            )

              :

              (
              <div className="credits-viewer-container-copy photos-overlay-container" onClick={(event) => { this.props.hideFunction(event)} } >
                <img src="images/noun_X_1890803_1.svg" alt="" className="image-5-copy" onClick={(event) => {this.props.hideFunction(event)} } />
                <div className="div-block-37">
                  <div style={{verticalAlign: "middle", display: "inline-block", maxWidth: this.state.containerWidth < 510 ? this.state.containerWidth + 60 : 510, margin: "35px", display: "flex", "alignItems": "center" }}>
                    <img className={"image-17"} src={ (this.props.snapshot ? this.props.snapshot.data().renderinfo.image_url : "") } style={{maxWidth: "100%", width:"100%", margin: 0}}  ref={this.imgEl}
                         onLoad={() => {this.updateRatio(this.imgEl.current.naturalWidth, this.imgEl.current.naturalHeight *0.7);}} />
                  </div>
                  <div className="hr-white-vertical"></div>
                  <div className="credits-information-text-half-photos">
                    <h4 className="heading-4">Remove Watermark, Choose Final Size</h4>
                    <div className="w-form">
                      <form id="email-form-3" name="email-form-3" data-name="Email Form 3">
                        { Object.keys(this.props.rendercost.sizesOptions).map((quality) =>
                          (quality !== "draft") && (
                            <div>
                              { this.props.rendercost.sizesOptions[quality].map((format) =>
                                ( format.value === this.props.snapshot.data().image.aspect ) && (
                                  <label className="radio-button-field w-radio">
                                    <input type="radio" data-name="Radio" id="radio"
                                           name="radio"
                                           value={quality}
                                           className="w-form-formradioinput w-radio-input"
                                           checked={quality === this.state.selectedOption}
                                           onChange={this.handleOptionChange}
                                    />
                                    <span className="radio-button-label w-form-label" style={{textTransform: "capitalize"}}>{quality.split("_").join(" ")} Quality {format.text}</span>
                                    <div className="text-block-44">({this.props.rendercost.sizes[quality][format.value].x}px x {this.props.rendercost.sizes[quality][format.value].y}px | {this.props.rendercost.quality[quality][format.value]} Credits)</div>
                                  </label>
                                )
                              )}
                            </div>
                          )
                        )}
                      </form>
                    </div>
                    <div className="text-block-43">best fit if you want to get a good grasp on how your final photo will
                      look like
                    </div>
                    <div className="render-preview-button" onClick={() => {this.handleSnapshotCreation()}}>
                      <div className="credits-left-text">BUY {this.state.selectedOption.split("_").join(" ")} PHOTO</div>
                      <div className="div-block-36">
                        <div className="credits-amount-text">{this.state.selectedRenderCost}</div>
                        <img src="images/noun_token_1796108.svg" alt="" className="image-16" />
                        </div>
                    </div>
                      <Link className={"div-block-38"} to={"/sid/" + this.props.snapshot.id} onClick={ () => {  window.analytics.track('Edit Snapshot', { sid: this.props.snapshot.id }); }}>
                        <img src="images/noun_edit_1360261.svg" alt="" className="image-18" /><span className={"link-2"}>Click Here To Edit Photo</span>
                      </Link>
                  </div>
                </div>
                <div className="div-block-40">
                  <div className="text-block-45">Your Current Account Balance</div>
                  <div className="credits-amount-text">{this.props.org ? this.props.org.credits : ""}</div>
                  <img src="images/noun_token_1796108.svg" alt="" className="image-16" /></div>
              </div>

          )}

        </RemoveScroll>
      );
    }

  }
);





export default withFirestore(PhotoOverlayOptions);
