import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import ProductPreview from './ProductPreview';
import { Placeholder } from 'semantic-ui-react';
import { Menu } from 'react-instantsearch-dom';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';
import ViewerSettingsToggleSetting from '../general_settings/ViewerSettingsToggleSetting';
import ToolTip from 'react-portal-tooltip'
import { Route, Link } from "react-router-dom";
import SVG from 'react-inlinesvg';





import CustomSearchBox from './CustomSearchBox';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
} from 'react-instantsearch-dom';
import InfiniteHitsScrollObjects from './CustomInfiniteHitsScrollObjects';
import TextOnlyTooltip from '../../helpers/Tooltip/TextOnlyTooltip';
import InfoTooltip from '../../helpers/Tooltip/InfoTooltip';
import TextOnlyTooltipComponent from '../../helpers/Tooltip/TextOnlyTooltipComponent';

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  'DSARB83COY',
  '1e1748b97732b49386fb7e2f2bcc8ff2',
);

const ZID = "vEpjGTXXWp54zck9w2lE";

const breakpointColumnsObj = {
  default: 3,
  950: 2,
  700: 2,
  500: 1
};

function convertAssetBundleLinkToNewFormt(assetbundle, type){
  if (assetbundle.includes("http")){
    if(type === 'product'){
      assetbundle = 'products/' +assetbundle.split('/products/')[1];
    }else{
      assetbundle = 'lifestyleobjects/' + assetbundle.split('/lifestyleobjects/')[1];
    }
  }
  return assetbundle;
}

const ViewerSettings = observer(class ViewerMenu extends Component {
  state = {
    products: null,
    objects: null,
    devObjects: null,
    searchState: {},
    defaultRefinement: "",
    currenSearch: "",
    devProducts: [],
    objectsLoadingStatus: null,
    objectLoadingStopStatus: null,
    isSwapModeOn: false,
    isAccountWithoutProducts: false,
  };

  showTooltip() {
    this.setState({isTooltipActive: true})
  }
  hideTooltip() {
    this.setState({isTooltipActive: false})
  }

  changeSearch(newSearch){
    this.setState({currentSearch: newSearch});
  }

  toggleSwapMode(){
    this.setState({isSwapModeOn : !this.state.isSwapModeOn});
  }

  constructor(props){
    super(props);
    this.unityContent = this.props.unityContent;
    this.data = [];
    this.changeSearch = this.changeSearch.bind(this);
    this.toggleSwapMode = this.toggleSwapMode.bind(this);
    // this.handleClick = this.handleClick.bind(this);

    this.unityContent.on("ExternalDeselectProducts", () => {

    });


    this.unityContent.on("removeProductAnimation", () => {
      // TODO:
    });

    // this.unityContent.on("AssetBundleDownloadProgressProductUpdate", (percent) => {
    //   if(this.currentDownloadId){
    //     console.log('UNITY: asset bundle download in progress');
    //     var currentObject = this.state.products.find(x => x.id === this.currentDownloadId);
    //     currentObject = currentObject ? currentObject : this.state.objects.find(x => x.id === this.currentDownloadId);
    //     percent = parseFloat(percent) == -1 ? 0 : percent;
    //     currentObject.loadingText = 'Loading... ' + parseInt(parseFloat(percent)*100) + "%";
    //     currentObject.isLoading = true;
    //     this.setState( {products: this.state.products, objects: this.state.objects} );
    //   }
    // });

    // this.unityContent.on("ProductLoaded", () =>{
    //   if(this.currentDownloadId){
    //     var currentObject = this.state.products.find(x => x.id === this.currentDownloadId);
    //     currentObject = currentObject ? currentObject : this.state.objects.find(x => x.id === this.currentDownloadId);
    //     currentObject.isLoading = false;
    //     this.setState( {products: this.state.products, objects: this.state.objects} );
    //   }
    //
    // });

    this.unityContent.on("productLoadingProgressUpdate", (argument) => {
      var argumentArray = argument.split("␞");
      const currId = argumentArray[0];

      const currProgress = argumentArray[2];
      const currType = argumentArray[1];
      if(currType === "product"){
        // update state of object to loading
        this.updateLoadingAnimationForProductsAndObjects(currId, currType, Number(currProgress), true);

      }else{
        this.setState({objectsLoadingStatus: argument});
      }

    });




    this.unityContent.on("stopProductLoadingSpinner", (argument) => {
      var argumentArray = argument.split("␞");

      const currId = argumentArray[0];
      const currProgress = argumentArray[2];
      const currType = argumentArray[1];
      if(currType === "product"){
        // update state of object to loading
        this.updateLoadingAnimationForProductsAndObjects(currId, currType, false, false);
      }else{
        this.setState({objectLoadingStopStatus: argument});
      }
    });

    // this.unityContent.on("assetBundleDownloadProgressProductUpdate", (percent) => {
    //   // TODO:
    //   console.log('UNITY: assetBundleDownloadProgressProductUpdate', percent);
    // });
  }

  updateLoadingAnimationForProductsAndObjects(currId, currType, progress, isLoading){
    if(currType == 'product' ) {
      var currentObject = this.state.products.find(x => x.id === currId);
      if(currentObject){
        currentObject.isLoading = isLoading;
        if(progress == 1){currentObject.isLoading = false;}
        currentObject.progress = progress;
        this.setState({ products: this.state.products });
      }

      // }else if(currType == "obj"){
      //   var currentObject = this.state.objects.find(x => x.id === currId);
      //   currentObject.isLoading = isLoading;
      //   currentObject.progress = progress;
      //   this.setState({ objects: this.state.objects });
      // }else if(currType = "devobj"){
      //   var currentObject = this.state.devObjects.find(x => x.id === currId);
      //   currentObject.isLoading = isLoading;
      //   currentObject.progress = progress;
      //   this.setState({ devObjects: this.state.devObjects });
      // }
    }
  }


  currentDownloadId = "";
  handleClick = (event, id, assetbundle, type, isSwap, modifiers) => {
    event.preventDefault();
    // checks if assetbundle is already the new type or the old type, if old type, convert to new format
    assetbundle = convertAssetBundleLinkToNewFormt(assetbundle, type);

    this.currentDownloadId = id;

    // update state of object to loading



    if(!(type === 'product')) {
      type = 'obj';
    }else{
      this.updateLoadingAnimationForProductsAndObjects(id, type, 0, true);
    }

    // this.setState({ products: this.state.products, objects: this.state.objects });

    const modifierArgument = modifiers ? "␞" + JSON.stringify(modifiers) : "";
    const argument = id + "␞" + assetbundle + "␞" + type + modifierArgument;
    // TODO: ADD SWAP CALL
    if(this.state.isSwapModeOn){
      console.log('swap mode');
      this.unityContent.send('SceneLoader', "replaceSelectedAndLoadNewProductOrObject", argument);
    }else{
      this.unityContent.send('SceneLoader', 'loadNewProductOrObject', argument);
    }

    window.analytics.track('Add Object', { objectId: id, assetbundle: assetbundle, type: type});

  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.org !== prevProps.org || !this.state.products) {
      if(this.props.org){
        console.log('ADD ORG: ', this.props.org);
        this.getProducts(this.props.org.id);
        if(window.location.origin !== "https://studio.zerolens.com"){
          this.getProductsDev();
        }
      }
    }
  }


  getProducts(oid){
    const { firestore } = this.props;

    firestore.collection('Products').where('isLive', '==', true).where('oid', '==', oid).orderBy("name", "asc").onSnapshot(snapshot => {
      var products = [];

      snapshot.docs.forEach(function (doc) {
        var currentData = doc.data();
        currentData.id = doc.id;
        currentData.loadingText = 'Loading...';
        products.push(currentData);
      });
      if(products.length === 0){
        this.setState({ isAccountWithoutProducts: true });
        this.getProducts(ZID);
      }else{
        this.setState({ products: products, objects: this.state.objects || [] });
      }
    });


  }

  getProductsDev(){
    console.log('DEV Products: GETTING ');
    const { firestore } = this.props;

    firestore.collection('Products').where('isDev', '==', true).orderBy("name", "asc").onSnapshot(snapshot => {
      var products = [];
      snapshot.docs.forEach(function (doc) {
        var currentData = doc.data();
        currentData.id = doc.id;
        currentData.loadingText = 'Loading...';
        products.push(currentData);
      });
      this.setState({ devProducts: products, objects: this.state.objects || [] });
    });
  }



  componentDidMount() {
    const { firestore } = this.props;

    if(this.props.org){
      this.getProducts(this.props.org.id);
      this.getProductsDev();
    }
  }

  render(){

    const { products, devProducts } = this.state;
    const productsData = products ? products : null;
    const productsDevData = devProducts ? devProducts : [];


    return (productsData ) ? (
      <div className={(this.props.isActive ? '' : 'hidden' )}>
        <h3 className="side-panel-inner-heading">Add Products</h3>

        <div className="objects-container">

            <div className="scrollable-container">
              <TextOnlyTooltipComponent text={"switches the selected Product with the new one"} refId={"switch_mode_toggle"}
                                        render={() => (<ViewerSettingsToggleSetting id="switch_mode_toggle" title={"Switch Mode"} isActive={this.state.isSwapModeOn} onPress={this.toggleSwapMode} />)}/>

              <br />

              { this.state.isAccountWithoutProducts && (
                <>


                  <h5 className="side-panel-inner-heading">Add your own Products</h5>
                  <div className="object-controls-container">
                    <a href={"http://zerolens.com/docs/plattform/products-contact"} target='_blank' className="regular-button-ui full-width" >
                      <div className="svg-inherit delete w-embed">
                        <SVG src= '/svgLoader/upload_file.svg' />
                      </div>
                      <div className="delete-button-obj-text">Upload own 3D Model</div>
                    </a>
                  </div>
                  <div className="object-controls-container">
                    <a href={"http://zerolens.com/docs/plattform/products-contact"} target='_blank' className="regular-button-ui full-width">
                      <div className="svg-inherit delete w-embed">
                        <SVG src= '/svgLoader/viewer_3d.svg' />
                      </div>
                      <div className="delete-button-obj-text">I need a 3D Model of my Product(s)</div>
                    </a>
                  </div>
                  <br />

                </>
              )}


              <h5 className="side-panel-inner-heading">{this.state.isAccountWithoutProducts ? 'Test with these Products' : 'Your Products'}</h5>
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column">
                {!this.state.currentSearch ? productsData.map(object => (
                  <TextOnlyTooltipComponent text={object.name}
                                            refId={'product-' + object.id}
                                            position={"left"} // default top, can be top, right, left, center
                                            arrowPosition={"center"} // default center
                                            render={() => (
                                              <Segment className="image-element-class" key={object.id} id={'product-' + object.id}
                                                       onClick={(event) => this.handleClick(event, object.id, object.assetbundle, 'product', false, object.modifiers)} >
                                                <Dimmer active={object.isLoading && object.progress < 1} disabled={!object.isLoading} inverted>
                                                  <Loader inverted content={Math.round(object.progress * 100) + " %"} />
                                                </Dimmer>
                                                <div className={'image-element-hover'}></div>
                                                <img src={object.preview} className="masonry-grid-image" />
                                              </Segment>
                                            )}/>
              )) : (<div></div>)}
              </Masonry>




              { (window.location.origin !== "https://studio.zerolens.com" && this.props.org.id === ZID) &&
              (
                <>
                  <br /><br /><br />
                  <h5 className="side-panel-inner-heading">Dev Products</h5>
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {!this.state.currentSearch ? productsDevData.map(object => (
                      <TextOnlyTooltipComponent text={object.name}
                                                refId={'product-' + object.id}
                                                position={"left"} // default top, can be top, right, left, center
                                                arrowPosition={"center"} // default center
                                                render={() => (
                                                  <Segment className="image-element-class" key={object.id} id={'product-' + object.id}
                                                           onClick={(event) => this.handleClick(event, object.id, object.assetbundle, 'product', false, object.modifiers)} >
                                                    <Dimmer active={object.isLoading} disabled={!object.isLoading} inverted>
                                                      <Loader inverted content={Math.round(object.progress * 100) + " %"} />
                                                    </Dimmer>
                                                    <div className={'image-element-hover'}></div>
                                                    <img src={object.preview} className="masonry-grid-image" />
                                                  </Segment>
                                                )}
                      />

                    )) : (<div></div>)}
                  </Masonry>
                </>
              )}

            </div>
        </div>
      </div>
    ) : (
      <div className={(this.props.isActive ? '' : 'hidden' )}>
        <Placeholder>
          <Placeholder.Image square/>
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square/>
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square/>
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square/>
        </Placeholder>
        <Placeholder>
          <Placeholder.Image square/>
        </Placeholder>

      </div>
    );
  }
});


export default withFirestore(ViewerSettings);
