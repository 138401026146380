import React, { Component, location, history } from 'react';

import ViewerMenu from './ViewerMenu';
import EnvironmentPanel from './colorAndTextureSettings/colorAndTextureSettingsEnvironment';
import ObjectPanel from './colorAndTextureSettings/colorAndTextureSettings';
import AddPanel from './add_objects/ViewerSettingsAddObject';
import AddProducts from './add_objects/ViewerSettingsAddProducts';
import ViewerSideBarMenu from './ViewerSideBarMenu/ViewerSideBarMenu';
import ViewerSettingsGeneral from './general_settings/ViewerSettingsGeneral';
import PhotoSettings from './general_settings/PhotoSettings';
import HelpPanel from './help/HelpPanel';


const ViewerPanel = class ViewerPanel extends Component {
  state = {
    value: 0
  }

  constructor(props) {
    super(props);
  }



  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  calculateCurrentRenderCost(aspect,quality){
    const rendercost = this.props.rendercost;
    if(rendercost){
      if(quality === "draft"){
        return rendercost.draft;
      }

      return rendercost.quality[quality.split(" ").join("_")] ? rendercost.quality[quality.split(" ").join("_")][aspect] : 10;
    }

    return 0

  }

  render() {


    return (
      <div className="viewer-side-menu-container-2" onClick={() => {this.props.unityContent.send('Network', 'focusCanvas', "0"); }}>
        <ViewerSideBarMenu
          active={this.props.active}
          isObjectSelected={this.props.isObjectSelected}
          onPress={this.props.onPress}
          setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
          joyRideStepIndex={this.props.joyRideStepIndex}
          joyrideRunning={this.props.joyrideRunning}
          unityContent={this.props.unityContent}
          store={this.props.store}
          updateStore ={this.props.updateStore}

        />
        <div className="side-main-container">
              <ViewerSettingsGeneral
                viewerState={this.props.state}
                rendercost={this.props.rendercost}
                settingsOptions={this.props.settingsOptions}
                handleGeneralSettings={this.props.handleGeneralSettings}
                isActive={this.props.active === "environment"}
                unityContent={this.props.unityContent}
              />
              <PhotoSettings
                viewerState={this.props.state}
                rendercost={this.props.rendercost}
                settingsOptions={this.props.settingsOptions}
                handleGeneralSettings={this.props.handleGeneralSettings}
                isActive={this.props.active === "photo"}
                unityContent={this.props.unityContent}
              />
              <HelpPanel
                isActive={this.props.active === "help"}
                restartJoyRideTour={this.props.restartJoyRideTour}
                store={this.props.store}
                updateStore ={this.props.updateStore}
              />

              {/*<EnvironmentPanel*/}
                {/*environment={this.props.state.currEnv}*/}
                {/*unityContent={this.props.unityContent}*/}
                {/*isActive={this.props.active === "environment"}*/}
                {/*state={this.props.state}*/}
                {/*settingsOptions={this.props.settingsOptions}*/}
                {/*handleGeneralSettings={this.props.handleGeneralSettings}*/}
                {/*envPreview={this.props.store.currentEnvironmentPreview}*/}
                {/*calculateRenderCost={this.calculateCurrentRenderCost}*/}
                {/*rendercost={this.props.rendercost}*/}
              {/*/>*/}
              <ObjectPanel selectedObj={this.props.state.currSelection} unityContent={this.props.unityContent} isActive={this.props.active === "object"} onPress={this.props.onPress} org={this.props.org}/>
              <AddPanel isObjectSelected={this.props.state.isObjectSelected} unityContent={this.props.unityContent} isActive={this.props.active === "add"} org={this.props.org} cms={this.props.cms} />
              <AddProducts isObjectSelected={this.props.state.isObjectSelected} unityContent={this.props.unityContent} isActive={this.props.active === "add_products"} org={this.props.org} cms={this.props.cms} />

        </div>
      </div>
    );
  }
};


export default ViewerPanel;
