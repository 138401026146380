import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import './CustomInfiniteHits.css';
import Masonry from 'react-masonry-css';
import { withRouter } from "react-router-dom";



const breakpointColumnsObj = {
  default: 3,
  950: 2,
  700: 2,
  500: 1
};

class InfiniteHitsScroll extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refine: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;
    // var { store } = this.props.store;

    return (
      <div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {hits.map( (hit) => (

            <div className="image-element-class" key={hit.objectID} onClick={() => {
              this.props.setNewHitsArray(hits);
              this.props.setHitPreview(hit);

            }}>
              <div className={'image-element-hover'}></div>
              <img src={hit.preview} className="masonry-grid-image" />
            </div>
          ))}


        </Masonry>
        <span className="ais-InfiniteHits-sentinel" ref={c => (this.sentinel = c)} />
      </div>
    );
  }
}

export default withRouter(connectInfiniteHits(InfiniteHitsScroll));
