import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import addSymbol from '../../../../images/noun_add_2664285-1.svg';

const InViewerSettingsElement = observer(class InViewerSettingsElement extends Component {

  render() {

    return (
      <div className={"in-viewer-option-container " + (this.props.isVisible ? '' : 'hidden ') + (this.props.isActive ? 'active' : '')} onClick={this.props.onPress} onMouseEnter={this.props.mouseEnter} onMouseLeave={this.props.mouseLeave} >
        <div className="side-menu-in-viewer-heading">
          <div className="text-block-23">{this.props.title}</div>
        </div>
        <div className="div-block-5" ><img src={this.props.symbol} alt="" className="image-7" />
        </div>
      </div>
    );
  }
});


export default InViewerSettingsElement;
