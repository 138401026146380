import React, { Component, location, history } from 'react';

const ViewerMenu = class TransformSlider extends Component {


  constructor(props) {
    super(props);
  }

  handleChange = (newState) => {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  render() {
    return (
      <div className="tab-container-panel">
        <div className={"tab-element-container-panel "  + (this.props.active === "add" ? "active-new" : "")} onClick={ () => {this.props.onPress("Add Objects")}}>
          <div className="svg-toggle-object-panel-icon w-embed">
            <span dangerouslySetInnerHTML={{__html: "<svg height=\"18px\" width=\"18px\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\"\n" +
              "                 xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 48 48\" version=\"1.1\" x=\"0px\" y=\"0px\">\n" +
              "              <title>9.1</title>\n" +
              "              <desc>Created with Sketch.</desc>\n" +
              "              <g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\">\n" +
              "                <g fill-rule=\"nonzero\" fill=\"currentColor\">\n" +
              "                  <g>\n" +
              "                    <path\n" +
              "                      d=\"M26,22 L33.008845,22 C34.1085295,22 35,22.8877296 35,24 C35,25.1045695 34.1103261,26 33.008845,26 L26,26 L26,33.008845 C26,34.1085295 25.1122704,35 24,35 C22.8954305,35 22,34.1103261 22,33.008845 L22,26 L14.991155,26 C13.8914705,26 13,25.1122704 13,24 C13,22.8954305 13.8896739,22 14.991155,22 L22,22 L22,14.991155 C22,13.8914705 22.8877296,13 24,13 C25.1045695,13 26,13.8896739 26,14.991155 L26,22 Z M24,48 C10.745166,48 0,37.254834 0,24 C0,10.745166 10.745166,0 24,0 C37.254834,0 48,10.745166 48,24 C48,37.254834 37.254834,48 24,48 Z M24,44 C35.045695,44 44,35.045695 44,24 C44,12.954305 35.045695,4 24,4 C12.954305,4 4,12.954305 4,24 C4,35.045695 12.954305,44 24,44 Z\"></path>\n" +
              "                  </g>\n" +
              "                </g>\n" +
              "              </g>\n" +
              "            </svg>"}} />
          </div>
          <div className="tab-menu-text-panel">Add</div>
        </div>
        <div className={"tab-element-container-panel " + (this.props.active === "environment" ? "active-new" : "")} onClick={ () => {this.props.onPress("Environment")}}>
          <div className="svg-toggle-object-panel w-embed">
            <span dangerouslySetInnerHTML={{__html: "<svg height=\"18px\" width=\"18px\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 512 512\" enable-background=\"new 0 0 512 512\" xml:space=\"preserve\"><path d=\"M493.2,79.8c-0.2-0.1-0.3-0.2-0.5-0.3L264,3.3c-5.2-1.7-10.8-1.7-16.1,0L19.4,79.5c-0.2,0.1-0.3,0.2-0.5,0.3  C16.6,80.6,2,88,2,103.6v304.8c0,10.9,7,20.6,17.4,24.1L248,508.7c0,0,0.1,0,0.1,0c2.6,0.8,5.2,1.3,7.9,1.3c2.7,0,5.4-0.5,7.9-1.3  c0,0,0.1,0,0.1,0l228.6-76.2C503,429,510,419.3,510,408.4V103.6C510,101.2,507.5,86,493.2,79.8z M230.6,449.3L52.8,390.1V138.8  l177.8,59.3V449.3z M256,153l-148.3-49.4L256,54.2l148.3,49.4L256,153z M459.2,390.1l-177.8,59.3V198.1l177.8-59.3V390.1z\"></path></svg>\n"
              }} />
            </div>
          <div className="tab-menu-text-panel">Scene</div>
        </div>
        <div className={"tab-element-container-panel " + (this.props.isObjectSelected ? " " : "hiddenpanel ")  + (this.props.active === "object" ? " active-new " : " ") } onClick={ () => {this.props.onPress("Object")}}>
          <div className="svg-toggle-object-panel w-embed">
            <span dangerouslySetInnerHTML={{__html: "<svg height=\"18px\" width=\"18px\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" viewbox=\"0 0 48 48\" x=\"0px\" y=\"0px\"><defs><style>.cls-1{fill:none;}</style></defs><title>Image</title><g data-name=\"Bouding Box\"><rect class=\"cls-1\" width=\"48\" height=\"48\"></rect></g><g><path d=\"M28,23a6,6,0,1,0-6-6A6,6,0,0,0,28,23Zm0-9a3,3,0,1,1-3,3A3,3,0,0,1,28,14Z\"></path><path d=\"M38,4H10a6,6,0,0,0-6,6V38a6,6,0,0,0,6,6H38a6,6,0,0,0,6-6V10A6,6,0,0,0,38,4ZM10,7H38a3,3,0,0,1,3,3V33.86L35.17,28a4.49,4.49,0,0,0-6.36,0l-3.32,3.31-8.31-8.3a4.49,4.49,0,0,0-6.36,0L7,26.86V10A3,3,0,0,1,10,7ZM7,38V31.11l5.94-5.94a1.53,1.53,0,0,1,2.12,0L30.89,41H10A3,3,0,0,1,7,38Zm31,3H35.14l-7.53-7.53,3.32-3.31a1.53,1.53,0,0,1,2.12,0L41,38.1A3,3,0,0,1,38,41Z\"></path></g></svg>"}} />

          </div>
          <div className="tab-menu-text-panel">Object</div>
        </div>
      </div>
    );
  }
};


export default ViewerMenu;
