import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';






const watermarkOverlay = observer(class watermarkOverlay extends Component {



  render() {
    return (
      <div className={"cam-viewer-container watermark-overlay " + (this.props.isVisible? "show" : "") } >

      </div>
    );
  }
});


export default watermarkOverlay;


