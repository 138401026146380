import React, { Component, history } from 'react';
import BackgroundVideo from 'react-background-video-player';
import posed from 'react-pose';



const VIDEO_STYLES = {
  overlayColor: 'rgba(0, 0, 0, 0.0)',
  tooltip: {
    width: "800px",
  },
}

const HIDE_BUTTON = {
  buttonNext: {
    display: 'none'
  }
};


const Modal = posed.div({
  enter: {
    y: 0,
    opacity: 1,
    delay: 300,
    transition: {
      y: { type: 'spring', stiffness: 1000, damping: 15 },
      default: { duration: 300 }
    }
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: { duration: 150 }
  }
});


const RIGHT_SIDE_STLYE = {
  tooltip: {
    marginBottom: '40px',
  },
  buttonNext: {
    display: 'none'
  },
  overlay: {
    backgroundColor: 'transparent',
  },
  overlayLegacy: {
    backgroundColor: 'transparent',
  },
  tooltip: {
    fontSize: 12
  }
}

export const steps = [
  {
    target: 'body',
    content: "Let's show you around at zerolens",
    placement: 'center',
    locale: {
      next: <span>Start</span>,
    },
  },
  {
    target: 'body',
    title: "To start, just open a prepared template!",
    content: (
      <div className={"video-step-container"}>
        <BackgroundVideo
        src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e70faed910272f644b84ac0_Screen Recording 2020-03-17 at 172845-transcode.webm"}
        containerWidth={750}
        containerHeight={300}
        />
      </div>

    ),
    placement: 'center',
    styles: VIDEO_STYLES,
    locale: {
      next: <span>Next</span>,
    },
  },
  {
    target: '#tut-id-6mZLj2ISuJwKUiY5Vyvd',
    content: 'click on the template',
    placement: 'top'
  },
  {
    target: '#open-studio-button',
    content: 'click to open the studio',
    placement: 'top'
  },
  {
    target: 'body',
    content: 'The template is loading, this will take a few seconds',
    placement: 'center',
    styles: HIDE_BUTTON,
    locale: {
      next: <span></span>,
    },
  },
  {
    target: '.left-tutorial-handler',
    title: 'Rotating the Camera',
    content: (
      <Modal>
        <><span className={"tutorial-action"}>Right Click</span> and <span className={"tutorial-action"}>Drag</span> to rotate the camera</>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e736da3a2128c4950c6d5ac_rotation-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>rotate the camera to continue</div>
      </Modal>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Great! Let's move the camera and find the chocolate candy",
    content: (
      <Modal>
        <><span className={"tutorial-action"}>Right Click + SHIFT</span> and <span className={"tutorial-action"}>Drag</span> to move the camera</>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e7378aea2128c260ac71bf7_Screen%20Recording%202020-03-19%20at%20144953-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>move the camera to continue</div>
      </Modal>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Cool! The candy should be in the photo, so move it",
    content: (
      <>
        <><span className={"tutorial-action"}>Left Click</span> on the <span className={"tutorial-action"}>candy</span> and <span className={"tutorial-action"}>Drag</span> to desired position</>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e737ad98fd6dfa1ce4e60c3_Screen%20Recording%202020-03-19%20at%20145848-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>move the chocolate candy to continue</div>
      </>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.grid-button.left',
    title: "Now reset the camera position to where we started",
    content: (
      <>
        <><span className={"tutorial-action"}>Left Click</span> on the <span className={"tutorial-action"}><span className={"home-button-tutorial"} dangerouslySetInnerHTML={{__html: "<svg height='15px' width='15px'  fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" enable-background=\"new 0 0 100 100\" xml:space=\"preserve\"><g><polygon fill=\"currentColor\" points=\"64.999,92 65,56 35,56 34.999,92 8,92 8,39.52 50,8.72 92,39.52 92,92  \"></polygon><path fill=\"currentColor\" d=\"M50,12.44l39,28.6V89H67.999L68,59l0-6h-6H38h-6l0,6l-0.001,30H11V41.04L50,12.44 M50,5L5,38v57h32.999   L38,59h24l-0.001,36H95V38L50,5L50,5z\"></path></g></svg>" }} /> Button</span> to reset the position and rotation of the camera</>
        {/*<div className={"video-container-right-side"}>*/}
          {/*<BackgroundVideo*/}
            {/*src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e737d9ba2128cdb92c73dac_Screen%20Recording%202020-03-19%20at%20151058-transcode.webm"}*/}
            {/*containerWidth={300}*/}
            {/*containerHeight={225}*/}
            {/*style={{*/}
              {/*width: '300px',*/}
              {/*height: '225px',*/}
              {/*visibility: 'auto'*/}
            {/*}}*/}
          {/*/>*/}
        {/*</div>*/}
        <div className={"tutorial-continue-text"}>reset the camera to continue</div>
      </>
    ),
    styles: HIDE_BUTTON,
    placement: 'top',
    floaterProps: {hideArrow: false},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Let's add objects to the scene",
    content: (
      <>
        <div className={"tutorial-step-text-block"}>
          <ol>
            <li><span className={"tutorial-action"}>Left Click</span> on an <span className={"tutorial-action"}>Object</span> in the <span className={"tutorial-action"}>Object Panel</span> on the right hand side </li>
            <li>A ghost object appears on your mouse once the object is loaded</li>
            <li>Place the object by <span className={"tutorial-action"}>Left Clicking</span> in the Viewer</li>
          </ol>
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73bc86ba24f27a06d2d1b7_Screen Recording 2020-03-19 at 193726-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>add the object to continue</div>
      </>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Rotating Objects",
    content: (
      <>
        <div className={"tutorial-step-text-block"}>
          <ol>
            <li><span className={"tutorial-action"}>Left Click</span> the Object you just placed in the scene. </li>
            <li>Rotate the Object by <span className={"tutorial-action"}>Clicking and Dragging</span></li>
          </ol>
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73ce3310e4b4505d051518_Screen Recording 2020-03-19 at 205421-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>rotate the object you added to continue</div>
      </>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Object Panel & Deleting Objects",
    content: (
      <>
        <div className={"tutorial-step-text-block"}>
          After selecting the object an <span className={"tutorial-action"}>Object Panel</span> appeared on the upper right corner of the Viewer. <br />
          Here you can <strong>switch between Tools</strong> (Rotate, Move & Scale) <br /><br />
          Some Objects have additional options such as changing the objects colors or material<br /><br />

          <span className={"tutorial-action"}>Delete the Object</span> by <span className={"tutorial-action"}>Left Clicking</span> on the <br /><span className={"tutorial-action"}>Delete Object Button</span>
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73ce7eba24f2a176d330ec_Screen Recording 2020-03-19 at 205259-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>delete the object you added to continue</div>
      </>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Delete the Pouch",
    content: (
      <>
        <div className={"tutorial-step-text-block"}>
          <span className={"tutorial-action"}>Delete the Coffe Bean Pouch</span><img src={"https://storage.googleapis.com/pixelstore-4ec98.appspot.com/Products%2FZerolens_Standing_Pouch_Coffee__2019-11-19T13%3A31%3A47.582Z"} className={"delete-coffe-bean-pouch-hint-image"}/> like you did with the last object.
        </div>
        <div className={"tutorial-continue-text"}>delete the pouch to continue</div>
      </>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Add another Product",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <ol>
            <li><span className={"tutorial-action"}>Left Click</span> the <span className={"tutorial-action"}>Product Panel Button</span> to open it.</li>
            <li><span className={"tutorial-action"}>Left Click</span> the Product you want to add and wait until it the loading completed</li>
            <li><span className={"tutorial-action"}>Place the product</span> just like you did with the Object</li>
          </ol>

          <div className={"tutorial-information-block"}>
            <strong>IMPORTANT:</strong> use zerolens with your own products! You don't need a 3D Object of your product yet. <a href="https://zerolens.com/docs/plattform/products-contact" target="_blank">Click here for more information on how to add you own products</a>
          </div>
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73d5f60a912bd166350245_add_product_new-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>add another product to continue</div>
      </div>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Flip the Product Rotation",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <ol>
            <li><span className={"tutorial-action"}>Left Click</span> on the <span className={"tutorial-action"}>Product</span> you just added.</li>
            <li><span className={"tutorial-action"}>Left Click</span> the <img src={"/images/flip_button.png"} className={"delete-coffe-bean-pouch-hint-image"}/>  <span className={"tutorial-action"}>Flip Button</span> lay it on it's back.</li>
          </ol>

          <div className={"tutorial-information-block"}>
            <strong>IMPORTANT:</strong> use zerolens with your own products! You don't need a 3D Object of your product yet. <a href="https://zerolens.com/docs/plattform/products-contact" target="_blank">Click here for more information on how to add you own products</a>
          </div>
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73d3800a912b476034f257_Screen Recording 2020-03-19 at 210435-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>flip the product to continue</div>
      </div>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Place the Product on the Surface",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <span className={"tutorial-action"}>Left Click</span> and <span className={"tutorial-action"}>Drag</span> the product to lay it float on the surface<br />

          <div className={"tutorial-information-block"}>
            <strong>Information:</strong> Once you click and drag an object or product physics is activated for that object and it will behave just like in the real world.
          </div>
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73d3358606e0299a66baa0_Screen Recording 2020-03-19 at 210515-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>move the product to continue</div>
      </div>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.left-tutorial-handler',
    title: "Zoom and Frame the Photo",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <span className={"tutorial-action"}>Zoom in</span> by using your <span className={"tutorial-action"}>mouse wheel</span> or the zoom function on your touchpad.
        </div>
        <div className={"video-container-right-side"}>
          <BackgroundVideo
            src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e7cbb4eae1232e0843447f3_Screen Recording 2020-03-26 at 152340-transcode.webm"}
            containerWidth={300}
            containerHeight={225}
            style={{
              width: '300px',
              height: '225px',
              visibility: 'auto'
            }}
          />
        </div>
        <div className={"tutorial-continue-text"}>zoom to continue</div>
      </div>
    ),
    styles: RIGHT_SIDE_STLYE,
    placement: 'right-end',
    floaterProps: {hideArrow: true},
    disableOverlay: true
  },
  {
    target: '.camer-button-container',
    title: "Take the Photo",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <span className={"tutorial-action"}>Left Click</span> the <span className={"tutorial-action"}>photo button</span>.
        </div>
        {/*<div className={"video-container-right-side"}>*/}
          {/*<BackgroundVideo*/}
            {/*src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73bc86ba24f27a06d2d1b7_Screen Recording 2020-03-19 at 193726-transcode.webm"}*/}
            {/*containerWidth={300}*/}
            {/*containerHeight={225}*/}
            {/*style={{*/}
              {/*width: '300px',*/}
              {/*height: '225px',*/}
              {/*visibility: 'auto'*/}
            {/*}}*/}
          {/*/>*/}
        {/*</div>*/}
        <div className={"tutorial-continue-text"}>take a photo to continue</div>
      </div>
    ),
    styles: HIDE_BUTTON,
    placement: 'top',
    floaterProps: {hideArrow: false},
    disableOverlay: true
  },
  {
    target: '.render-preview-button',
    title: "Start Rendering a Preview",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <span className={"tutorial-action"}>Left Click</span> the <span className={"tutorial-action"}>FREE PREVIEW</span> option.
        </div>
        {/*<div className={"video-container-right-side"}>*/}
          {/*<BackgroundVideo*/}
            {/*src={"https://uploads-ssl.webflow.com/5cfbf18c982a74f184ee58cb/5e73bc86ba24f27a06d2d1b7_Screen Recording 2020-03-19 at 193726-transcode.webm"}*/}
            {/*containerWidth={300}*/}
            {/*containerHeight={225}*/}
            {/*style={{*/}
              {/*width: '300px',*/}
              {/*height: '225px',*/}
              {/*visibility: 'auto'*/}
            {/*}}*/}
          {/*/>*/}
        {/*</div>*/}
        <div className={"tutorial-continue-text"}>choose "FREE PREVIEW" to continue</div>
      </div>
    ),
    styles: HIDE_BUTTON,
    placement: 'bottom',
    floaterProps: {hideArrow: false},
    disableOverlay: true
  },
  {
    target: '.photo-button-in-viewer',
    title: "Let's go into my photos to check.",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          <span className={"tutorial-action"}>Left Click</span> the <span className={"tutorial-action"}>My Photos Button</span>.
        </div>
        <div className={"tutorial-continue-text"}>click my photos to continue</div>
      </div>
    ),
    styles: HIDE_BUTTON,
    placement: 'top',
    floaterProps: {hideArrow: false},
    disableOverlay: true
  },
  {
    target: 'body',
    title: 'The Admin Panel',
    content: "We are now in the admin panel of zerolens, where you can find your photos, settings, your products and more",
    placement: 'center'
  },
  {
    target: '.backend-photo-card.tiles.backend-photo-card-0',
    title: "Your Photo is rendering now",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          The completion will take a few minutes. You will be notified via email once it is finished.
        </div>
      </div>
    ),
    placement: 'right',
    floaterProps: {hideArrow: false},
  },
  {
    target: '.sidebar-item-products',
    title: "Adding your own Products",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          Let's go to the products settings.
        </div>
      </div>
    ),
    placement: 'right',
    styles: HIDE_BUTTON,
    floaterProps: {hideArrow: false},
    disableOverlay: true
  },
  {
    target: '.backend-add-button',
    title: "Own Products",
    disableScrolling: false,
    content: (
      <div className={"long-tooltip-container"}>
        <div className={"tutorial-step-text-block"}>
          Learn how to add your own Products to zerolens.
        </div>
      </div>
    ),
    placement: 'bottom',
    floaterProps: {hideArrow: false},
  },








];
