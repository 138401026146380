import React, { Component, location, history } from 'react';
import { withFirestore } from 'react-firestore';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';

const MaterialChange = class MaterialChange extends Component {


  state = {
    materials: []
  }

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentDidMount(){
    this.getMaterials(undefined);
  }

  handleMaterialChange(material){

  }

  getMaterials(filter){
    const { firestore } = this.props;
    console.log('MATERIALS BEFORE');
    firestore.collection('Materials').orderBy("name", "asc").onSnapshot(snapshot => {
      console.log('MATERIALS');
      var materials = [];
      snapshot.docs.forEach(doc => {
        var currentData = doc.data();
        currentData.id = doc.id;
        materials.push(currentData);
      });
      this.setState({ materials: materials });
    });
  }

  render() {
    return (
      <div className="propertycontainer">
        <div className="propertycontainer">
          <div className="propertytitle">{this.props.title}</div>
        </div>
        <div className="settings-container">
          <div className="material-container">
            { this.state.materials.map(material => (
              <div className={"single-material-container " + (material.assetbundle == this.props.modifier.options.changeableMaterialID ? "active" : "" )} onClick={() => { this.props.onChangeComplete(material, this.props.modifierIndex) }}>
                <img src={material.preview} alt="" className={"material-preview " + (false ? 'active' : '')} />
                <div className="material-name">{material.name} test</div>
                <Dimmer active={true} disabled={!true} inverted>
                  <Loader inverted content={Math.round(10 * 100) + " %"} />
                </Dimmer>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
};


export default withFirestore(MaterialChange);
