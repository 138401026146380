import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import InfiniteScroll from 'react-infinite-scroller';
import { Route, Link } from "react-router-dom";


// import { observer } from 'mobx-react';

const SelectedOverlay = observer(
  class SelectedOverlay extends Component {

    // interaction
    render() {
      return (
        <div className="selected-container">
          <div className="selectedsvg w-embed">
            <span dangerouslySetInnerHTML={{__html: "<svg height=\"100%\" width=\"100%\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewbox=\"0 0 24 24\" version=\"1.1\" x=\"0px\" y=\"0px\"><title>select</title><desc>Created with Sketch.</desc><g stroke=\"none\" stroke-width=\"1\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M9.87867966,14.5355339 L7.75735931,12.4142136 C7.36683502,12.0236893 6.73367004,12.0236893 6.34314575,12.4142136 C5.95262146,12.8047379 5.95262146,13.4379028 6.34314575,13.8284271 L9.17157288,16.6568542 C9.36683502,16.8521164 9.62275734,16.9497475 9.87867966,16.9497475 C10.134602,16.9497475 10.3905243,16.8521164 10.5857864,16.6568542 L17.6568542,9.58578644 C18.0473785,9.19526215 18.0473785,8.56209717 17.6568542,8.17157288 C17.26633,7.78104858 16.633165,7.78104858 16.2426407,8.17157288 L9.87867966,14.5355339 Z M12,24 C5.372583,24 0,18.627417 0,12 C0,5.372583 5.372583,0 12,0 C18.627417,0 24,5.372583 24,12 C24,18.627417 18.627417,24 12,24 Z\" fill=\"currentColor\" fill-rule=\"nonzero\"></path></g></svg>"}} />
          </div>
        </div>
      );
    }
  }
);





export default withFirestore(SelectedOverlay);

