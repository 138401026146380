import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import CameraIcon from '../../../images/noun_Camera_269752.svg';

const CameraButton = observer(class CameraButton extends Component {

  render() {
    const { onPress, mouseEnter, mouseLeave } = this.props;
    return (
      <div className="photo-button-container">
        <div className="photo-button" onClick={onPress} onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}><img src={CameraIcon} /></div>
      </div>
    );
  }
});


export default CameraButton;
