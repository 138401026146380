import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import CorncerIcon from './../../../images/corners_rect.svg';
import { joyRideStepSettings } from '../../helpers/joyrideHelpers/joyrideHelpers';



const RenderDialog = observer(class RenderDialog extends Component {




  constructor(props) {
    super(props);
    this.state = {
      selectedOption: this.props.state.quality,
      selectedRenderCost: this.props.rendercost.quality[this.props.state.quality][this.props.state.aspectRatio]
    };
  }

  handleOptionChange = changeEvent => {
    this.setState({
      selectedOption: changeEvent.target.value,
      selectedRenderCost: this.props.rendercost.quality[changeEvent.target.value][this.props.state.aspectRatio]
    });
  };

  handlePreviewRender(){
    if(this.props.joyrideRunning){
      if(this.props.joyRideStepIndex === joyRideStepSettings.templateFreeOptionId ){
        this.props.setJoyRideStepByIndex(joyRideStepSettings.templateFreeOptionId + 1);
      }
    }

   this.props.render(true, this.state.selectedOption)
  }

  render() {
    return (
      <div className="credits-viewer-container">
        <div className="div-block-37">
          <div className="credits-information-text-half">
            <h4 className="heading-4">Preview with Watermarks</h4>
            <div className="text-block-42">- small image size<br />
              <strong className="bold-text-3">- unlock high quality photo at any time</strong>
            </div>
            <div className="text-block-43">best fit if you want to get a good grasp on how your final photo will
              look like
            </div>
            <div className="render-preview-button" onClick={() => {this.handlePreviewRender()}}>
              <div className="credits-left-text">FREE PREVIEW</div>
            </div>
          </div>
          <div className="hr-white-vertical"></div>
          <div className="credits-information-text-half">
            <h4 className="heading-4">Remove Watermarks, Choose Final Image Size</h4>
            <div className="w-form">
              <form id="email-form-3" name="email-form-3" data-name="Email Form 3">

                { Object.keys(this.props.rendercost.sizesOptions).map((quality) =>
                  (quality !== "draft") && (
                    <div>
                    { this.props.rendercost.sizesOptions[quality].map((format) =>
                      ( format.value === this.props.state.aspectRatio ) && (
                        <label className="radio-button-field w-radio">
                          <input type="radio" data-name="Radio" id="radio"
                                 name="radio" value={quality}
                                 className="w-form-formradioinput w-radio-input"
                                 checked={quality === this.state.selectedOption}
                                 onChange={this.handleOptionChange}
                          />
                          <span className="radio-button-label w-form-label" style={{textTransform: "capitalize"}}>{quality.split("_").join(" ")} Quality {format.text}</span>
                          <div className="text-block-44">({this.props.rendercost.sizes[quality][format.value].x}px x {this.props.rendercost.sizes[quality][format.value].y}px | {this.props.rendercost.quality[quality][format.value]} Credits)</div>
                        </label>
                      )
                    )}
                    </div>
                  )
                )}
              </form>
            </div>
            <div className="text-block-43">best fit if you already know that the photo will turn out how you
              excpect it. (e.g small changes, switching only one object, etc.)
            </div>
            <div className="render-quality-button" onClick={() => this.props.render(false, this.state.selectedOption)}>
              <div className="credits-left-text">{this.state.selectedOption.split("_").join(" ")}</div>
              <div className="div-block-36">
                <div className="credits-amount-text">{this.state.selectedRenderCost}</div>
                <img src="/images/noun_token_1796108.svg" alt="" className="image-16" /></div>
            </div>
          </div>
        </div>
        <div className="div-block-40">
          <div className="text-block-45">Your Current Account Balance</div>
          <div className="credits-amount-text">{this.props.org ? this.props.org.credits : ""}</div>
          <img src="/images/noun_token_1796108.svg" alt="" className="image-16" /></div>
        <img src="/images/noun_X_1890803_1.svg" alt="" className="image-5-copy" onClick={() => this.props.close()} />
      </div>


    );
  }
});


export default RenderDialog;


