import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore } from 'react-firestore';
import InViewerSettingsElement from './InViewerSettingsElement';

import addSymbol from '../../../../images/noun_add_2664285-1.svg';
import addSymbolColor from '../../../../images/noun_drop_1235520.svg';
import binSymbol from '../../../../images/noun_bin_1433555-1.svg';
import gearSymbol from '../../../../images/noun_Settings_2650515.svg';
import transformSymbol from '../../../../images/noun_rotation_2574812.svg';
import moveSymbol from '../../../../images/noun_Move_2451320.svg';
import scaleSymbol from '../../../../images/noun_scale tool_337727.svg';
import previewSymbol from '../../../../images/noun_Before After_576653.svg';


const ViewerMenu = observer(class ViewerMenu extends Component {
  constructor(props){
    super(props);
    this.unityContent = this.props.unityContent;
  }

  render() {
    let styleDiv = this.props.position == 'top right' ? { top: 0, right: 0, left: 'auto', bottom: 'auto', zIndex: 100  }  : {};

    return (
      <div style={styleDiv} className="side-menu-in-viewer">
        <InViewerSettingsElement isActive={false} isVisible={ (this.props.viewerState.currentTranfsormTool !== 'Rotate' && this.props.viewerState.isObjectSelected)  } title={"Rotate Selection"} symbol={transformSymbol} onPress={() => this.props.onTransform('Rotate')} />
        <InViewerSettingsElement isActive={false} isVisible={ (this.props.viewerState.currentTranfsormTool !== 'Move' && this.props.viewerState.isObjectSelected)  } title={"Move Selection"} symbol={moveSymbol} onPress={() => this.props.onTransform('Move')} />
        <InViewerSettingsElement isActive={false} isVisible={ (this.props.viewerState.currentTranfsormTool !== 'Scale' && this.props.viewerState.isObjectSelected)  } title={"Scale Selection"} symbol={scaleSymbol} onPress={() => this.props.onTransform('Scale')} />

        <InViewerSettingsElement isActive={this.props.viewerState.isColorSettingVisible} isVisible={ (this.props.viewerState.selectedObjectCanChangeColor && this.props.viewerState.isObjectSelected) } title={"Change Color"} symbol={addSymbolColor} onPress={() => this.props.onPress('Change Color')} />


        {/* <InViewerSettingsElement isActive={this.props.viewerState.isTransformSettingVisible} isVisible={this.props.viewerState.isObjectSelected} title={"Transform Selection"} symbol={transformSymbol} onPress={() => this.props.onPress('Transform')} /> */}
        <InViewerSettingsElement isActive={this.props.viewerState.isAddObjectVisible} isVisible={true}  title={"Add Objects"} symbol={addSymbol} onPress={() => this.props.onPress('Add Objects')} />
        <InViewerSettingsElement isActive={this.props.viewerState.isSettingsVisible} isVisible={true} title={"Settings"} symbol={gearSymbol} onPress={() => this.props.onPress('Settings')} />
       </div>
    );
  }
});


export default withFirestore(ViewerMenu);
