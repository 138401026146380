
import React, { Component, location, history } from 'react';
import { Route, Link } from "react-router-dom";
import { Placeholder, Segment, Dimmer, Loader } from 'semantic-ui-react';






class NotSupported extends Component {
  render() {
    return (
      <div className="fullscreen-loader absolute"><Segment className="fullscreen-loader">
        <Dimmer active>
          <h4 size='massive'>Sorry, we are currently not supporting this Browser. We recommend using Chrome for zerolens.<br /><br /></h4>
          <a href="https://www.google.com/intl/en/chrome/" className="w-button button regular-button">Download Chrome Browser</a>
        </Dimmer>
      </Segment>
      </div>
    )
  }
}

export default NotSupported;
