/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { Component, location, history, useState } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import MaterialPicker from './material-picker';
import { withFirestore } from 'react-firestore';
import { Placeholder } from 'semantic-ui-react';



const InputMaterial = observer(class Properties extends Component {

  state={
    isMaterialPickerVisible: false,
    preview: undefined,
    material: undefined,
    updateIdHack: 0,
    materialOptions: []
  }

  changeMaterial(materialId){
    console.log('going to change material --> call unity');
  }

  getMaterialByFirebaseId(materialFirebaseId){
    const { firestore } = this.props;
    firestore.collection('Materials').doc(materialFirebaseId).onSnapshot(snapshot => {
      var material = snapshot.data();
      console.log('got material data: ', material);
      this.setState({
        preview: material.preview,
        materialId: materialFirebaseId,
        material: material,
      })
    });
  }

  componentDidUpdate(prevProps){
    if( (prevProps && this.props.activeMaterialId !== prevProps.activeMaterialId) || (!prevProps && this.props.activeMaterialId) ){
      this.getMaterialByFirebaseId(this.props.activeMaterialId);
    }
  }

  getMaterialOptionsByFilter(filter){
    const { firestore } = this.props;
    firestore.collection('Materials').orderBy("name", "asc").onSnapshot(snapshot => {
      var materials = [];
      snapshot.docs.forEach(doc => {
        var currentData = doc.data();
        currentData.id = doc.id;
        materials.push(currentData);
      });
      this.setState({ materialOptions: materials });
    });
  }

  closeMaterialPicker(){
    this.setState( {isMaterialPickerVisible: false});
  }

  openMaterialPicker(){
    this.setState( {isMaterialPickerVisible: true});
  }


  constructor(props) {
    super(props);
    this.closeMaterialPicker = this.closeMaterialPicker.bind(this);

    if (this.props.activeMaterialId) {
      this.getMaterialByFirebaseId(this.props.activeMaterialId);
    }
    this.getMaterialOptionsByFilter(undefined);

    // stopMaterialLoadingSpinner(string csv); // called once a material is fully loaded, regardless of the success or failure. Parameter are a string of the material's firebase ID and success ("success"/"fail"), separated with the Record Seperator Symbol (see Modifier structure)

    this.props.unityContent.on("stopMaterialLoadingSpinner", (argument) => {
      var argumentArray = argument.split("␞");
      const materialFBId = argumentArray[0] || this.props.nextMaterialId;
      const success = argumentArray[1];

      // TODO: handle failure or success

      var materialArray = this.state.materialOptions;
      const currentMaterialIndex = materialArray.findIndex(mat => mat.id == materialFBId);
      var currentMaterial = materialArray[currentMaterialIndex];
      currentMaterial.isLoading = false;
      currentMaterial.progress = 1;

      console.log('unity content: is loading is done: ', materialFBId);


      materialArray[currentMaterialIndex] = currentMaterial;

      this.setState({ materialOptions: materialArray, updateIdHack: Math.random() });

      // this.setState({ materialOptions: progress });
    });

    this.props.unityContent.on("materialLoadingProgressUpdate", (argument) => {
      var argumentArray = argument.split("␞");
      const materialFBId = argumentArray[0] || this.props.nextMaterialId;
      const type = argumentArray[1];
      const progress = argumentArray[2];



      var materialArray = this.state.materialOptions;
      const currentMaterialIndex = materialArray.findIndex(mat => mat.id == materialFBId);
      var currentMaterial = materialArray[currentMaterialIndex];
      if(progress != 1 || progress != "1"){
        currentMaterial.isLoading = true;
      }else{
        currentMaterial.isLoading = false;
      }
      currentMaterial.progress = progress;
      console.log('unity argument: ', progress);


      materialArray[currentMaterialIndex] = currentMaterial;

      this.setState({ materialOptions: materialArray, updateIdHack: Math.random() });

      // this.setState({ materialOptions: progress });
    });

    // materialLoadingProgressUpdate(string csv);//sent every frame while downloading a material (except when it is loaded as part of a snapshot)). String consists of the materials's firebase id, type, and the progress, separated with the Record Seperator Symbol (see Modifier structure)


  }





  render() {
    return (
    <div className="single-object-modifier-container left " onClick={() =>{this.openMaterialPicker()}} >
      <div className={"color-option-container left"}>
        { this.state.isMaterialPickerVisible && (<div className={"completeOverlay"} onClick={(e) => {e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation(); this.closeMaterialPicker(); }}></div>) }
          <div className={"single-material-option"} >
            { true &&  (
              <>
              <img src={this.state.preview} className={"material-preview-image"} />
              { this.state.isMaterialPickerVisible &&
                (<div className={"zl-tooltip center-margin-material-picker-overlay"}>
                  <MaterialPicker material={this.props.modifier} onClose={this.closeMaterialPicker} materialOptions={this.state.materialOptions} onChange={this.props.onChange} index={this.props.index} filter={undefined} updateId={this.state.updateIdHack} />
                </div>)
              }
              </>
            )
            }
          </div>



      </div>
      <div className="text-block-48">{this.props.title}</div>
    </div>

  )};
});


export default withFirestore(InputMaterial);
