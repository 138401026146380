import React, { Component, location, history } from 'react';
import './CircleLoaderCheckmark.css';

const CircleLoaderCheckmark = class CircleLoaderCheckmark extends Component {

  render() {
    // const style = { "--my-css-var": 10 } as React.CSSProperties; // TODO load with props and implement in css

    return (
      <div className={"circle-loader " + (this.props.isComplete ? 'load-complete ' : '')}>
        <div className={(this.props.isComplete ? 'show' :  '') + " checkmark draw"}></div>
      </div>
    );
  }
};


export default CircleLoaderCheckmark;
