import React from 'react';
import ToolTip from 'react-portal-tooltip';
import { debounce } from "debounce";


const style = {
  style: {
    background: '#2b2b2b',
    padding: 10,
    borderColor: "#333333"
    // boxShadow: '5px 5px 3px rgba(0,0,0,.5)'
  },
  arrowStyle: {
    color: '#2b2b2b',
    borderColor: "#333333"
  }
}


class TextOnlyTooltipComponent extends React.Component {

  constructor(props) {
    super(props);
    this.debouncedMouseOver = debounce(this.showTooltip, 850);

  }

  state = {
    isTooltipActive: false
  }

  handleMouseOver(){
    this.setState({alreadyLeft: false});
    this.debouncedMouseOver();
  }

  showTooltip() {
    if(!this.state.alreadyLeft){
      this.setState({isTooltipActive: true, alreadyLeft: false})
    }
  }
  hideTooltip() {
    this.setState({isTooltipActive: false, alreadyLeft: true});
  }

  render() {

    return (
        <>

        {React.cloneElement(this.props.render(this.state), { onMouseEnter: this.handleMouseOver.bind(this), onMouseLeave: this.hideTooltip.bind(this) })}


        <ToolTip className={"textOnlyTooltip"}
                 active={this.state.isTooltipActive}
                 position={this.props.position || 'top'}
                 arrow={this.props.arrowPosition || "center"}
                 parent={"#" + this.props.refId } // this must start with #
                 style={style}
        >
          <div>
            <p style={{fontSize: "0.75em", color: "#dedede"}}>{this.props.text}</p>
          </div>
        </ToolTip>
      </>
    )
  }
}

export default TextOnlyTooltipComponent;
