import React, { Component, location, history } from 'react';
import { observer } from "mobx-react";
import {RemoveScroll} from 'react-remove-scroll';
import { withFirestore } from 'react-firestore';
import { Route, Link } from "react-router-dom";



// import { observer } from 'mobx-react';

const MIN_PADDING_TOP = 30;
const MIN_PADDING_BOTTOM = 30 + 72;
const MIN_PADDING_LEFT = 30;
const MIN_PADDING_RIGHT = 30;

function getBestLargestAreaFitForCanvasByRatio(ratio, canvasWidth, canvasHeight){
  // if witdh > height ==> ratio > 1
  let width = 0;
  let height = 0;

  // console.log('RATIO: ', ratio);
  // console.log('canvas width: ', canvasWidth);
  // console.log('canvas height: ', canvasHeight);
  if(ratio > 1){
    height = canvasHeight;
    width = canvasHeight / ratio;

    if(width > canvasWidth){
      width = canvasWidth;
      height = canvasWidth * ratio;
    }
  }else{
    width = canvasWidth;
    height = canvasWidth * ratio;

    if(height > canvasHeight){
      height = canvasHeight;
      width = canvasHeight / ratio;
    }
  }

  return { width: width, height: height }


}

const PhotoOverlay = observer(
  class PhotoOverlay extends Component {
    imgEl = React.createRef();


    state = {
      containerWidth: 0,
      imageHeight: 0,
      rating: undefined
    }

    constructor(props) {
      super(props);
    }


    forceDownload(url, fileName){
      fileName = fileName.split(" ").join('_').toLowerCase();

      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
      }
      xhr.send();
    }

    componentDidUpdate(prevProps, prevState, snapshot){
      if(prevProps !== this.props){
        // this.updateRatio();
        if(prevProps.snapshot !== this.props.snapshot){
          this.setState({ rating: undefined });
        }
      }
    }



    setRating(rating){
      const { firestore } = this.props;
      firestore.collection('Snapshots').doc(this.props.snapshot.id).update({ rating: rating }).then(() => {
        this.setState({ rating: rating });
      });
    }

    updateRatio(naturalWidth, naturalHeight){
      var width = 0;
      var height = 0;
      if(this.props.snapshot){
        const snapshotHeight = this.props.snapshot.data().fovCalc.height;
        const snapshotWidth = this.props.snapshot.data().fovCalc.width;

        const maxWidth = this.props.width > (MIN_PADDING_LEFT + MIN_PADDING_RIGHT ) ? (this.props.width - MIN_PADDING_LEFT - MIN_PADDING_RIGHT) : 0;
        const maxHeight = this.props.height > (MIN_PADDING_TOP + MIN_PADDING_BOTTOM ) ? (this.props.height - MIN_PADDING_TOP - MIN_PADDING_BOTTOM) : 0;
        const aspect = snapshotHeight/snapshotWidth;

        const bestFit = getBestLargestAreaFitForCanvasByRatio(aspect, maxWidth, maxHeight);

        bestFit.width = bestFit.width > naturalWidth ? naturalWidth : bestFit.width;
        bestFit.height = bestFit.height > naturalHeight ? naturalHeight : bestFit.height;

        // this.containerRef.current.offsetWidth = bestFit.width;

        this.setState({
          containerWidth: bestFit.width,
          imageHeight: bestFit.height
        });
      }

    }

    // interaction
    render() {
      return (
        <RemoveScroll className={"react-portal " + (this.props.isVisible ? 'show' : 'hidden')} enabled={this.props.isVisible}>
          <div className="backend-photo-overlay-container" onClick={(event) => {this.props.hideFunction(event)} } >
            <img src="images/noun_X_1890803.svg" alt="" className="image-5" />
            <div className="overlay-photo-container" style={{width: this.state.containerWidth}}>
              <img src={ (this.props.snapshot ? this.props.snapshot.data().renderinfo.image_url : "") } style={{width: this.state.containerWidth }} ref={this.imgEl}
                   onLoad={() => {this.updateRatio(this.imgEl.current.naturalWidth, this.imgEl.current.naturalHeight);}} />
              <div className="div-block-13">
                <div className="div-block-14">
                  <div className="text-block-28">Do you like the Photo?</div>
                  <div className={ "smiley green w-embed "  + (this.props.snapshot ? ( (this.props.snapshot.data().rating === "good" && this.state.rating === undefined) || this.state.rating === "good" ? "active" : "") : "")  } onClick={() => {this.setRating("good")}}>
                    <span dangerouslySetInnerHTML={{__html: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26.833\" height=\"26.832\" viewBox=\"0 0 26.833 26.832\"\n" +
                      "                       fill=\"currentColor\">\n" +
                      "                    <g id=\"Group_23\" data-name=\"Group 23\" opacity=\"1\">\n" +
                      "                      \n" +
                      "                      <path id=\"Path_14\" data-name=\"Path 14\"\n" +
                      "                            d=\"M5.538,0A6.065,6.065,0,0,0,.144,3.361,1.337,1.337,0,0,0,.765,5.169a1.361,1.361,0,0,0,1.808-.621,3.313,3.313,0,0,1,5.931,0,1.335,1.335,0,0,0,1.215.763,1.518,1.518,0,0,0,.593-.141,1.361,1.361,0,0,0,.621-1.808A6.065,6.065,0,0,0,5.538,0Z\"\n" +
                      "                            transform=\"translate(18.942 20.61) rotate(180)\" fill=\"currentColor\"></path>\n" +
                      "                      <path id=\"Path_15\" data-name=\"Path 15\"\n" +
                      "                            d=\"M15.916,2.5A13.416,13.416,0,1,0,29.332,15.916,13.428,13.428,0,0,0,15.916,2.5Zm0,24.149A10.733,10.733,0,1,1,26.649,15.916,10.746,10.746,0,0,1,15.916,26.649Z\"\n" +
                      "                            transform=\"translate(-2.5 -2.5)\" fill=\"currentColor\"></path>\n" +
                      "                      <circle id=\"Ellipse_5\" data-name=\"Ellipse 5\" cx=\"2.203\" cy=\"2.203\" r=\"2.203\"\n" +
                      "                              transform=\"translate(7.005 8.134)\" fill=\"currentColor\"></circle>\n" +
                      "                      <circle id=\"Ellipse_6\" data-name=\"Ellipse 6\" cx=\"2.203\" cy=\"2.203\" r=\"2.203\"\n" +
                      "                              transform=\"translate(15.422 8.134)\" fill=\"currentColor\"></circle>\n" +
                      "                    </g>\n" +
                      "                  </svg>" }} />

                  </div>
                  <div className={"smiley red w-embed " + (this.props.snapshot ? ( (this.props.snapshot.data().rating === "bad" && this.state.rating === undefined) || this.state.rating === "bad" ? "active" : "") : "")  }  onClick={() => {this.setRating("bad")}}>
                    <span dangerouslySetInnerHTML={{__html: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26.833\" height=\"26.832\" viewBox=\"0 0 26.833 26.832\"\n" +
                      "                       fill=\"currentColor\">\n" +
                      "                    <g id=\"Group_19\" data-name=\"Group 19\" transform=\"translate(0)\" opacity=\"1\">\n" +
                      "                      <path id=\"Path_14\" data-name=\"Path 14\"\n" +
                      "                            d=\"M35.93,53.9a6.065,6.065,0,0,0-5.395,3.361,1.337,1.337,0,0,0,.621,1.808,1.361,1.361,0,0,0,1.808-.621,3.313,3.313,0,0,1,5.931,0,1.335,1.335,0,0,0,1.215.763,1.518,1.518,0,0,0,.593-.141,1.361,1.361,0,0,0,.621-1.808A6.065,6.065,0,0,0,35.93,53.9Z\"\n" +
                      "                            transform=\"translate(-22.514 -39.382)\" fill=\"currentColor\"></path>\n" +
                      "                      <path id=\"Path_15\" data-name=\"Path 15\"\n" +
                      "                            d=\"M15.916,2.5A13.416,13.416,0,1,0,29.332,15.916,13.428,13.428,0,0,0,15.916,2.5Zm0,24.149A10.733,10.733,0,1,1,26.649,15.916,10.746,10.746,0,0,1,15.916,26.649Z\"\n" +
                      "                            transform=\"translate(-2.5 -2.5)\" fill=\"currentColor\"></path>\n" +
                      "                      <circle id=\"Ellipse_5\" data-name=\"Ellipse 5\" cx=\"2.203\" cy=\"2.203\" r=\"2.203\"\n" +
                      "                              transform=\"translate(7.005 8.134)\" fill=\"currentColor\"></circle>\n" +
                      "                      <circle id=\"Ellipse_6\" data-name=\"Ellipse 6\" cx=\"2.203\" cy=\"2.203\" r=\"2.203\"\n" +
                      "                              transform=\"translate(15.422 8.134)\" fill=\"currentColor\"></circle>\n" +
                      "                    </g>\n" +
                      "                  </svg>" }} />
                  </div>
                </div>
                <div className="div-block-15" onClick={() => {this.forceDownload(this.props.snapshot.data().renderinfo.image_url, this.props.snapshot.data().metadata.name ? this.props.snapshot.data().metadata.name +'.png' : 'zerolens_image.png')}}>
                  <div>Download</div>
                </div>
              </div>
            </div>
          </div>
        </RemoveScroll>
      );
    }

  }
);





export default withFirestore(PhotoOverlay);
