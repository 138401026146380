import React, { Component, location, history, useRef } from 'react';
import '../page_elements/nav/Nav.css';
import { observer } from "mobx-react"
import ReactDOM from 'react-dom';
import {RemoveScroll} from 'react-remove-scroll';
import Unity, { UnityContent } from "react-unity-webgl";
import { FirestoreDocument, withFirestore } from 'react-firestore';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { withRouter} from "react-router-dom";


// components
import ViewerMenu from './viewer_elements/settings/ViewerMenu';
import ViewerMenuTop from './viewer_elements/settings/ViewerMenuTop';
import AspectRatio from './viewer_elements/AspectRatio';
import CameraButton from './viewer_elements/CameraButton';
import LoadingCircleOverlay from '../helpers/LoadingCircleOverlay';
import EnvironmentPreviewOverlay from '../helpers/EnvironmentPreviewOverlay/EnvironmentPreviewOverlay';
import ViewerPanel from '../viewer_sidebar/ViewerPanel';
import CameraOverlay from './viewer_elements/CameraOverlay';
import Help from './viewer_elements/Help';
import TopBar from './TopBar/TopBar';
import WatermarkOverlay from './viewer_elements/WatermarkOverlay';
import { joyRideInteractionsStart, joyRideEventHandler } from './../helpers/joyrideHelpers/joyrideHelpers';

// functions
import { convertModifierTextToTitleWithoutColor } from '../../functions/snapshot_helpers';
import { joyRideStepSettings } from './../helpers/joyrideHelpers/joyrideHelpers';

// images
import xImg from '../../images/noun_X_1890803.svg';
import RenderDialog from './viewer_elements/RenderDialog';
import Properties from './Properties/Properties';
import PropertiesEnvironment from './PropertiesEnvironment/PropertiesEnvironment';


const ADD_PANEL = "add";
const ENVIRONMENT_PANEL = "environment";
const OBJECT_PANEL = "object";
const PRODUCTS_PANEL = "add_products";
const DEFAULT_ACTIVE_PANEL = "add";
const DEFAULT_PHOTO_PANEL = "photo";
const DEFAULT_HELP_PANEL = "help";



const Viewer = observer(class Viewer extends Component {
  isEnvironmentWaitingToBeLoaded = false;
  environemntWaitedToBeLoaded = undefined;
  isSnapshotWaitingToBeLoaded = false;
  snapshotWaitingtoBeLoaded = undefined;
  viewerSizeElement = React.createRef();
  environmentId = undefined;
  snapshot = undefined;

  currentViewerMode = undefined;
  currentSnapshotId = undefined;
  currentTemplateId = undefined;

  // handling snapshot data


  state = {
    activePanel: DEFAULT_ACTIVE_PANEL,
    isObjectSelected: false,
    isUnityLoaded: false,
    aspectRatio: 'ig_portrait',
    quality: 'social_media',
    dof: 'strong',
    gravity: false,
    autofocus: true,
    isShowingLoadingCircleOverlay: true,
    isShowingEnvironmentPreviewOverlay: true,
    isLoadingCircleCompleted: false,
    currSelection: {},
    reccomendedColorsGlobally: [],


    // important for loading a new snapshot / environment
    modifiersNewlyPrepared: false,
    environmentModifers: [],
    unityModifiers: [],
    currEnv: {},



    // selected Object Settings
    selectedObjectCanChangeColor: true,
    cameraWidth: 0,
    cameraHeight: 0,
    orientation: "landscape",
    unbalancedpx: 0,
    snapshotDownloadProgress: 0,
    loadingCircleText: "Loading Viewer",
    showReturnButton: false,
    currentTranfsormTool: 'Rotate',
    selection: "1",
    overflow: 0,
    vertFOV: 25,
    viewerMenuTopHidden: false,
    snapshot: undefined,
    aspect: {
      x: 0,
      y: 0
    },
    blurBackground: false,
    isShowingHelp: false,
    mouserHoveOverCameraButton: false,
    isRenderDialogVisible: false,
    isCurrentSnapshotDraft: false,
    grid:false,
    isEnvPropertyVisible:false
  };

  constructor(props) {
    super(props);

    // Next up create a new Unity Content object to
    // initialise and define your WebGL build. The
    // paths are relative from your index file.

    this.handleOptionSelect = this.handleOptionSelect.bind(this);
    this.handleTransforms = this.handleTransforms.bind(this);
    this.handleGeneralSettings = this.handleGeneralSettings.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.setOverflow = this.setOverflow.bind(this);
    this.hideHelp = this.hideHelp.bind(this);
    this.onPressHelp = this.onPressHelp.bind(this);
    this.loadSnapshotSlowly = this.loadSnapshotSlowly.bind(this);
    this.changeGrid = this.changeGrid.bind(this);

    this.snapshotType = undefined;
    this.snapshotName = undefined;
    this.backgroundURL = undefined;


    this.unityContent = new UnityContent(
      "/Build/WebBuild.json",
      "/Build/UnityLoader.js"
    );


    this.unityContent.on("loaded", () => {
      this.unityContent.send('Network', 'focusCanvas', "0");

    });


    this.unityContent.on("GameControlReady", () => {
      const { firestore } = this.props;

      // Now we can use the score to for example
      // display it on our React app.

      firestore.doc('ImageEnvironment/' + this.props.environmentId).onSnapshot(snapshot => {
        // TODO: call function to push this into unity viewer --> if is loaded, if not run function in loading
      });

    });


    this.unityContent.on("stopSnapshotLoadingSpinner", (snapshotId) => {
      // TODO: remove loading screen

      this.setState({
        isShowingEnvironmentPreviewOverlay: false,
        isShowingLoadingCircleOverlay: false,
        isLoadingCircleCompleted: true,
        showReturnButton: false,
        snapshotDownloadProgress: 0
      });

      this.handleGeneralSettings("aspectRatio", this.snapshot.image.aspect);
      this.handleGeneralSettings("dof", this.snapshot.image.dof);

      // check if we are in an interactive tutorial
      if(this.props.joyrideRunning){
        joyRideInteractionsStart(this.unityContent, this.props.joyrideStepIndex, this.props.setJoyRideStepByIndex);
      }

    });

    this.unityContent.on("snapshotLoadingProgressUpdate", (argument) => {
      var argumentArray = argument.split("␞");
      const snapshotFirebaseId = argumentArray[0];
      const progress = argumentArray[1];
      this.setState({ snapshotDownloadProgress: progress });
    });

    this.unityContent.on("GameControlReady", score => {
      this.store.isUnityAlreadyLoaded = true;
      this.setState({
        isUnityLoaded: true,
        isShowingLoadingCircleOverlay: false,
        isShowingEnvironmentPreviewOverlay: false,
        showReturnButton: false,
      });


      if (this.isEnvironmentWaitingToBeLoaded && this.environemntWaitedToBeLoaded) {
        this.loadEnvironmentByFirestoreId(this.environemntWaitedToBeLoaded);
      }

      if (this.isSnapshotWaitingToBeLoaded && this.snapshotWaitingtoBeLoaded) {
        this.loadSnapshotBySid(this.snapshotWaitingtoBeLoaded);
      }

      // Now we can use the score to for example
      // display it on our React app.

    });

    this.unityContent.on('getSnapshotForRecColorsSnapshotData', (snapshot) => {
      const snapshotData = JSON.parse(snapshot);
      this.getReccomendedColorsFromCurrentProductsInSnapshot(snapshotData);

    });
    // Network → requestCurrentSnapsthotData(funcName)
    // this.getReccomendedColorsFromCurrentProductsInSnapshot(this.snapshot);



    this.unityContent.on("SendSnapshotDataToExternalHandler", (snapshot) => {
      let snapshotData = JSON.parse(snapshot);

      const imageQuality = this.state.quality;
      const dof = this.state.dof;
      const snapshotTitle = this.props.store.currEnvironmentName;
      const aspect = this.state.aspectRatio;

      snapshotData.image.quality = imageQuality;
      snapshotData.image.aspect = aspect;
      snapshotData.image.dof = dof;
      snapshotData.metadata.title = snapshotTitle;

      const canvasHeight = this.viewerSizeElement.clientHeight + this.state.unbalancedpx;
      const canvasWidth = this.viewerSizeElement.clientWidth;

      snapshotData.fovCalc = {
        canvasHeight: canvasHeight.toString(),
        canvasWidth: canvasWidth.toString(),
        height: this.state.cameraHeight.toString(),
        orientation: this.state.orientation,
        width: this.state.cameraWidth.toString(),
      };

      if (this.props.user && this.props.org) {
        const currBalance = this.props.org ? this.props.org.credits : 0;
        const currentRenderCost = this.state.isCurrentSnapshotDraft ? 1 : this.props.rendercost.quality[this.state.quality][this.state.aspectRatio];

        if (currBalance > currentRenderCost) {
          const email = this.props.user.email;
          snapshotData.renderinfo.email = email;
          snapshotData.renderinfo.user.email = email;

          snapshotData.uid = this.props.user.uid;
          snapshotData.oid = this.props.user.oid;

          // setting snapshot render properties
          snapshotData.metadata.title = this.snapshotName;
          snapshotData.type = this.snapshotType;
          snapshotData.renderpath = this.renderpath;
          snapshotData.isDraft = this.state.isCurrentSnapshotDraft;

          axios.post('/api/snapshot/', snapshotData, { withCredentials: true })
            .then((response) => {
              this.setState({
                isLoadingCircleCompleted: true,
                loadingCircleText: "Success. Your image will be ready soon. " + currentRenderCost + " Credits will be subtracted from your balance.",
                showReturnButton: true,
              });
            })
            .catch((error) => {
              console.log('error: ', error);
            });
        } else {
          this.setState({
            isLoadingCircleCompleted: true,
            loadingCircleText: "YOU DON'T HAVE ENOUGH CREDITS TO TAKE THIS PHOTO. PLEASE TOP UP YOUR BALANCE",
            showReturnButton: true,
          });
        }


      } else {
        this.setState({
          isLoadingCircleCompleted: true,
          loadingCircleText: "You need to be logged in to create images",
          showReturnButton: true,
        });
      }

    });


    this.unityContent.on("SnapshotUpdateSuccess", (snapshot) => {
      // TODO:
    });

    this.unityContent.on("showInfo", () => {
      // TODO:
    });

    this.unityContent.on("showInfo", (info) => {
      // TODO:
    });

    this.unityContent.on("newSnapshotData", () => {
      // TODO:
    });

    this.unityContent.on("assetBundleProgress", (percent) => {
      // TODO:
    });

    this.unityContent.on("ProductSelected", (argument) => {
      // TODO HANDLE OBJECT INFORMATION
      this.handleProductSelection(argument);
    });

    this.unityContent.on("sendModifiersOnEnvironment", (argument) => {
      var currArguments = argument.split("␞");
      const currEnvFirebaseId = this.environmentId;
      const currEnvModifiers = JSON.parse(currArguments[1]);

      if(this.state.modifiersNewlyPrepared){
        // applying parsed modifiers to Unity so Unity knows about every modifier;
        this.unityContent.send('ModifierManager', 'applyModifierToEnvironment', JSON.stringify(this.state.unityModifiers));
        this.setState({  isEnvPropertyVisible: true, modifiersNewlyPrepared: false });

      }else{
        this.setState({
          currEnv: {
            firebaseId: currEnvFirebaseId ,
            modifiers: currEnvModifiers,
          },
          isEnvPropertyVisible: true
        });
      }
      this.updateRecColorsFromSnapshot();

    });

    this.unityContent.on("DeselectProducts", () => {

      if (this.state.activePanel === OBJECT_PANEL) {
        this.setState({ activePanel: DEFAULT_ACTIVE_PANEL });
      }
      this.setState({ isObjectSelected: false });


    });

    // UndoManager → undo(): undoes the last action.
    // UndoManager → redo(): redoes the last undone action.
    // UndoManager → pushExternalEvent(eventstring): Can be used to tell unity to push an external event such as aspect ratio change or anything else that unity is not aware of. In case of an undo, the same string is returned 1:1 via

    // onUserHasInteractionToolChanged(string tool); // called when user changed the interaction tool via button. Possible parameter values: "None", "Move", "Rotate", "Scale", "View".
    // undoUnityExternalEvent(string eventstring); //called if the user undoes an event that has been pushed earlier via UndoManager → pushExternalEvent(eventstring). It returns comma seperated values, the first is the type, the next the parameters. By default, it will call gravity and modifier changes: "gravity,off",
    // sendTrackingEvent(trackingEvent); //called if the user did a trackable action. Tracking event is a json.
    // modifierChangedByUnity(firebaseId_type_modifierjson) same as productSelected, but only fired by undo, can be handled differently (e.g. w/o tracking).


    // [UNDO EXTERNAL EVENT]
    this.unityContent.on("undoUnityExternalEvent", (argument) => {
      var [tool, value] = argument.split(',');
      // tool = tool.toLowerCase();
      // value = value.toLowerCase();
      if (tool === "gravity" ||tool === "Gravity") {
        this.setState({ gravity: ( value === "false" || !value || value === "False" ) ? false : true });
      }else if(tool === "quality"){
        const [prevQuality, prevAspectRatioQuality, prevAspectQualityX, prevAspectQualityY] = value.split("|");
        this.setState({
          quality: prevQuality,
          aspectRatio: prevAspectRatioQuality,
          aspect: {x: prevAspectQualityX, y: prevAspectQualityY}
        });
      }else if(tool === "aspectRatio"){
        const [prevValueAspectRatio, prevValueAspectX, prevValueAspectY ] = value.split("|");
        this.setState({
          aspectRatio: prevValueAspectRatio,
          aspect: { x: prevValueAspectX, y: prevValueAspectY }
        });
      }else if(tool === "dof"){
        this.setState({dof: value});
      }else if(tool === "fov"){
        this.setState({ vertFOV: value});
        this.unityContent.send('CameraController', 'setVerticalFoV', value);
      }else if(tool==="setFocusMode"){
        this.setState({autofocus: value === 'AutoFocus' ? true : false});
        this.unityContent.send('CameraController', 'setFocusMode', value);
      }else if(tool === "format"){
        const [prevQuality, prevValueAspectRatio, prevValueAspectX, prevValueAspectY ] = value.split("|");
        this.setState({
          quality: prevQuality,
          aspectRatio: prevValueAspectRatio,
          aspect: { x: prevValueAspectX, y: prevValueAspectY }
        });
      }
    });






    this.unityContent.on("sendTrackingEvent", (argument) => {
      //console.log("[Unity Event] ", JSON.parse(argument));
      console.log('JOYRIDE EVENT: ', JSON.parse(argument));
      const action = JSON.parse(argument);

      if(this.props.joyrideRunning) {
        // joyRideEventHandler(this.unityContent, JSON.parse(argument));
        if (this.props.joyrideStepIndex === joyRideStepSettings.templateRotateId && action.additionalInfo === "Camera movement: Rotate") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateRotateId + 1, true);
        } else if (this.props.joyrideStepIndex === joyRideStepSettings.templateMoveId && action.additionalInfo === "Camera movement: Translate") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateMoveId + 1, true);
        } else if (this.props.joyrideStepIndex === joyRideStepSettings.templateMoveObjectId &&
          action.action == "Change Scene State" &&
          action.additionalInfo && action.additionalInfo.type == "ManipulateObject" &&
          action.additionalInfo.object == "praline_03" &&
          action.additionalInfo.tool == "PhysicalPlace") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateMoveObjectId + 1, true);
        } else if (this.props.joyrideStepIndex === joyRideStepSettings.templateAddObjectId &&
          action.action == "Change Scene State" &&
          action.additionalInfo && action.additionalInfo.type == "AddObject") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateAddObjectId + 1);
        } else if (this.props.joyrideStepIndex == joyRideStepSettings.templateRotateObjectId &&
          action.action == "Change Scene State" &&
          action.additionalInfo &&
          action.additionalInfo.type == "ManipulateObject" &&
          action.additionalInfo.tool == "Rotate") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateRotateObjectId + 1, true);
        } else if (this.props.joyrideStepIndex == joyRideStepSettings.templateDeleteObjectId &&
          action.action == "Change Scene State" &&
          action.additionalInfo &&
          action.additionalInfo.type == "DeletedObject") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateDeleteObjectId + 1);
        } else if (this.props.joyrideStepIndex == joyRideStepSettings.templateDeleteProductId &&
          action.action == "Change Scene State" &&
          action.additionalInfo &&
          action.additionalInfo.type == "DeletedObject" &&
          action.additionalInfo.object == "standing_pouch_coffee(Clone)") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateDeleteProductId + 1);
        } else if (this.props.joyrideStepIndex === joyRideStepSettings.templateAddProductId &&
          action.action == "Change Scene State" &&
          action.additionalInfo && action.additionalInfo.type == "AddObject") {
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateAddProductId + 1);
        } else if( this.props.joyrideStepIndex === joyRideStepSettings.templatePlaceOnPlaneId &&
          action.action == "Change Scene State" &&
          action.additionalInfo &&
          action.additionalInfo.type == "ManipulateObject" &&
          action.additionalInfo.tool == "PhysicalPlace"){
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templatePlaceOnPlaneId + 1);
        } else if( this.props.joyrideStepIndex === joyRideStepSettings.templateZoomInId &&
          action.action == "Change Camera" &&
          action.additionalInfo &&
          action.additionalInfo == "Camera movement: Zoom"){
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateZoomInId + 1);
        }
      }
      window.analytics.track('Unity Event', JSON.parse(argument));
    });

    this.unityContent.on("modifierChangedByUnity", (argument) => {
      //console.log("[Unity Event] ", JSON.parse(argument));
      // this.handleProductSelection(argument);
    });

  }

  handleProductSelection(argument){
    var currArguments = argument.split("␞");
    const currSelectedFirebaseId = currArguments[0];
    const currSelectedType = currArguments[1];
    var currModifiersOnSelected = JSON.parse(currArguments[2]); // replaces all =  with : so we can use JSON.parse
    console.log('UNITY MODS: JSON.PARSE: TEST VAR ', JSON.stringify(currModifiersOnSelected));


    // this.handleOptionSelect("Object"); // change here if you want to show a different panel on select

    this.setState({
      isObjectSelected: true,
      currentTranfsormTool: currArguments[3] || 'Rotate',
      currSelection: {
        firebaseId: currSelectedFirebaseId,
        type: currSelectedType,
        modifiers: currModifiersOnSelected
      },
      selection: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    });

    // TODO: TEST ___ add the function here where we get the currentSnapshot and then run it
    this.updateRecColorsFromSnapshot();
  }

  updateRecColorsFromSnapshot(){
    this.unityContent.send('Network', 'requestCurrentSnapsthotData', 'getSnapshotForRecColors');
  }

  showSnapshotLoadingScreen(){
    if(this.store.isUnityAlreadyLoaded){
      this.unityContent.send('Network', 'focusCanvas', "0");
      this.setState({
        isShowingEnvironmentPreviewOverlay: true,
        isShowingLoadingCircleOverlay: true,
        isLoadingCircleCompleted: false,
        loadingCircleText: 'Loading Environment',
        showReturnButton: false,
        snapshotDownloadProgress: 0
      });
    }
  }

  modifierOptionToJson(modifierOption){
    var option = {}
    modifierOption.forEach( element => {
      const singleOptionArray = element.split("=");
      option[singleOptionArray[0]] = singleOptionArray[1];
    });
    return option;
  }

  modifierOptionToString(modifierOptionJson){
    var option = [];

    Object.keys(modifierOptionJson).forEach((key) => {
      const singleOption = `${key}=${modifierOptionJson[key]}`;
      option.push(singleOption);
    });

    return option;
  }

  loadEnvironmentDataFromFirestoreAndPrepareEnvironmentModifiersForUnity(environmentId, snapshot){

    // 1. Loading environment information from Firestore
    const { firestore } = this.props;
    firestore.doc('Environments/' + environmentId).onSnapshot(environmentData => {
      const currEnvironment = environmentData.data();

      // 2. create modifier JSON for Unity from snapshot data and environment
      // this will make sure, that all modifiers from firestore are also applied in unity (important for click on env)
      // modifiers that are in the snapshot but are not registered in firestore will be kept but not shown to the user
      const currSnapshotEnvironmentModifiers = snapshot.telemetry.virtualScene.modifiers || [];
      const currFirestoreEnvironmentModifiers = currEnvironment ? (currEnvironment.modifiers || []) : [];

      var finalUnityModifiers = [];
      var reactEnvironmentModifierList = {};

      currFirestoreEnvironmentModifiers.forEach((firestoreModifier) => {
        // converting options in format ["materialName=1_material_xxxx", "isColorChangable=true", ...] to JSON
        firestoreModifier.options = this.modifierOptionToJson(firestoreModifier.options);
        const materialNameSearchingFor = firestoreModifier.options.materialName;
        var foundMatch = false;

        currSnapshotEnvironmentModifiers.forEach((snapsotModifier) => {
          const snapshotOptions = this.modifierOptionToJson(snapsotModifier.options);
          if(snapshotOptions.materialName == materialNameSearchingFor){
            foundMatch = true;
            finalUnityModifiers.push(snapsotModifier); // adding the raw snapshot Modifier back in
            const currColor = snapshotOptions.defaultColor || undefined;
            var colorRecMod = firestoreModifier.colorRec || [];

            if(currColor){colorRecMod.unshift(currColor)}
            colorRecMod = [...new Set(colorRecMod)];

            var singleReactModifier = {
              modifierViewerText: firestoreModifier.modifierViewerText || "Change Material",
              type: firestoreModifier.type,
              colorRec: colorRecMod
            }

            reactEnvironmentModifierList[materialNameSearchingFor] = singleReactModifier;

          }
        });

        if(!foundMatch){
          var singleUnityModifier = {
            options: this.modifierOptionToString(firestoreModifier.options),
            type: firestoreModifier.type
          };

          finalUnityModifiers.push(singleUnityModifier);

          var singleReactModifier = {
            modifierViewerText: convertModifierTextToTitleWithoutColor(firestoreModifier.modifierViewerText) || "Change Material",
            type: firestoreModifier.type,
            colorRec: firestoreModifier.colorRec || []
          }
          reactEnvironmentModifierList[materialNameSearchingFor] = singleReactModifier;
        }

      });

      this.setState({ environmentModifers: reactEnvironmentModifierList, unityModifiers: finalUnityModifiers, modifiersNewlyPrepared:true });

    });
  }

  async getReccomendedColorsFromCurrentProductsInSnapshot(snapshot){
    var reccomendedColors = [];
    var productsAlreadyFetched = [];

    const productArray = snapshot.telemetry.product;

    // reading out curr set default color:
    productArray.forEach((element) => {
      if (element.type === 'product') {
        if(element.productColorRecommondation && Array.isArray(element.productColorRecommondation)){
          reccomendedColors = reccomendedColors.concat(element.productColorRecommondation);
          // element.modifiers.forEach((modifier) => {
          //   var currElementOptions = this.modifierOptionToJson(modifier.options);
          //   console.log(`colorRec options: ${currElementOptions}`);
          //   if(currElementOptions.defaultColor){
          //     reccomendedColors.push(currElementOptions.defaultColor);
          //   }
          // });
        }
      }
    });

    reccomendedColors = [...new Set(reccomendedColors)];
    console.log(`colorRec: ${JSON.stringify(reccomendedColors)}`);
    this.setState({reccomendedColorsGlobally: reccomendedColors});




    productArray.forEach((element) => {
      if(element.type === 'product'){
        const { firestore } = this.props;

        // this.showSnapshotLoadingScreen();

        if(!productsAlreadyFetched.includes(element.objectID)){
          productsAlreadyFetched.push(element.objectID);
          firestore.doc('Products/' + element.objectID).onSnapshot(productData => {
            const product = productData.data();
            if(product.productColorRecommondation && Array.isArray(product.productColorRecommondation)){
              console.log('IS REC COLOR AND ARRAY');
                reccomendedColors = reccomendedColors.concat(product.productColorRecommondation);
                reccomendedColors = [...new Set(reccomendedColors)];
                this.setState({reccomendedColorsGlobally: reccomendedColors});
                }
              })
            }
        }
    });
  }

  prepareForViewerSetupBeforeUnityIsLoadingSnapshot(snapshot){
    // 1. set snapshot variables
    this.snapshot = snapshot;
    this.setState({snapshot: snapshot, reccomendedColorsGlobally: []});

    this.snapshotType = snapshot.type;
    this.snapshotName = snapshot.name;
    this.environmentId = snapshot.metadata.environmentId;
    var currStore = this.props.store;
    currStore.currentEnvironmentPreview = snapshot.renderinfo.image_url;
    this.props.updateStore( currStore );
    if(this.snapshotType == 'environment25D'){
      this.renderpath = snapshot.renderpath;
    }
    // 2. setting aspect ratio
    const x =  this.props.rendercost.sizes ? this.props.rendercost.sizes[snapshot.image.quality][snapshot.image.aspect].width : 1;
    const y =  this.props.rendercost.sizes ? this.props.rendercost.sizes[snapshot.image.quality][snapshot.image.aspect].height : 1;
    this.setState({
      aspectRatio: snapshot.image.aspect,
      aspect: { x: x, y: y },
      quality: snapshot.image.quality
    });
    // 3. load environment data
    this.getReccomendedColorsFromCurrentProductsInSnapshot(snapshot);
    this.loadEnvironmentDataFromFirestoreAndPrepareEnvironmentModifiersForUnity(this.environmentId, snapshot);
  }



  loadSnapshotBySid(snapshotId){
    const { firestore } = this.props;

    this.showSnapshotLoadingScreen();
    this.handleGeneralSettings("gravity-option", false);
    firestore.doc('Snapshots/' + snapshotId).onSnapshot(snapshot => {
      this.prepareForViewerSetupBeforeUnityIsLoadingSnapshot(snapshot.data());
      this.unityContent.send('SceneRecreator', 'recreateScene', JSON.stringify(snapshot.data()));
      // TODO: call function to push this into unity viewer --> if is loaded, if not run function in loading
    });
  }


  async Sleep(milliseconds) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }

  async loadSnapshotSlowly(){
    var currProducts = Object.assign({}, this.snapshot.telemetry.product);
    var numberOfObjects = Object.keys(currProducts).length;
    var currSnapshot = {... this.snapshot };
    currSnapshot.telemetry.product = [];

    this.unityContent.send('SceneRecreator', 'recreateScene', JSON.stringify(currSnapshot));
    var that = this;

    console.log('OBJ LOADER: CURR PRODUCTS', currProducts.length);
    // load all objects anywhere to cache them and then wait
    for(var i = 0; i<numberOfObjects; i++){
      console.log('OBJ LOADER: INSIDE', currProducts[i]);
      var currProductsInSky = { ... currProducts[i] };
      currProductsInSky.position = {x: 99, y:99, z:99 };
      console.log('OBJ LOADER: PRELOAD: trying to load obj: ', currProductsInSky );
      that.unityContent.send('SceneLoader', 'loadProductOrObjectWithJSON', JSON.stringify(currProductsInSky));
    }

    console.log('OBJ LOADER: before', new Date());
    await this.Sleep(5000);
    console.log('OBJ LOADER: after', new Date());

    for(var i = 0; i<numberOfObjects; i++){
      await this.Sleep(70);
      var currProductsInSky = { ... currProducts[i] };
      console.log('OBJ LOADER: FINAL LOADING: trying to load obj: ', currProductsInSky );
      that.unityContent.send('SceneLoader', 'loadProductOrObjectWithJSON', JSON.stringify(currProductsInSky));
    }



  }

  loadEnvironmentByFirestoreId(environmentId){
    const { firestore } = this.props;

    if(this.store.isUnityAlreadyLoaded){
      // CATCH ALL KEYBOARD INPUTS
      this.unityContent.send('Network', 'focusCanvas', "1");

      this.setState({
        isShowingEnvironmentPreviewOverlay: true,
        isShowingLoadingCircleOverlay: true,
        isLoadingCircleCompleted: false,
        loadingCircleText: 'Loading Environment',
        showReturnButton: false,
        snapshotDownloadProgress: 0
      });

      // TODO: jump back
      this.handleGeneralSettings("gravity-option", false);

      firestore.doc('ImageEnvironment/' + environmentId).onSnapshot(snapshot => {

        this.prepareForViewerSetupBeforeUnityIsLoadingSnapshot(snapshot.data().snapshot);
        this.unityContent.send('SceneRecreator', 'recreateScene', JSON.stringify(snapshot.data().snapshot));
        // TODO: call function to push this into unity viewer --> if is loaded, if not run function in loading
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){

    var newSid = undefined;
    var newTempplateId = undefined;
    if(this.props.location.pathname.split('/sid/')[1]){
      this.currentViewerMode = "snapshot";
      newSid = this.props.location.pathname.split('/sid/')[1];
    }else if (this.props.location.pathname.split('/template/')[1]){
      newTempplateId = this.props.location.pathname.split('/template/')[1]
      this.currentViewerMode = "normal";
    }

    if (newTempplateId && newTempplateId !== this.currentTemplateId && this.currentViewerMode === 'normal' ) {
      this.currentTemplateId = newTempplateId;
      if( this.state.isUnityLoaded ){
        this.loadEnvironmentByFirestoreId(newTempplateId);
      } else {
        this.isEnvironmentWaitingToBeLoaded = true;
        this.environemntWaitedToBeLoaded = newTempplateId;
      }
    }

    if((this.currentViewerMode === "snapshot" && newSid !== this.currentSnapshotId && newSid) ){
      this.currentSnapshotId = newSid;
      if( this.state.isUnityLoaded ){
        console.log('NEW SID: ', newSid);
        this.loadSnapshotBySid(newSid);
      } else {
        this.isSnapshotWaitingToBeLoaded = true;
        this.isEnvironmentWaitingToBeLoaded = false;
        this.snapshotWaitingtoBeLoaded = newSid;
      }
    }
  }

  componentDidMount() {
    if(this.props.viewerMode === 'snapshot' && this.props.snapshotId !== ''){
      this.isSnapshotWaitingToBeLoaded = true;
      this.isEnvironmentWaitingToBeLoaded = false;
      this.snapshotWaitingtoBeLoaded = this.props.snapshotId;
    }else{ // this reloads a composition if the user left the viewer page went to a different one came back and wanted to load exactly the same composition
      if(this.props.environmentId){
        if( this.state.isUnityLoaded){
          this.loadEnvironmentByFirestoreId(this.props.environmentId);
        } else {
          this.isEnvironmentWaitingToBeLoaded = true;
          this.environemntWaitedToBeLoaded = this.props.environmentId;
        }
      }

    }
  }


  setOverflow = (overflow) => {
    this.setState({ overflow: overflow });
  }

  onPressHelp(){
    this.setState({isShowingEnvironmentPreviewOverlay: true, isShowingHelp: true, viewerMenuTopHidden: true});
  }

  hideHelp() {
    this.setState({isShowingHelp: false, isShowingEnvironmentPreviewOverlay: false, viewerMenuTopHidden: false});
  }

  // FIRED: High Quality Render or Draft render in Render Dialog | ACTION: get unity Snapshot Data
  handleSnapshotCreation = (isDraft, newQuality) => {
    window.analytics.track('New Snapshot', {
      aspect: this.state.aspectRatio,
      quality: this.state.quality,
      dof: this.state.dof,
      autofocusOn: this.state.autofocus,
      environment: this.state.currEnv,
      aspectRatio: this.state.aspect,
      credits: this.props.rendercost.quality[this.state.quality][this.state.aspectRatio],
      creditBalance: this.props.org ? this.props.org.credits : 0,
      oid: this.props.user.oid, // JUMP HERE
      isCurrentSnapshotDraft: isDraft,
  });
    //this.unityContent.send('Network', 'updateSnapshotToRender', `${consent},${urgency},${snapshotId},${email}`);

    this.setState(state => ({
      isShowingEnvironmentPreviewOverlay: false,
      isRenderDialogVisible: false,
      isShowingLoadingCircleOverlay: true,
      isLoadingCircleCompleted: false,
      loadingCircleText: 'Sending Data ...',
      // cameraWidth: width,
      // cameraHeight: height,
      // orientation: camOrientation,
      // unbalancedpx: unbalancedPx,
      blurBackground: false,
      isCurrentSnapshotDraft: isDraft,
      quality: newQuality,
    }));

    setTimeout(function() {
      this.unityContent.send('Network', 'connectToDBAndSendData') // gameobjectName, functionName, parameter
    }.bind(this), 200);
  };

  // FIRED: Photo Button pressed | ACTION: opens render dialog
  handleCameraPress = (width, height, camOrientation, unbalancedPx) => {
    window.analytics.track('Photo Button', {
      aspect: this.state.aspectRatio,
      quality: this.state.quality,
      dof: this.state.dof,
      autofocusOn: this.state.autofocus,
      environment: this.state.currEnv,
      aspectRatio: this.state.aspect,
      credits: this.props.rendercost.quality[this.state.quality][this.state.aspectRatio],
      creditBalance: this.props.org ? this.props.org.credits : 0
    });

    this.setState({
      isRenderDialogVisible: true,
      blurBackground: true,
      cameraWidth: width,
      cameraHeight: height,
      orientation: camOrientation,
      unbalancedpx: unbalancedPx
    });

  }

  closeRenderDialog = () => {
    this.setState({isRenderDialogVisible: false, blurBackground: false});
  };

  showPreview(isActive){
    this.setState({ isShowingEnvironmentPreviewOverlay: isActive});
  }

  changeGrid(){
    this.setState({grid: !this.state.grid});
  }

  handleTransforms(currentTransformOption){
    window.analytics.track('Switch Transform Tool', { tool: currentTransformOption});
    this.setState({currentTranfsormTool: currentTransformOption} );
    this.unityContent.send('TransformHandlesUI', 'OnToolChanged', currentTransformOption.toString()) // gameobjectName, functionName, parameter
    // GameObject: “TransformHandlesUI”
    // Funktion: “OnToolChanged(string)”
    // Parameter: String
    // erlaubte Parameter Werte: ‘Rotate’, ‘Move’, ‘None’, ‘Scale’, ‘View’

  }

  handleMouseEnterCamerButton(){
    this.unityContent.send('focusHandler', 'setDofEnabled', 'true');
    this.unityContent.send('focusHandler', 'setDofLevel', this.state.dof);
    this.setState({mouserHoveOverCameraButton: true});
  }

  handleMouseLeaveCameraButton(){
    this.unityContent.send('focusHandler', 'setDofEnabled', 'false');
    this.setState({mouserHoveOverCameraButton: false});
  }


  handleOptionSelect(selectedOption){
    switch (selectedOption) {
      case "Add Objects":
        window.analytics.track('Switch Panel', { panel: "Add Objects"});
        this.setState({activePanel: ADD_PANEL });
        break;
      case "Delete Selection":
        window.analytics.track('Delete Selection');
        if(this.state.activePanel === OBJECT_PANEL ){
          this.setState({activePanel: ENVIRONMENT_PANEL});
        }
        this.setState({isObjectSelected: false});
        this.unityContent.send('SceneLoader', 'deleteSelectedProduct') // gameobjectName, functionName, parameter
        break;
      case "Object":
        window.analytics.track('Switch Panel', { panel: "Object Properties"});
        this.setState({activePanel: OBJECT_PANEL});
        break;
      case "Environment":
        window.analytics.track('Switch Panel', { panel: "Environment Properties"});
        this.setState({activePanel: ENVIRONMENT_PANEL});
        break;
      case "Add Products":
        window.analytics.track('Switch Panel', { panel: "Add Products"});
        this.setState({activePanel: PRODUCTS_PANEL});
        break;
      case "photo":
        window.analytics.track('Switch Panel', { panel: "Photo Settings"});
        this.setState({activePanel: DEFAULT_PHOTO_PANEL});
        break;
      case "help":
        window.analytics.track('Switch Panel', { panel: "Help"});
        this.setState({activePanel: DEFAULT_HELP_PANEL});
        break;
    }
  }

  handleGeneralSettings(selectedOption, value){

    window.analytics.track('Environment Settings Change', { option: selectedOption, value: value });
    switch (selectedOption) {
      case "format":
        // TODO: ADD UNDO
        var [quality, aspect] = value.split(",");

        var y = this.props.rendercost.sizes[quality || 'draft'][aspect].height;
        var x = this.props.rendercost.sizes[quality || 'draft'][aspect].width;

        const prevState = `format,${this.state.quality}|${this.state.aspectRatio}|${this.state.aspect.x}|${this.state.aspect.y}`;
        const currState = `format,${quality}|${aspect}|${x}|${y}`;
        this.setState({
          aspectRatio: aspect,
          aspect: { x: x, y: y },
          quality: quality
        });

        this.unityContent.send('UndoManager', 'pushExternalEvent', `${prevState}␞${currState}`);
        break;
      case "gravity":
        this.setState({gravity: !this.state.gravity});
        //this.unityContent.send('CameraController', 'setPhysicsUsable', 'false');
        this.unityContent.send('CameraController', 'setPhysicsUsable', this.state.gravity.toString());
        break;
      case "gravity-option":
        this.setState({gravity: value});
        this.unityContent.send('CameraController', 'setPhysicsUsable', value.toString());
        break;
      case "autofocus":
        this.setState({autofocus: value});
        // CameraController → setFocusMode(mode) : Switches between manual and autofocus. Parameters: "ManualFocus" or "AutoFocus".
        this.unityContent.send('CameraController', 'setFocusMode', value ? 'AutoFocus' : 'ManualFocus');
        this.unityContent.send('UndoManager', 'pushExternalEvent', `setFocusMode,${!value ? 'AutoFocus' : 'ManualFocus'}␞setFocusMode,${value ? 'AutoFocus' : 'ManualFocus'}`);
        // TODO: call gravity function of unity
        break;
      case "aspectRatio":
        console.log('VALUE: ', value);
        console.log('VALUE: ', this.state.quality);
        console.log(this.props.rendercost.sizes);
        var y = this.props.rendercost.sizes[this.state.quality || 'draft'][value].height;
        var x = this.props.rendercost.sizes[this.state.quality || 'draft'][value].width;
        const prevValueAspectRatio = this.state.aspectRatio;
        const prevValueAspect = this.state.aspect;
        this.setState({
          aspectRatio: value,
          aspect: { x: x, y: y }
        });
        this.unityContent.send('UndoManager', 'pushExternalEvent', `aspectRatio,${prevValueAspectRatio}|${prevValueAspect.x}|${prevValueAspect.y}␞aspectRatio,${value}|${x}|${y}`);
        break;
      case "quality":

        const prevQuality = this.state.quality;
        const prevAspectQuality = this.state.aspectRatio;
        const prevAspectQualityRatio = this.state.aspect;
        var newQuality = value;
        var newAspectRatio = this.state.aspectRatio;
        var newAspect = this.state.aspect;
        if(this.props.rendercost.sizes[value || 'draft'][this.state.aspectRatio]){
          this.setState({quality: value});
        }else{
          this.setState({
            quality: value,
            aspectRatio: "square",
            aspect: {x: 1, y: 1}
          });
          newAspectRatio = "square";
          newAspect = {x:1 , y:1};
        }
        // TODO FIX
        this.unityContent.send('UndoManager', 'pushExternalEvent', `quality,${prevQuality}|${prevAspectQuality}|${prevAspectQualityRatio.x}|${prevAspectQualityRatio.y}␞quality,${value}|${newAspectRatio}|${newAspect.x}|${newAspect.y}`);

        break;
      case "dof":
        const prevValueDof = this.state.dof;
        this.setState({dof: value});
        this.unityContent.send('UndoManager', 'pushExternalEvent', `dof,${prevValueDof}␞dof,${value}`);
        break;
      case "FOV":
        const prevValueFOV = this.state.vertFOV;
        this.setState({
          vertFOV: value,
        });
        this.unityContent.send('CameraController', 'setVerticalFoV', value);
        this.unityContent.send('UndoManager', 'pushExternalEvent', `vertFOV,${prevValueFOV}␞vertFOV,${value}`);

        // CameraController → setVerticalFoV(verticalFovInDegrees) : sets the vertical field of view of the main camera in degrees.

    }
  }
  store = this.props.store;

  render() {
    return ReactDOM.createPortal(
      <RemoveScroll className={"react-portal " + (this.props.store.isViewerVisible ? 'show' : 'hidden')} enabled={this.props.store.isViewerVisible}>
        <div className="creator-overlay-container fullscreen" onMouseDown={(event) => {
          // event.preventDefault();
          if (event.target === event.currentTarget) {
            this.store.isViewerVisible = false;
            this.props.updateStore(this.store);
            // this.props.history.push("/");
            if(this.state.isUnityLoaded){
              this.unityContent.send('Network', 'focusCanvas', "0"); // keyboard input possible
            }
          }
        }}>
          {/*<img src={xImg} alt="" className="image-5" onMouseDown={(event) => {*/}
            {/*event.preventDefault();*/}
            {/*if (event.target === event.currentTarget) {*/}
              {/*this.store.isViewerVisible = false;*/}
              {/*this.props.updateStore(this.store);*/}
              {/*// this.props.history.push('/');*/}
              {/*if(this.state.isUnityLoaded){*/}
                {/*this.unityContent.send('Network', 'focusCanvas', "0");*/}
              {/*}*/}
            {/*}*/}
          {/*}}/>*/}
          <div className="overlay-container fullscreen">
            <div className={"left-tutorial-handler"}></div>
            <div className="overlay-container fullscreen">
              <TopBar
                unityContent={this.unityContent}
                onShowPreview={this.showPreview}
                updateStore ={this.props.updateStore}
                store={this.props.store}
                isUnityLoaded={this.state.isUnityLoaded}
                changeGrid={this.changeGrid}
                grid={this.state.grid}
                setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
                joyRideStepIndex={this.props.joyrideStepIndex}
                joyrideRunning={this.props.joyrideRunning}
              />
              <div className="viewer-container-with-menu">
                <div className="unity-viewer-container" ref={ (viewerSizeElement) => this.viewerSizeElement = viewerSizeElement}>
                  <div
                    className={( (this.state.blurBackground || this.state.isShowingEnvironmentPreviewOverlay)  ? "blur" : "")}
                    style={{ position: "absolute" , width: "100%", top: -this.state.overflow, height: "calc( 100% + " + this.state.overflow + "px )" }}
                    onClick={() => {this.unityContent.send('Network', 'focusCanvas', "1"); }}
                  >
                    <Unity unityContent={this.unityContent} />
                  </div>


                  <CSSTransition
                    in={!this.state.blurBackground}
                    timeout={300}
                    classNames="hideanimation"
                    unmountOnExit
                  >
                    <CameraOverlay x={this.state.aspect.x} y={this.state.aspect.y}
                                   onPress={this.handleCameraPress}
                                   mouseEnter={() => this.handleMouseEnterCamerButton()}
                                   mouseLeave={() => this.handleMouseLeaveCameraButton()}
                                   unityContent={this.unityContent}
                                   setOverflow={this.setOverflow}
                                   preview={this.store.currentEnvironmentPreview}
                                   isShowingPreview={this.state.isShowingEnvironmentPreviewOverlay}
                                   isShowingUI={!this.state.isShowingEnvironmentPreviewOverlay}
                                   grid={this.state.grid}
                                   aspect={this.state.aspect}
                                   aspectRatio={this.state.aspectRatio}ß
                                   settingsOptions={SETTINGS_OPTIONS}
                                   handleGeneralSettings={this.handleGeneralSettings}
                                   rendercost={this.props.rendercost}
                                   quality={this.state.quality}
                                   setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
                                   joyRideStepIndex={this.props.joyrideStepIndex}
                                   joyrideRunning={this.props.joyrideRunning}

                    />
                  </CSSTransition>
                  <CSSTransition
                    in={!this.state.blurBackground}
                    timeout={300}
                    classNames="hideanimation"
                    unmountOnExit
                  >
                    <ViewerMenuTop isHidden={this.state.viewerMenuTopHidden} loadSnapshotSlowly={this.loadSnapshotSlowly} onPressHelp={this.onPressHelp} unityContent={this.unityContent} viewerState={this.state} onPress={this.handleOptionSelect} onTransform={this.handleTransforms} onShowPreview={this.showPreview} position={'top right'} />
                  </CSSTransition>

                  { /*<WatermarkOverlay isVisible={this.state.quality == "draft" && this.state.mouserHoveOverCameraButton}/>*/ }

                  {this.state.isShowingLoadingCircleOverlay && (
                    <LoadingCircleOverlay show={this.state.isShowingLoadingCircleOverlay}
                                          isCompleted={this.state.isLoadingCircleCompleted} title={this.state.loadingCircleText}
                                          hideButtonText={"return"}
                                          // hideFunction={ this.hideLoadingCircleOverlay.bind(this) } />
                                          showReturnButton={this.state.showReturnButton}
                                          progress={this.state.snapshotDownloadProgress}
                                          hideFunction={ () => { this.setState( { isShowingLoadingCircleOverlay: false, isShowingEnvironmentPreviewOverlay: false } ) } } />
                  )}

                  { /* <CSSTransition
                    in={this.state.isShowingEnvironmentPreviewOverlay && (!this.state.isUnityLoaded || this.state.isShowingLoadingCircleOverlay)}
                    timeout={300}
                    classNames="hideanimation"
                    unmountOnExit
                  >
                    <EnvironmentPreviewOverlay backgroundImage={this.store.currentEnvironmentPreview} />

                  </CSSTransition> */ }

                  <CSSTransition
                    in={this.state.isShowingHelp}
                    timeout={300}
                    classNames="hideanimation"
                    unmountOnExit
                  >
                    <Help onPress={this.hideHelp}/>

                  </CSSTransition>

                  <CSSTransition
                    in={this.state.isRenderDialogVisible}
                    timeout={150}
                    classNames="hideanimation"
                    unmountOnExit
                  >
                    <RenderDialog close={this.closeRenderDialog}
                                  org={this.props.org}
                                  settingsOptions={SETTINGS_OPTIONS}
                                  store = {this.store}
                                  state={this.state}
                                  rendercost={this.props.rendercost}
                                  render={this.handleSnapshotCreation}
                                  setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
                                  joyRideStepIndex={this.props.joyrideStepIndex}
                                  joyrideRunning={this.props.joyrideRunning}
                    />
                  </CSSTransition>






                </div>


                <ViewerPanel
                  active={this.state.activePanel}
                  isObjectSelected={this.state.isObjectSelected}
                  onPress={this.handleOptionSelect}
                  state={this.state}
                  unityContent={this.unityContent}
                  settingsOptions={SETTINGS_OPTIONS}
                  handleGeneralSettings={this.handleGeneralSettings}
                  store = {this.store}
                  updateStore ={this.props.updateStore}
                  rendercost={this.props.rendercost}
                  org={this.props.org}
                  cms={this.props.cms}
                  restartJoyRideTour={this.props.restartJoyRideTour}
                  setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
                  joyRideStepIndex={this.props.joyrideStepIndex}
                  joyrideRunning={this.props.joyrideRunning}
                />
                </div>

              <CSSTransition
                in={this.state.isObjectSelected}
                timeout={300}
                classNames="hideanimation"
                unmountOnExit
              >
                <Properties
                  handleGeneralSettings={this.handleGeneralSettings}
                  unityContent={this.unityContent}
                  onPress={this.handleOptionSelect}
                  selectedObj={this.state.currSelection}
                  snapshot={this.state.snapshot}
                  reccomendedColorsGlobally={this.state.reccomendedColorsGlobally}
                  selectionId={this.state.selection}
                  key={this.state.selection}
                  setJoyRideStepByIndex={this.props.setJoyRideStepByIndex}
                  joyRideStepIndex={this.props.joyrideStepIndex}
                  joyrideRunning={this.props.joyrideRunning}
                  transformTool={this.state.currentTranfsormTool}
                  handleTransforms={this.handleTransforms}
                />
              </CSSTransition>

              <CSSTransition
                in={this.state.isEnvPropertyVisible && !this.state.isObjectSelected}
                timeout={300}
                classNames="hideanimation"
                unmountOnExit
              >
                <PropertiesEnvironment
                  //handleGeneralSettings={this.handleGeneralSettings}
                  unityContent={this.unityContent}
                  // onPress={this.handleOptionSelect}
                  modifiers={this.state.modifiers}
                  firebaseId={this.state.firebaseId}
                  environment={this.state.currEnv}
                  envPreview={this.state.currentEnvironmentPreview}
                  environmentModifersAtStart={this.state.environmentModifers}
                  reccomendedColorsGlobally={this.state.reccomendedColorsGlobally}
                />
              </CSSTransition>

            </div>

          </div>
        </div>

      </RemoveScroll>,
      document.getElementById('portal')
    )
  }
});



const aspectRatioOptions = [
  {value:"square",        x:1,    y:1,  text:"Square (1:1)"},
  {value:"landscape",     x:4,    y:3,  text:"Landscape (4:3)"},
  {value:"portrait",      x:3,    y:4,  text:"Portrait (3:4)"},
  {value:"ig_story" ,     x:9,    y:16, text:"Instagram Story (9:16)"},
  {value:"ig_portrait",   x:4,    y:5,  text:"Instagram Portrait (4:5)"},
  {value:"ig_regular" ,   x:1,    y:1,  text:"Instagram Post (1:1)"},
  {value:"ig_landscape",  x:1.91, y:1,  text:"Instagram Landscape (1.91:1)"},
  {value:"fb_cover",      x:164,  y:75, text:"Facebook Cover (164:75)"},
  {value:"fb_post" ,      x:2,    y:3,  text:"Facebook Post (2:3)"},
];

const fovOptions = [
  {value:"10", text:"10"},
  {value:"25", text:"25"},
  {value:"27", text:"27"},
  {value:"50", text:"50"},
  {value:"75", text:"75"},
  {value:"90", text:"90"},
];

const dofOptions = [
  {value:"none", text:"none"},
  {value:"weak", text:"weak"},
  {value:"strong", text:"strong"},
  {value:"ludicrous", text:"ludicrous"},
];

const imageQualityOptions = [
  {value:"draft", text:"draft  (~4 min)", min: 600},
  {value:"web", text:"web  (~4 min)", min: 600},
  {value:"social_media", text:"social media  (~10 min)", min: 1080},
  {value:"print", text:"print  (~20 min)", min: 2500}
]

const SETTINGS_OPTIONS = {
  aspectRatioOptions: aspectRatioOptions,
  dofOptions: dofOptions,
  imageQualityOptions: imageQualityOptions,
  fovOptions: fovOptions
}



export default withRouter(withFirestore(withRouter(Viewer)));
