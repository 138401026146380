import React from 'react';
import ToolTip from 'react-portal-tooltip';


const style = {
  style: {
    background: '#2b2b2b',
    padding: 10,
    borderColor: "#333333"
    // boxShadow: '5px 5px 3px rgba(0,0,0,.5)'
  },
  arrowStyle: {
    color: '#2b2b2b',
    borderColor: "#333333"
  }
}


class TextOnlyTooltip extends React.Component {

  render() {
    return (
      <ToolTip className={"textOnlyTooltip"}
               active={this.props.isTooltipActive}
               position={this.props.position || 'top'}
               arrow={this.props.arrowPosition || "center"}
               parent={this.props.tooltipRef} // this must start with #
               style={style}
      >
        <div>
          <p style={{fontSize: "0.75em"}}>{this.props.text}</p>
        </div>
      </ToolTip>
    )
  }
}

export default TextOnlyTooltip;
