import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import CorncerIcon from './../../../images/corners_rect.svg';
import { Route, Link } from "react-router-dom";



const Help = observer(class Help extends Component {





  render() {
    return (
      <div className="help-viewer-container">
        <div className="help-navigation-container">
          <div className="help-menu-item active">
            <div className="text-block-37">Navigation Basics</div>
          </div>
          <Link to="/docs" target="_blank" className="help-menu-item" onClick={() => {window.analytics.track('Help Panel', {type:"more help", action:"click", location: "tab"});}}>
            <div className="text-block-37">All Controls</div>
          </Link>
          <img src="images/noun_X_1890803.svg" alt="" className="image-5-copy" onClick={ () => {this.props.onPress()} }/></div>
        <div className="controls-help-container" onClick={ () => {this.props.onPress()} }>
          <div className="div-block-32"><img src="images/noun_Zoom-In_744781.svg" alt="" className="image-14" />
            <div className="text-block-38">Zoom</div>
            <div className="text-block-39">Mouse Wheel or<br />Touchpad Zoom</div>
          </div>
          <div className="div-block-32"><img src="images/noun_reload_425449.svg" alt="" className="image-14" />
            <div className="text-block-38">Orbit Around</div>
            <div className="text-block-39">Right click + drag</div>
          </div>
          <div className="div-block-32"><img src="images/noun_Move_1076865.svg" alt="" className="image-14" />
            <div className="text-block-38">Pan Move</div>
            <div className="text-block-39">Right click + ALT + drag<br /></div>
          </div>
        </div>
        <div className="footer-more">
          <Link to="/docs" target="_blank" className={"whiteLink"} onClick={() => {window.analytics.track('Help Panel', {type:"more help", action:"click", location: "footer"});}}>click here for more help</Link>
        </div>
      </div>
    );
  }
});


export default Help;


