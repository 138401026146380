/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import ColorPicker from './color-picker';
import { parseColor } from './utils';




const InputColor = ({ initialHexColor, onChange, recommendColors, placement, title, selectionId, ...props }) => {
  const [color, setColor] = useState(parseColor(initialHexColor));
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);

  useEffect(() => {
    changeColor(parseColor(initialHexColor));
  }, [initialHexColor]);

  function changeColor(color) {
    if (onChange) {
      setColor(color);
      onChange(color);
    }
  }




  return (
    <div className="single-object-modifier-container left " onClick={() =>{setIsColorPickerVisible(!isColorPickerVisible)}} >
      <div className={"color-option-container left"}>
        { isColorPickerVisible && (<div className={"completeOverlay"} onClick={() => {setIsColorPickerVisible(false)}}>click to close</div>) }
        <div className={"zl-tooltip-hit-color"}>
          <div className={"single-color-option red left "}
               style={{ backgroundColor: color.hex }}
          >
            <div className={"tag-color"}></div>
          </div>


          { isColorPickerVisible &&
          (<div className={"zl-tooltip"}>
            <ColorPicker color={color} onChange={changeColor} onClose={() => {setIsColorPickerVisible(false)}} recommendedColors={recommendColors || []} />
          </div>)
          }

        </div>
      </div>
      <div className="text-block-48">{title}</div>
    </div>

  );
};

InputColor.defaultProps = {
  placement: 'bottom'
};

export default InputColor;
