import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import darkXSymbol from '../../images/noun_X_1890803_1.svg';


const ViewerSettingsHeader = observer(class ViewerMenu extends Component {
  render() {
    return (
      <div className={(this.props.isSubmenu ? "viewer-submenu-header-container" : "viewer-menu-header-container")}>
        <img src={this.props.symbol} alt="" className="viewer-heading-img" />
        <div className="text-block-22">{this.props.title}</div>
        { this.props.withExitSymbol && (
        <div className="div-block-3">
            <img src={darkXSymbol} alt="" className="image-6" />
        </div>
          )
        }
      </div>
    )

  }
});


export default ViewerSettingsHeader;
