import React, { Component, location, history } from 'react';
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import axios from 'axios';
import { Loader, Segment } from 'semantic-ui-react'


import { observer } from "mobx-react";


// import { observer } from 'mobx-react';

const NewOrganization = observer(
  class NewOrganization extends Component {

    state={
      input: "",
      loading: false
    };

    async newOrganization(orgName){
      var currUser = this.props.firebaseUser;
      const response = await axios.post(
        '/api/newOrganization/' + currUser.uid,
        // 'http://localhost:5000/api/newOrganization/' + currUser.uid,
        { orgName: orgName },
        { headers: { 'Content-Type': 'application/json' } }
      );
      const oid = response.oid;
      currUser.claims.oid = oid;
      currUser.claims.state = "orgCreated";
      this.setState({loading: false});
      this.props.handleUserUpdate(currUser);
      this.handleSignOut();
      return <Redirect to='/my-photos' />
    }

    handleSignOut(){
      this.props.handleUserUpdate(undefined);
      this.props.firebase.app().auth().signOut();
      window.analytics.track('Sign Out');
    };

    handleChange(e) {
      this.setState({ input: e.target.value });
    }

    componentDidUpdate(prevProps, prevState, snapshot){
    }

    handleSubmit(){
      const currInput = this.state.input;
      if(currInput !== "" && currInput){
        this.setState({ loading: true });
        this.newOrganization(currInput);
      }
    }

    // interaction
    render() {

      if(this.props.firebaseUser){
        if (this.props.firebaseUser.claims.state !== "createOrganization" && this.props.firebaseUser.claims.state){
          return (<Redirect to='/my-photos'  />)
        }
      }

      return (
        <div className="full-height">
          <div className="login-panel-right">
            <div className="login-panel-right-header-copy"><Link to="/" className="logo-login-container w-inline-block"><img
              src="images/zerolens-logo.svg" alt="" /></Link>
              <Link to="/login" className="link-block w-inline-block">
                <div className="text-block-6">Log In</div>
              </Link>
            </div>
            <div className="div-block-17">
              <div className="div-block-18">
                <h1 className="heading-3">One Last Step!</h1>
                <div className="text-block-7 less-margin"><strong>ORGINZATION NAME</strong> (you can change this later)
                </div>
                <div className="w-form">
                  <div id="email-form" name="email-form" data-name="Email Form">
                    <div className="div-block-14"><input type="text" className="login-text-field firstname w-input"
                                                         maxLength="256" name="firstname" data-name="Firstname"
                                                         placeholder="e.g. Some Company LLC" id="firstname" onChange={ (e) => {this.handleChange(e)} } /></div>
                    <div className="regular-button full-width bold w-button" onClick={() => {this.handleSubmit()}} >{this.state.loading ? ( <Segment className="transparentBg" ><Loader active /></Segment> ) : ( <span>Next</span> )}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);





export default (NewOrganization);
