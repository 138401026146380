import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';




const ViewerMini = observer(class ViewerMini extends Component {
  constructor(props) {
    super(props);

  }


  render() {
    return (
      <div className={"viewerclosed " + (this.props.isVisible ? " " : "hidden")} onClick={() => {this.props.store.isViewerVisible = true;}}><img src="./images/noun_enlarge_806826-1.svg" alt="" className="image-15" />
        <div className="text-block-41">Return To Viewer</div>
      </div>
    );
  }
});


export default ViewerMini;
