import React, { Component, location, history } from 'react';
import { observer } from "mobx-react";
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import Iframe from 'react-iframe';





// import { observer } from 'mobx-react';
import SideBar from '../../SideBar/SideBar';
import HeaderBackend from '../HeaderBackend/HeaderBackend';
import ProductElements from '../ProductElements/ProductElements';
import ProductDetail from './ProductDetail';


const ProductsPage = observer(
  class ProductsPage extends Component {

    state = {
      activeProduct: {}
    }

    constructor(props) {
      super(props);
      this.setActiveProduct = this.setActiveProduct.bind(this);
      this.updateColorRecOfActiveProduct = this.updateColorRecOfActiveProduct.bind(this);
    }

    setActiveProduct(product){
      this.setState({activeProduct: product});
    }

    updateColorRecOfActiveProduct(colorRec){
      var activeProduct = this.state.activeProduct;
      activeProduct.productColorRecommondation = colorRec;
      this.setState({ activeProduct: activeProduct });
    }
    // interaction
    render() {

      if (!this.props.firebaseUser){
        return (<Redirect to='/login'  />)
      }
      return (
        <div className="backend-container">
          <SideBar active={"products"} />
          <div className="product-page-backend-container">
            <div className="backend-body-container" ref={this.targetRef}>
              <HeaderBackend title={"Products"}
                             user={this.props.firebaseUser}
                             firebase={this.props.firebase}
                             handleUserUpdate={this.props.handleUserUpdate}
                             org={this.props.org}
                             buttonText={"New Product   +"}
                             buttonType={"link"}
                             buttonLink={"http://zerolens.com/docs/plattform/products-contact"}
              />
              { this.props.isSignedIn ?
                (
                  <>
                    <p className={"hint"}>You can add color recommendations to products. Once you use the color change in any template (e.g. for changing the background color) you will get every reccommended color of all the products in the template as a suggestion. </p>
                    <ProductElements user={this.props.firebaseUser} firebase={this.props.firebase} activeProduct={this.state.activeProduct} setActiveProduct={this.setActiveProduct}  />
                    {/*<Iframe url="https://zerolens.com/docs/plattform/products-contact"*/}
                            {/*width="100%"*/}
                            {/*height="3500"*/}
                            {/*id="myId"*/}
                            {/*className="docsContainer"*/}
                            {/*display="initial"*/}
                            {/*position="relative"/>*/}
                  </>

                ) : (
                  <div className="div-block-20">
                    <div className="text-block-30">Ups. You are not signed in!</div>
                    <Link to="/login" className="regular-button greyed">
                      <div>Sign In</div>
                    </Link>
                  </div>
                )}
              </div>
              <ProductDetail activeProduct={this.state.activeProduct} updateColorRecOfActiveProduct={this.updateColorRecOfActiveProduct} />
          </div>
      </div>
      );
    }
  }
);





export default (ProductsPage);
