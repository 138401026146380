import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import InfiniteScroll from 'react-infinite-scroller';
import { Route, Link } from "react-router-dom";


// import { observer } from 'mobx-react';

const NoPhotosYet = observer(
  class NoPhotosYet extends Component {

    // interaction
    render() {
      return (
        <div>
          <h1 className="information-empty">Looks like your haven&#x27;t taken any photos yet. <strong>Go to &quot;Take
            Photo&quot; to start.</strong></h1>
          <div className="div-block-11">
            <div className="backend-photo-card empty">
              <div className="div-block-12">
                <div className="backend-photo-preview-empty"></div>
              </div>
              <div className="backend-photo-information-conatiner empty">
                <div className="backend-photo-information-img empty"></div>
                <div className="div-block-10">
                  <div className="backend-photo-information-header empty">Photo Dummy</div>
                  <div className="backend-photo-information-subtext empty">information</div>
                </div>
              </div>
            </div>
            <div className="backend-photo-card empty">
              <div className="div-block-12">
                <div className="backend-photo-preview-empty"></div>
              </div>
              <div className="backend-photo-information-conatiner empty">
                <div className="backend-photo-information-img empty"></div>
                <div className="div-block-10">
                  <div className="backend-photo-information-header empty">Photo Dummy</div>
                  <div className="backend-photo-information-subtext empty">information</div>
                </div>
              </div>
            </div>
            <div className="backend-photo-card empty">
              <div className="div-block-12">
                <div className="backend-photo-preview-empty"></div>
              </div>
              <div className="backend-photo-information-conatiner empty">
                <div className="backend-photo-information-img empty"></div>
                <div className="div-block-10">
                  <div className="backend-photo-information-header empty">Photo Dummy</div>
                  <div className="backend-photo-information-subtext empty">information</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      );
    }
  }
);





export default withFirestore(NoPhotosYet);

