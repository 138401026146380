import React, { Component, location, history } from 'react';
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import axios from 'axios';
import { Loader, Segment } from 'semantic-ui-react'


import { observer } from "mobx-react";

import ErrorMessageSignUp from './ErrorMessageSignUp';

// import { observer } from 'mobx-react';

const PasswordReset = observer(
  class PasswordReset extends Component {

    state={
      input: "",
      loading: false,
      errorMessage: "",
      isError: false,
    };

    // props.firebase.auth().sendPasswordResetEmail

    handleChange(e) {
      this.setState({ input: e.target.value });
    }

    async handlePasswordReset(){
      if(this.props.firebase){
        this.setState({loading: true});
        this.props.firebase.auth().sendPasswordResetEmail(this.state.input)
          .then(response => {
            console.log('response: ', response);
            this.setState({loading: false, isError: true, errorMessage: "Check your E-Mail for a Password Reset Link", isSuccess: true});
          })
          .catch(error => {
            console.log('error: ', error);
            this.setState({loading: false, isError: true, errorMessage: error.message, isSuccess: false});
        });

      }

    }

    componentDidUpdate(prevProps, prevState, snapshot){
    }

    handleSubmit(){
      this.handlePasswordReset();
    }

    // interaction
    render() {

      return (
        <div className="full-height">
          <div className="login-panel-right">
            <div className="login-panel-right-header-copy"><Link to="/" className="logo-login-container w-inline-block"><img
              src="images/zerolens-logo.svg" alt="" /></Link>
              <Link to="/login" className="link-block w-inline-block">
                <div className="text-block-6">Log In</div>
              </Link>
            </div>
            <div className="div-block-17">
              <div className="div-block-18">
                <h1 className="heading-3">Reset Your Password</h1>
                <div className="text-block-7 less-margin"><strong>type in your E-Mail</strong>
                </div>
                <div className="w-form">
                  <div id="email-form" name="email-form" data-name="Email Form">
                    <div className="div-block-14"><input type="text" className="login-text-field firstname w-input"
                                                         maxLength="256" name="firstname" data-name="Firstname"
                                                         placeholder="Your E-Mail" id="firstname" onChange={ (e) => {this.handleChange(e)} } /></div>
                    <div className="regular-button full-width bold w-button" onClick={() => {this.handleSubmit()}} >
                      {this.state.loading ? ( <Segment className="transparentBg" ><Loader active /></Segment> ) : ( <span>Reset Password</span> )}</div>
                  </div>
                </div>
                <ErrorMessageSignUp isError={this.state.isError} errorMessage={this.state.errorMessage} />
              </div>
            </div>




          </div>
        </div>
      );
    }
  }
);





export default PasswordReset;
