import React, { Component, location, history } from 'react';
import InfoTooltip from '../../helpers/Tooltip/InfoTooltip';

const ViewerSettingsToggleSetting = class ViewerSettingsToggleSetting extends Component {

  render() {
    return (
      <div id={this.props.id} className="object-controls-container" onClick={() => {this.props.onPress()}} {...this.props}>
        <div className="settings-text">{this.props.title}</div>
        <div className={"max-space"}></div>
        <div className={"toggle-container " + (this.props.isActive ? 'off' : 'off') }>
          <div className={"gravity "  + (this.props.isActive ? '' : 'off')} ></div>
        </div>
      </div>
    );
  }
};


export default ViewerSettingsToggleSetting;
