const TUTORIAL_TEMPLATE_ID = "6mZLj2ISuJwKUiY5Vyvd";
const TUTORIAL_PREVIEW_IMAGE = "";
const TUTORIAL_ENVIRONMENT_NAME = "Tutorial";


exports.openTutorialTemplate = (store, updateStore, history) => {
    history.push('../template/' + TUTORIAL_TEMPLATE_ID);

    var currStore = store;
    currStore.isViewerVisible = true;
    currStore.currentEnvId = TUTORIAL_TEMPLATE_ID;
    currStore.currentEnvironmentPreview = TUTORIAL_PREVIEW_IMAGE;
    currStore.currEnvironmentName = TUTORIAL_ENVIRONMENT_NAME;

    updateStore(currStore);
}

exports.joyRideInteractionsStart = (unityContent, currIndex, setJoyRideStepByIndex) => {
  setJoyRideStepByIndex(currIndex + 1);
  console.log('blocking movement');
  //unityContent.send("CameraController", "setMovementAllowed", "false");
  // unityContent.send("TutorialUtil", "createTutorialGhost", "0,0,0,0,0,0,1,1,1,#ff00ff");
}

exports.joyRideEventHandler = (unityContent, event) => {

}

exports.joyRideStyle = {
  options: {
    primaryColor: '#1592e6',
    fontSize: '12px'
  },
  buttonNext: {
    padding: 15,
    fontWeight: 'bold'
  }
};

exports.joyRideTemplates = [
  {
    name: "Simple Studio Shot",
    objectID: "12cvUJwAAnineHZkx4Bj",
    preview: "https://storage.googleapis.com/pixelstore-4ec98.appspot.com/Compositions/mp8NU32SgrheY9hWyL8f.jpg?GoogleAccessId=firebase-adminsdk-m26eq%40pixelstore-4ec98.iam.gserviceaccount.com&Expires=2445522027&Signature=WVba7zRFeV9tUhQh02YnJS5W6dHX5yape2Thls6eIzmWS8d%2FIwM1YWBRFyjSs8jeyZWphLUkeqeXpkHIhFa%2BN6DuiYwkuFvYZ66Jrhw0aO1FSILPiDRQWidDkji7PMj6FL1%2FP8zird2j36k9Us2zOBRYk17OCKIc5J%2BAQv8B%2B8reow8xACgOOOQrRLRIy%2FhF6x60b2CJLJrMC5JlyhVsu3OJUvTeiOpjahVsE2Z%2FzfERfRFXJSm2n%2B1kdGptY1bqqhXLQQet%2B8uakLsxBWZ1Cqa9l1OYObO6A9jib%2Bw8Jt5VCMSnUmrMIAafLXIMatoiOkBqMpgbBqcX8ccsi92vaw%3D%3D",
    tags: "studio,indoor,simple"
  },
  {
    name: "Flatlay Wood",
    objectID: "6mZLj2ISuJwKUiY5Vyvd",
    preview: "https://storage.googleapis.com/pixelstore-4ec98.appspot.com/Compositions%2FTutorial_CompositionThu_Mar_19_2020_11%3A56%3A01_GMT%2B0100_(Central_European_Standard_Time)",
    tags: "flatlay, tutorial, wood"
  },
  {
    name: "Simple Studio Shot 2",
    objectID: "amyIhR0wZcdkzzyd5omn#",
    preview: "https://storage.googleapis.com/pixelstore-4ec98.appspot.com/Compositions/k7RVEO256uWAdNWSNzs0.jpg?GoogleAccessId=firebase-adminsdk-m26eq%40pixelstore-4ec98.iam.gserviceaccount.com&Expires=2446541704&Signature=LZj2rgAKuClYBSjp1ulQoHAMWZTHXJrak0qd1pNrEiOGmEv0yVPZVg0XFMcC28rvoFuWPbi6mFz2Jiu5KypzCfx6n22SVAzT%2Bu2TEWSPYnSXMBl3z0vIOOzNYhsVjOcpORHaWhaEd3f1NPMAZBE2Co6aMwUulH1U7I6PrESPKrOPNHVa6iTsBcJsi6ooGJU1gLd76tM3NVHkYhJH3Ozogvx1YsU3pOANoKq3gumXgKVjmKrMzhLXQPE4dtVyg6hy8jgj88K53VpWRT3kNc7SUfCUufuUG%2F%2BCcLata1OEOJo2Kmlksnt%2FZBi25jmneWf1N8gJccRdXYBimFncyj5EvA%3D%3D",
    tags: "studio,indoor,simple"
  }
];

exports.joyRideStepSettings = {
  templateClickId: 3,
  templateIdPrefix: 'tut-id-',
  templateOpenId: 4,
  templateRotateId: 5,
  templateMoveId: 6,
  templateMoveObjectId: 7,
  templateResetCameraId: 8,
  templateAddObjectId: 9,
  templateRotateObjectId: 10,
  templateDeleteObjectId: 11,
  templateDeleteProductId: 12,
  templateAddProductId: 13,
  templateFlatlayProductId: 14,
  templatePlaceOnPlaneId: 15,
  templateZoomInId: 16,
  templateTakePhotoId: 17,
  templateFreeOptionId: 18,
  templateMyPhotosOptionId: 19,
  templateMyProductsOptionId: 22,
}
