import React, { Component, location, history } from 'react';
import {
  SearchBox,
} from 'react-instantsearch-dom';
import './Hero.css';

const DARK_MODE = false;



class Hero extends Component {
  render() {


    return(
      <div className={"home-section " + (this.props.isVisible ? '' : 'hidden')}>
        <div className="w-container">
          <div className="w-form">
            <div id="email-form-2" name="email-form-2" data-name="Email Form 2" className="form-3">
              <div className="div-block-2">
                <h1 className="heading">zerolens</h1>
                <p className="paragraph margin-bottom-20 ">find and adapt a photo for your product</p>
              </div>
              <SearchBox
                className={"text-field w-input"  + (DARK_MODE ? " dark-mode " : "")}
                autoFocus={true}
                searchAsYouType={false}
                translations={{
                  placeholder: 'Search product templates',
                }}
              />
                <div className="text-block-21">search for terms like studio, fruits, summer or just click on any category above to start</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Hero;
