import React, { Component, location, history } from 'react';
import { observer } from "mobx-react";
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import { withFirestore, FirestoreCollection } from 'react-firestore';

import { withFirebase } from 'firebase';


// import { observer } from 'mobx-react';


const TextToInputField = observer(
  class TextToInputField extends Component {
    state = {
      editModeOn: false,
      editText: this.props.text
    }

    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
    }

    handleSwitchToEditMode(){
      this.setState({ editModeOn: true });
    }

    handleOnBlur(){
      this.setState({ editModeOn: false });

      if(this.state.editText !== this.props.text){
        if(this.props.changeHandlerProps){
          this.props.changeHandler(this.state.editText, this.props.changeHandlerProps);
        }else{
          this.props.changeHandler(this.state.editText);
        }
      }
    }

    handleChange(event) {
      this.setState({editText: event.target.value});
    }

    _handleKeyDown = (e) => {
      if (e.key === 'Enter') {
        this.handleOnBlur()
      }
    }


    // interaction
    render() {

      return (
        <>
          {this.state.editModeOn ? (
            <input className={"w-input text-to-input-input-field " + this.props.class } type="text" value={this.state.editText} onBlur={() => {this.handleOnBlur()}} onChange={this.handleChange} onKeyDown={this._handleKeyDown} autoFocus/>
            )

          : (
            <span className={this.props.class} onClick={() => {this.handleSwitchToEditMode()}}>{this.props.text}</span>
          )}

        </>
      );
    }
  }
);





export default withFirestore(TextToInputField);
