import React, { Component, location, history } from 'react';
import { Dimmer, Loader, Segment, Button } from 'semantic-ui-react';
import CircleLoaderCheckmark from '../CircleLoaderCheckmark';
import '../LoadingCircleOverlay.css';
import './EnvironmentPreviewOverlay.css';

const EnvironmentPreviewOverlay = class EnvironmentPreviewOverlay extends Component {


  render() {
    let styleDiv = {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      verticalAlign: 'middle',
      justifyContent: 'center',
      backgroundImage: `url('${this.props.backgroundImage}')`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
    };


    return (
      <div className={'EnvironmentPreviewOverlay ' + (this.props.show ? '' : 'hide')} style={ styleDiv }>
      </div>
    );
  }
};


export default EnvironmentPreviewOverlay;
