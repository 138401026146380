import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { Route, Link } from "react-router-dom";
import SVG from 'react-inlinesvg';


import zerolensLogo from '../../images/zerolens-logo.svg';
import productIcon from '../../images/product.svg';
import helpIcon from '../../images/noun_help_2302551_dark.svg';
import accountIcon from '../../images/noun_account_1581254.svg';
import { joyRideStepSettings } from '../helpers/joyrideHelpers/joyrideHelpers';


const photosIcon =  '../../images/image.svg';

// import { observer } from 'mobx-react';
const SideBar = observer(
  class SideBar extends Component {

    handleProductItem(){
      window.analytics.track('Sidebar', {type:"products", action:"click"});
      if(this.props.joyrideRunning){
        if(this.props.joyRideStepIndex === joyRideStepSettings.templateMyProductsOptionId ){
          this.props.setJoyRideStepByIndex(joyRideStepSettings.templateMyProductsOptionId + 1);
        }
      }
    }
    // interaction
    render() {
      return (
        <div className="backend-menu-container">
          <Link to="/" className="link-block-3 w-inline-block" onClick={() => {window.analytics.track('Sidebar', {type:"logo", action:"click"}); if(this.props.closeCallback){ this.props.closeCallback()};}}>
            <img src={zerolensLogo} alt="" className="logo-img" /></Link>
            <Link to="/" className={"side-bar-link w-inline-block "  + (this.props.active === "feed" ? 'active' : '')} onClick={() => {window.analytics.track('Sidebar', {type:"take photo", action:"click"}); if(this.props.closeCallback){ this.props.closeCallback()};}}>
              <div className={"active-symbol " + (this.props.active === "feed" ? 'active' : '')}></div>
              <SVG className="sidebar-img" src= './svgLoader/camera_lines.svg' />
              <div className={"side-bar"  + (false === "photos" ? 'active' : '')}>Take Photo</div>
            </Link>

          <Link to="/my-photos" className={"side-bar-link w-inline-block " + (this.props.active === "photos" ? 'active' : '')} onClick={() => {window.analytics.track('Sidebar', {type:"photos", action:"click"});}}>
            <div className={"active-symbol " + (this.props.active === "photos" ? 'active' : '')}></div>
            <SVG className="sidebar-img" src= './svgLoader/single_photo.svg' />
            <div className={"side-bar "  + (this.props.active === "photos" ? 'active' : '')}>Photos</div>
          </Link>
          <Link to="/org-photos" className={"side-bar-link w-inline-block " + (this.props.active === "org-photos" ? 'active' : '')} onClick={() => {window.analytics.track('Sidebar', {type:"org-photos", action:"click"});}}>
            <div className={"active-symbol " + (this.props.active === "org-photos" ? 'active' : '')}></div>
            <SVG className="sidebar-img" src= './svgLoader/photos.svg' />
            <div className={"side-bar "  + (this.props.active === "org-photos" ? 'active' : '')}>Organization Photos</div>
          </Link>
          <Link to="/products" className={"side-bar-link w-inline-block sidebar-item-products "  + (this.props.active === "products" ? 'active' : '')} onClick={() => {this.handleProductItem()}}>
            <div className={"active-symbol " + (this.props.active === "products" ? 'active' : '')}></div>
            <SVG className="sidebar-img" src= './svgLoader/product.svg' />
            <div className={"side-bar "  + (this.props.active === "products" ? 'active' : '')}>Products</div>
          </Link>
          <Link to="/settings" className={"side-bar-link w-inline-block " + (this.props.active === "settings" ? 'active' : '')} onClick={() => {window.analytics.track('Sidebar', {type:"help", action:"account"});}}>
            <div className={"active-symbol " + (this.props.active === "settings" ? 'active' : '')}></div>
            <SVG className="sidebar-img" src= './svgLoader/account.svg' />
            <div className={"side-bar "  + (this.props.active === "settings" ? 'active' : '')}>Account</div>
          </Link>
          <a href="https://zerolens.webflow.io/university-post/first-steps" target="_blank" className={"side-bar-link w-inline-block " + (this.props.active === "docs" ? 'active' : '')} onClick={() => {window.analytics.track('Sidebar', {type:"help", action:"click"});}} >
            <div className={"active-symbol " + (this.props.active === "docs" ? 'active' : '')}></div>
            <SVG className="sidebar-img" src= './svgLoader/help.svg' />
            <div className={"side-bar "  + (this.props.active === "docs" ? 'active' : '')}>Help</div>
          </a>

          { window.location.origin !== "https://studio.zerolens.com" && (
            <Link to="/dev" className={"side-bar-link w-inline-block " + (this.props.active === "dev" ? 'active' : '')} >
              <div className={"active-symbol " + (this.props.active === "dev" ? 'active' : '')}></div>
              <SVG className="sidebar-img" src= './svgLoader/product.svg' />
              <div className={"side-bar "  + (this.props.active === "dev" ? 'active' : '')}>Development</div>
            </Link>
          )}
        </div>
      );
    }
  }
);
export default (SideBar);
