import React, { Component, location, history, useState } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore } from 'react-firestore';
import {ColorPicker} from 'primereact/colorpicker';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import { Placeholder } from 'semantic-ui-react';

import InputColor from '../../helpers/ColorPicker/input-color';
import InputMaterial from '../../helpers/MaterialPicker/input-material';


const PropertiesEnvironment = observer(class PropertiesEnvironment extends Component {


  state = {
    background: '#fff',
    panel: "object",
    isLoading: true,
    isLoadingEnv: true,
    objName: "",
    modifiers: [],
    preview: "",
    transformTool: "Rotate",
    standardMods: undefined,
    initial: '#5e72e4',
    color: ''
  };


  standardMods = undefined;

  downloadedEnvironments = {};

  handleChangeMaterialEnvComplete(material, i) {
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].options.changeableMaterialID = material.assetbundle;
    this.setState({ modifiersEnv: newModifiers, nextMaterialId: material.id });

    if (this.state.modifiersEnv[i].type === "materialEdit") {
      // todo create for all modifiers
      var currModifiers = [
        {
          "type": this.state.modifiersEnv[i].type,
          "options": [
            "materialName=" + this.state.modifiersEnv[i].options.materialName,
            "isColorChangeable=" + (this.state.modifiersEnv[i].options.isColorChangeable === "true" ? "true" : "false"),
            "defaultColor=" + this.state.modifiersEnv[i].options.defaultColor || "#ffffff",
            "isMaterialChangeable=" + (this.state.modifiersEnv[i].options.isMaterialChangeable === "true" ? "true" : "false"),
            "changeableMaterialID=" + material.assetbundle || "",
            "materialFirebaseId=" + material.id,
            "isLight=" + (this.state.modifiersEnv[i].options.isLight === "true" ? "true" : "false"),
            "unityLightGameObjectName=" + ((this.state.modifiersEnv[i].options.unityLightGameObjectName && this.state.modifiersEnv[i].options.unityLightGameObjectName != "false") ? this.state.modifiersEnv[i].options.unityLightGameObjectName : "false"),

          ],

        }
      ];
      console.log("MAT CHANGE: ", JSON.stringify(currModifiers));

      window.analytics.track('Environment Settings Change', {
        option: "Environment Material Change",
        unityMaterial: this.state.modifiersEnv[i].options.materialName,
        value: material.title
      });
      this.unityContent.send('ModifierManager', 'applyModifierToEnvironment', JSON.stringify(currModifiers));
    }
  }

  handleChangeEnvComplete(color, i) {
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].options.defaultColor = color.hex;
    this.setState({ modifiersEnv: newModifiers });

    if (this.state.modifiersEnv[i].type === "materialEdit") {
      // todo create for all modifiers
      var currModifiers = [
        {
          "type": this.state.modifiersEnv[i].type,
          "options": [
            "materialName=" + this.state.modifiersEnv[i].options.materialName,
            "isColorChangeable=" + (this.state.modifiersEnv[i].options.isColorChangeable === "true" ? "true" : "false"),
            "defaultColor=" + (color.hex || ""),
            "isMaterialChangeable=" + (this.state.modifiersEnv[i].options.isMaterialChangeable === "true" ? "true" : "false"),
            "changeableMaterialID=" + (this.state.modifiersEnv[i].options.changeableMaterialID ? this.state.modifiersEnv[i].options.changeableMaterialID : ""),
            "materialFirebaseId=" + (this.state.modifiersEnv[i].options.materialFirebaseId ? this.state.modifiersEnv[i].options.materialFirebaseId : ""),
            "isLight=" + (this.state.modifiersEnv[i].options.isLight === "true" ? "true" : "false"),
            "unityLightGameObjectName=" + ((this.state.modifiersEnv[i].options.unityLightGameObjectName && this.state.modifiersEnv[i].options.unityLightGameObjectName != "false") ? this.state.modifiersEnv[i].options.unityLightGameObjectName : "false"),
          ]
        }
      ];
      console.log('COLOR CHANGE MOD: ', currModifiers);
      window.analytics.track('Environment Settings Change', {
        option: "Environment Color Change",
        unityMaterial: this.state.modifiersEnv[i].options.materialName,
        value: color.hex
      });
      this.unityContent.send('ModifierManager', 'applyModifierToEnvironment', JSON.stringify(currModifiers));
    }
  }

  handleChangeMaterialEnvComplete(material, i){
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].options.changeableMaterialID = material.assetbundle;
    newModifiers[i].options.materialFirebaseId = material.id;

    this.setState({ modifiersEnv: newModifiers, nextMaterialId: material.id });

    if(this.state.modifiersEnv[i].type === "materialEdit"){
      // todo create for all modifiers
      var currModifiers = [
        {
          "type": this.state.modifiersEnv[i].type,
          "options": [
            "materialName="+this.state.modifiersEnv[i].options.materialName,
            "isColorChangeable=" + (this.state.modifiersEnv[i].options.isColorChangeable === "true" ? "true" : "false"),
            "defaultColor=" + this.state.modifiersEnv[i].options.defaultColor || "#ffffff",
            "isMaterialChangeable=" + (this.state.modifiersEnv[i].options.isMaterialChangeable ==="true" ? "true" :  "false") ,
            "changeableMaterialID=" + material.assetbundle || "",
            "materialFirebaseId=" + material.id,
            "isLight=" + (this.state.modifiersEnv[i].options.isLight === "true" ? "true"  : "false"),
            "unityLightGameObjectName="  + ( (this.state.modifiersEnv[i].options.unityLightGameObjectName && this.state.modifiersEnv[i].options.unityLightGameObjectName != "false") ? this.state.modifiersEnv[i].options.unityLightGameObjectName  : "false"),

          ],

        }
      ];

      console.log("MAT CHANGE: ", JSON.stringify(currModifiers));

      window.analytics.track('Environment Settings Change', { option: "Environment Material Change", unityMaterial: this.state.modifiersEnv[i].options.materialName, value: material.title });
      this.unityContent.send('ModifierManager', 'applyModifierToEnvironment', JSON.stringify(currModifiers));
    }
  }


  toggleColorpickerEnv(i) {
    window.analytics.track('Environment Settings Change', { option: "toggleColorPickerEnv", value: "toggle" });
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].colorPickerOpen = newModifiers[i].colorPickerOpen ? false : true;
    this.setState({ modifiersEnv: newModifiers });
  }

  constructor(props) {
    super(props);
    this.unityContent = this.props.unityContent;
    this.handleChangeMaterialEnvComplete = this.handleChangeMaterialEnvComplete.bind(this);


  }

  updateObjectTransforms(type) {
    window.analytics.track('Switch Transform Tool', { tool: type });
    this.currentRotationCommand = type;
    this.unityContent.send("SceneRecreator", "sendSelectedObjectTransformationDataViaExternalFunction");
  }

  resetComposition() {
    this.unityContent.send("CameraController", "resetViewAndComposition");
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    // environment update
    if (prevProps.environment !== this.props.environment) {

      const currJSMods = this.createJavascriptModifier(this.props.environment.modifiers)


      this.setState({
        modifiersEnv: currJSMods,
        envName: (currJSMods[0] && this.props.environmentModifersAtStart[currJSMods[0].options.materialName]) ? this.props.environmentModifersAtStart[currJSMods[0].options.materialName].modifierViewerText : "",
        isLoadingEnv: false
      });
    }
  }

  createModifierForObj(firebaseModifiers, unityModifiers) {
    // firebaseModifiers = this.createJavascriptModifier(firebaseModifiers || []);
    unityModifiers = this.createJavascriptModifier(unityModifiers || []);
    console.log(`CURR MODS firebaseModifiers: ${JSON.stringify(firebaseModifiers)}`);
    console.log(`CURR MODS firebaseModifiers: UNITY ${JSON.stringify(unityModifiers)}`);

    console.log('CURR MODS firebaseModifiers:', firebaseModifiers);
    console.log('CURR MODS unityModifiers:', unityModifiers);
    for (var i = 0; i < firebaseModifiers.length; i++) {
      if (firebaseModifiers[i].type === "materialEdit") {
        for (var j = 0; j < unityModifiers.length; j++) {
          if (firebaseModifiers[i].options.materialName === unityModifiers[j].options.materialName) {
            firebaseModifiers[i].options.defaultColor = unityModifiers[j].options.defaultColor;
          }
        }
      }
    }

    return firebaseModifiers;
  }

  createJavascriptModifier(modifiers) {
    var javascriptModifiers = [];
    for (var i = 0; i < modifiers.length; i++) {
      modifiers[i].options = this.modifierOptionsToProperties(modifiers[i].options);
      javascriptModifiers.push(modifiers[i]);
    }
    return javascriptModifiers;
  }

  modifierOptionsToProperties(options) {
    var returnOption = {};
    if (Array.isArray(options)) {
      for (var i = 0; i < options.length; i++) {
        const currOption = options[i].split("=");
        returnOption[currOption[0]] = currOption[1];
      }
    } else {
      returnOption = options;
    }

    return returnOption;
  }

  setCurrEnvData(firebaseId) {
    const currMod = this.state.standardMods ? this.state.standardMods : this.downloadedEnvironments[firebaseId].modifiers.map(a => a.options.defaultColor);
    ;
    this.setState({
      envName: this.downloadedEnvironments[firebaseId].heading,
      envPreview: this.downloadedEnvironments[firebaseId].preview,
      modifiersEnv: this.downloadedEnvironments[firebaseId].modifiers,
      isLoadingEnv: false,
      standardMods: currMod,
    });
  }

  componentDidMount() {
  }

  deleteCurrSelection() {
    console.log("HI WHATS UP");
    this.props.deleteSelected("Delete Selection");
  }


  render() {
    return (
      <div
        className={"object-panel-viewer " + (this.state.modifiersEnv && this.state.modifiersEnv.length > 0 ? "" : "hidden")}
        onClick={() => {
          this.props.unityContent.send('Network', 'focusCanvas', "0");
        }}>
        {
          this.state.isLoadingEnv ?
            (
              <>
                <div className="object-name-container">
                  <Placeholder.Line/>
                </div>
                <div className="object-modifier-container">
                  <div className="placeholder-container">
                    <Placeholder>
                      <Placeholder.Header/>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                    </Placeholder>
                  </div>
                </div>
              </>
            )
            :
            (
              <>
                <div className="object-name-container">
                  <h3 className="object-name">{this.state.envName}</h3>
                </div>
                <div className="object-modifier-container">
                  {this.state.modifiersEnv.map((modifier, i) => {
                    return (
                      <div className={"modifierContainer"}>
                      {(modifier.type === "materialEdit" && modifier.options.isColorChangeable === "true") && (
                        <InputColor
                          key={"color-input-container-" + i}
                          title={this.props.environmentModifersAtStart[modifier.options.materialName] ? this.props.environmentModifersAtStart[modifier.options.materialName].modifierViewerText : ("Material" + (i + 1))}
                          initialHexColor={modifier.options.defaultColor}
                          onChange={(color) => (this.handleChangeEnvComplete(color, i))}
                          recommendColors={[].concat( (this.props.environmentModifersAtStart && this.props.environmentModifersAtStart[modifier.options.materialName]) ? this.props.environmentModifersAtStart[modifier.options.materialName].colorRec : []).concat(this.props.reccomendedColorsGlobally)}/>
                        )}

                      {(modifier.type === "materialEdit" && modifier.options.isMaterialChangeable === "true") && (
                        <InputMaterial
                          key={"material-input-container-" + i}
                          title={this.props.environmentModifersAtStart[modifier.options.materialName] ? this.props.environmentModifersAtStart[modifier.options.materialName].modifierViewerText : ("Material" + (i + 1))}
                          index={i}
                          onChange={this.handleChangeMaterialEnvComplete}
                          activeMaterialId={modifier.options.materialFirebaseId}
                          modifier={modifier}
                          unityContent={this.props.unityContent}
                          nextMaterialId={this.state.nextMaterialId}
                        />
                        )}
                      </div>
                    )
                  })}
                  <div className={"div-block-54"}></div>
                </div>

              </>
            )
        }
      </div>
    );
  }
});

export default withFirestore(PropertiesEnvironment);


