import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { ChromePicker, CirclePicker } from 'react-color';
import { Placeholder } from 'semantic-ui-react';
import { FirestoreDocument, withFirestore } from 'react-firestore';

// components
import GeneralSettings from '../general_settings/ViewerSettingsGeneral';
import MaterialChange from './MaterialChange';

// symbols
import ColorSymbol from '../../../images/noun_drop_1235520.svg'






const ColorAndtextureSettingsEnvironment = observer(class ColorAndtextureSettingsEnvironment extends Component {

  state = {
    background: '#fff',
    isLoadingEnv: true,
    preview: "",
    envName: "",
    envPreview: "",
    modifiersEnv: "",
    standardMods: undefined,
  };

  standardMods= undefined;

  downloadedEnvironments = {};

  handleChangeMaterialEnvComplete(material, i){
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].options.changeableMaterialID = material.assetbundle;
    this.setState({ modifiersEnv: newModifiers });

    if(this.state.modifiersEnv[i].type === "materialEdit"){
      // todo create for all modifiers
      var currModifiers = [
        {
          "type": this.state.modifiersEnv[i].type,
          "options": [
            "materialName="+this.state.modifiersEnv[i].options.materialName,
            "isColorChangeable=" + (this.state.modifiersEnv[i].options.isColorChangeable === "true" ? "true" : "false"),
            "defaultColor=" + this.state.modifiersEnv[i].options.defaultColor || "#ffffff",
            "isMaterialChangeable=" + (this.state.modifiersEnv[i].options.isMaterialChangeable ==="true" ? "true" :  "false") ,
            "changeableMaterialID=" + material.assetbundle || "",
            "materialFirebaseId=" + material.id,
            "isLight=" + (this.state.modifiersEnv[i].options.isLight === "true" ? "true"  : "false"),
            "unityLightGameObjectName="  + ( (this.state.modifiersEnv[i].options.unityLightGameObjectName && this.state.modifiersEnv[i].options.unityLightGameObjectName != "false") ? this.state.modifiersEnv[i].options.unityLightGameObjectName  : "false"),

          ],

        }
      ];
      console.log("MAT CHANGE: ", JSON.stringify(currModifiers));

      window.analytics.track('Environment Settings Change', { option: "Environment Material Change", unityMaterial: this.state.modifiersEnv[i].options.materialName, value: material.title });
      this.unityContent.send('ModifierManager', 'applyModifierToEnvironment', JSON.stringify(currModifiers));
    }
  }

  handleChangeEnvComplete(color, i){
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].options.defaultColor = color.hex;
    this.setState({ modifiersEnv: newModifiers });

    if(this.state.modifiersEnv[i].type === "materialEdit"){
      // todo create for all modifiers
      var currModifiers = [
        {
          "type": this.state.modifiersEnv[i].type,
          "options": [
            "materialName="+this.state.modifiersEnv[i].options.materialName,
            "isColorChangeable="+ (this.state.modifiersEnv[i].options.isColorChangeable === "true" ? "true" : "false"),
            "defaultColor=" + (color.hex || ""),
            "isMaterialChangeable=" + (this.state.modifiersEnv[i].options.isMaterialChangeable ==="true" ? "true" :  "false") ,
            "changeableMaterialID=" + (this.state.modifiersEnv[i].options.changeableMaterialID ? this.state.modifiersEnv[i].options.changeableMaterialID  : ""),
            "materialFirebaseId=" + (this.state.modifiersEnv[i].options.materialFirebaseId ? this.state.modifiersEnv[i].options.materialFirebaseId  : ""),
            "isLight=" + (this.state.modifiersEnv[i].options.isLight === "true" ? "true"  : "false"),
            "unityLightGameObjectName="  + ( (this.state.modifiersEnv[i].options.unityLightGameObjectName && this.state.modifiersEnv[i].options.unityLightGameObjectName != "false") ? this.state.modifiersEnv[i].options.unityLightGameObjectName  : "false"),
          ]
        }
      ];
      console.log('COLOR CHANGE MOD: ', currModifiers);
      window.analytics.track('Environment Settings Change', { option: "Environment Color Change", unityMaterial: this.state.modifiersEnv[i].options.materialName, value: color.hex });
      this.unityContent.send('ModifierManager', 'applyModifierToEnvironment', JSON.stringify(currModifiers));
    }
  }

  toggleColorpickerEnv(i){
    window.analytics.track('Environment Settings Change', { option: "toggleColorPickerEnv", value: "toggle" });
    var newModifiers = this.state.modifiersEnv;
    newModifiers[i].colorPickerOpen = newModifiers[i].colorPickerOpen ? false : true;
    this.setState({ modifiersEnv: newModifiers });
  }

  constructor(props){
    super(props);
    this.unityContent = this.props.unityContent;
    this.handleChangeMaterialEnvComplete = this.handleChangeMaterialEnvComplete.bind(this);


  }

  updateObjectTransforms(type){
    window.analytics.track('Switch Transform Tool', { tool: type});
    this.currentRotationCommand = type;
    this.unityContent.send("SceneRecreator", "sendSelectedObjectTransformationDataViaExternalFunction");
  }

  resetComposition(){
    this.unityContent.send("CameraController", "resetViewAndComposition");
  }


  changeGravity(){
    // this.props.handleGeneralSettings('gravity', !this.props.viewerState.gravity);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // environment update
    if (prevProps.environment !== this.props.environment) {
      console.log('HI I AM HERE: ', this.props.environment);
      this.setState({ standardMods: undefined });
      this.setState({ isLoadingEnv: true });
      if (this.downloadedEnvironments[this.props.environment.firebaseId]) {
        console.log('this environment has already been downloaded, load from store');
        this.setCurrEnvData(this.props.environment.firebaseId);

      } else {
        const { firestore } = this.props;
        const currentCollection = "Environments";
        firestore.doc(currentCollection + '/' + this.props.environment.firebaseId).onSnapshot(snapshot => {
          console.log('this.props.environemnt: ', this.props.environment);
          var currObjectData = snapshot.data();

          currObjectData.modifiers = this.createModifierForObj(currObjectData.modifiers, this.props.environment.modifiers);
          console.log('CURR MODS after: ', currObjectData.modifiers );
          this.downloadedEnvironments[this.props.environment.firebaseId] = currObjectData;
          this.setCurrEnvData(this.props.environment.firebaseId);
        });
      }
    }
  }

  createModifierForObj(firebaseModifiers, unityModifiers){
    firebaseModifiers = this.createJavascriptModifier(firebaseModifiers || []);
    unityModifiers = this.createJavascriptModifier(unityModifiers || []);

    console.log('CURR MODS firebaseModifiers:', firebaseModifiers);
    console.log('CURR MODS unityModifiers:', unityModifiers);
    for (var i = 0; i<firebaseModifiers.length; i++){
      if(firebaseModifiers[i].type === "materialEdit"){
        for (var j = 0; j<unityModifiers.length; j++){
          if(firebaseModifiers[i].options.materialName === unityModifiers[j].options.materialName){
            firebaseModifiers[i].options.defaultColor = unityModifiers[j].options.defaultColor;
          }
        }
      }
    }

    return firebaseModifiers;
  }

  createJavascriptModifier(modifiers){
    var javascriptModifiers = [];
    for (var i = 0; i<modifiers.length; i++){
      modifiers[i].options = this.modifierOptionsToProperties(modifiers[i].options);
      javascriptModifiers.push(modifiers[i]);
    }
    return javascriptModifiers;
  }

  modifierOptionsToProperties(options){
    var returnOption = {};
    for(var i = 0; i<options.length; i++){
      const currOption = options[i].split("=");
      returnOption[currOption[0]] = currOption[1];
    }
    return returnOption;
  }

  setCurrEnvData(firebaseId){
    const currMod = this.state.standardMods ? this.state.standardMods :  this.downloadedEnvironments[firebaseId].modifiers.map(a => a.options.defaultColor);;
    this.setState({
      envName: this.downloadedEnvironments[firebaseId].heading,
      envPreview: this.downloadedEnvironments[firebaseId].preview,
      modifiersEnv: this.downloadedEnvironments[firebaseId].modifiers,
      isLoadingEnv: false,
      standardMods: currMod,
    });
  }

  componentDidMount() {
  }

  deleteCurrSelection(){
    console.log("HI WHATS UP");
    this.props.deleteSelected("Delete Selection");
  }


  render() {

    return(
            <div className={" " + (this.props.isActive ? "" : "hidden ") } >
              <div>
                <div className="objectinformationtab">
                  <div className="objectimagecontainer">
                    {this.state.isLoadingEnv ? (
                      <Placeholder>
                        <Placeholder.Image square />
                      </Placeholder>
                    ) : (
                      <img src={this.props.envPreview} alt="" className="image-12" />
                    )}
                  </div>
                  <div className="objectinformationtextcontainer">
                    { this.state.isLoadingEnv ? (
                      <Placeholder>
                        <Placeholder.Header />
                        <Placeholder.Line />
                      </Placeholder>
                    ) : (
                      <div>
                        <div className="objectinformationtype">Scene</div>
                        <h3 className="objectinformationname">{this.state.envName}</h3>
                      </div>
                    )}
                  </div>

                </div>

                <GeneralSettings viewerState={this.props.state} rendercost={this.props.rendercost} settingsOptions={this.props.settingsOptions} handleGeneralSettings={this.props.handleGeneralSettings} calculateCurrentRenderCost={this.props.calculateCurrentRenderCost} />

                { this.state.isLoadingEnv ? (
                  <div className="propertycontainer">
                    <Placeholder>
                      <Placeholder.Header/>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                    </Placeholder>
                    <Placeholder>
                      <Placeholder.Header/>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                    </Placeholder>
                    <Placeholder>
                      <Placeholder.Header/>
                      <Placeholder.Line/>
                      <Placeholder.Line/>
                    </Placeholder>
                  </div>
                ) : (
                  <div className="propertycontainer">
                    {this.state.modifiersEnv.map((modifier, i) => {
                      return (
                        // TODO: check if this is a material edit modifier
                        <div className="propertycontainer">
                          { (window.location.origin !== "https://studio.zerolens.com" &&  modifier.options.isMaterialChangeable === "true")
                            &&
                          (<MaterialChange title={"Material Change"}
                                           unityContent={this.props.unityContent}
                                           modifier={modifier}
                                           modifierIndex={i}
                                           onChangeComplete={ this.handleChangeMaterialEnvComplete } />)
                          }

                          { modifier.options.isColorChangeable === "true"
                          && (
                            <div className="propertycontainer">
                              <div className="div-block-9">
                                <div className="propertytitle no-margin">{modifier.modifierViewerText  || ("Material" + (i+1) )}</div>
                                {modifier.colorPickerOpen ? (<div className="colorlink" onClick={ () => { this.toggleColorpickerEnv(i)} }>close</div>) : (<div className="colorlink" onClick={ () => { this.toggleColorpickerEnv(i)} }>+ custom Color</div>) }
                              </div>


                              <CirclePicker color={ modifier.options.defaultColor || "#ffffff" } colors={ modifier.colorRec || [] }
                                            onChangeComplete={ (color) => (this.handleChangeEnvComplete(color, i)) }/>

                              { modifier.colorPickerOpen &&  (
                                <ChromePicker disableAlpha={true} color={ modifier.options.defaultColor || "#ffffff" }
                                              onChangeComplete={ (color) => (this.handleChangeEnvComplete(color, i)) }/>

                              ) }
                            </div>
                          )}


                        </div>
                      );
                    })}

                  </div>

                  )
                }

                <div className="objectpaneldeletebutton" onClick={ () => { this.resetComposition() } }>
                  <div>RESET TO START</div>
                </div>


              </div>
            </div>
    )
  }
});


export default withFirestore(ColorAndtextureSettingsEnvironment);
