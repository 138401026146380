import React, { Component, location, history, useEffect } from 'react';
import '../page_elements/hero/Hero.css';
import ReactDOM from 'react-dom';
import {RemoveScroll} from 'react-remove-scroll';
import { withRouter } from 'react-router-dom';

import { connectInfiniteHits } from 'react-instantsearch-dom';




class Preview extends Component {


  goToNextPreview(){
    const hits = this.props.hits;
    const currIndex = hits.indexOf(this.props.hit);

    if(currIndex <= hits.length -2 ){
      this.props.setHitPreview(hits[currIndex + 1]);
    }
  }

  goToPrevPreview(){
    const hits = this.props.hits;
    const currIndex = hits.indexOf(this.props.hit);

    if(currIndex > 0 ){
      this.props.setHitPreview(hits[currIndex - 1]);
    }
  }

  componentDidMount(){
    document.addEventListener("keydown", this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  handleKeyPress = (event) => {
    if(event.key === "ArrowLeft"){
      this.goToPrevPreview()
    }else if(event.key === "ArrowRight"){
      this.goToNextPreview();
    }
  }

  render() {
    return ReactDOM.createPortal(
      <RemoveScroll className={"react-portal " + (this.props.isActive ? '' : 'hidden') } enabled={this.props.isActive}>
        <div className="creator-overlay-container-preview"
             onMouseDown={(event) => {
              if (event.target === event.currentTarget) {
                this.props.hidePreview();
              }}}
             onKeyPress={this.handleKeyPress}
        >
          <div className="backward-button-container" onClick={ () => { this.goToPrevPreview() }}>
            <div className="html-embed-6 w-embed">
              <span dangerouslySetInnerHTML={{ __html: "<svg fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 139 139\" enable-background=\"new 0 0 139 139\" xml:space=\"preserve\"><path d=\"M54.369,65.541c0.283-0.282,0.59-0.52,0.913-0.727l21.608-21.608c2.108-2.107,5.528-2.108,7.637,0.001  c2.109,2.108,2.109,5.527,0,7.637L65.918,69.453l18.705,18.706c2.109,2.108,2.109,5.526,0,7.637  c-1.055,1.056-2.438,1.582-3.818,1.582s-2.764-0.526-3.818-1.582L55.282,74.09c-0.323-0.207-0.632-0.445-0.913-0.727  c-1.078-1.078-1.598-2.498-1.572-3.911C52.771,68.039,53.291,66.619,54.369,65.541z\"></path></svg>"}} />
            </div>
          </div>
          <img src="images/noun_X_1890803_1.svg" alt="" className="image-5" onClick={ () => {this.props.hidePreview() }}/>
            <div className="overlay-container-prev">
              <div className="div-block-48">
                <div className="div-block-52">
                  <div className="div-block-46">
                    <img src={this.props.hit ? this.props.hit.preview : ''} alt="" className="image-21" />
                  </div>
                  <div className="div-block-49">
                    <h4 className="h4-prev">Tags</h4>
                    <div className="div-block-51">
                      {
                        this.props.hit && this.props.hit.tags && Array.isArray(this.props.hit.tags) && this.props.hit.tags.map((tag) => (
                        <div className="tag-container">{tag}</div>
                      ))
                      }
                      {
                        this.props.hit && this.props.hit.tags && !Array.isArray(this.props.hit.tags) && this.props.hit.tags.split(',').map((tag) => (
                          <div className="tag-container">{tag}</div>
                        ))
                      }
                      </div>
                  </div>
                </div>
                <div className="div-block-47">
                  <h1 className="h1-prev">{this.props.hit ? this.props.hit.name : ''}</h1>
                  <a href="#" id={"open-studio-button"} className="regular-button full-width w-button" onClick={(i) => {
                    if(this.props.joyrideRunning){
                      this.props.advanceJoyRideStepIndex(4);
                    }else{
                      if(this.props.hit){
                        this.props.hidePreview();
                        console.log(`HIT: ${this.props.hit.objectID}`);
                        this.props.history.push('../template/' + this.props.hit.objectID);

                        var currStore = this.props.store.store;
                        currStore.isViewerVisible = true;
                        currStore.currentEnvId = this.props.hit.objectID;
                        currStore.currentEnvironmentPreview = this.props.hit.preview;
                        currStore.currEnvironmentName = this.props.hit.name;

                        this.props.store.updateStore(currStore);

                        window.analytics.track('View Environment', { environmentName: this.props.hit.name, environmentId: this.props.hit.objectID })
                      }
                    }

                  }}>Open in zerolens 3D Editor</a></div>
              </div>
              {/*<h3 className="h2-prev">Similar Designs</h3>*/}
              {/*<div className="div-block-45"><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" /><img*/}
                {/*src="https://d3e54v103j8qbb.cloudfront.net/plugins/Basic/assets/placeholder.60f9b1840c.svg" alt=""*/}
                {/*className="image-20" />*/}
              {/*</div>*/}
            </div>
            <div className="backward-button-container forward" onClick={() => {if(!this.props.joyrideRunning){this.goToNextPreview()}}}>
              <div className="html-embed-6 w-embed">
                <span dangerouslySetInnerHTML={{ __html: "<svg fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 139 139\" enable-background=\"new 0 0 139 139\" xml:space=\"preserve\"><path d=\"M84.629,65.592c-0.28-0.282-0.59-0.52-0.912-0.727L62.108,43.257c-2.109-2.107-5.527-2.108-7.637,0.001  c-2.109,2.108-2.109,5.527,0,7.637l18.611,18.609L54.377,88.211c-2.11,2.107-2.11,5.527,0,7.637c1.055,1.053,2.436,1.58,3.817,1.58  c1.381,0,2.765-0.527,3.817-1.582l21.706-21.703c0.322-0.207,0.631-0.444,0.912-0.727c1.08-1.08,1.598-2.498,1.574-3.912  C86.228,68.09,85.709,66.67,84.629,65.592z\"></path></svg>"}} />
              </div>
            </div>
        </div>
      </RemoveScroll>
    , document.getElementById('preview')
    )
  }
};

export default withRouter((Preview));
