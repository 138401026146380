import React, { Component, location, history } from 'react';
import { FirestoreDocument, withFirestore } from 'react-firestore';



const OrganizationHandler = class OrganizationHandler extends Component {

  async componentDidUpdate(prevProps, prevState, snapshot){
    var prevUser = undefined;
    if(prevProps){
      prevUser = prevProps.user ? prevProps.user : undefined;
    }
    if( (this.props.user !== prevUser && this.props.user)) {
      //const idTokenResult = await this.props.user.getIdTokenResult();
      //const customClaims = idTokenResult.claims;
      var oid = this.props.user.oid;
      console.log('USER Org: ', this.props.user);


      const { firestore } = this.props;
      firestore.doc('Organizations/' + oid).onSnapshot(snapshot => {
        var org = snapshot.data();
        org.id = oid;
        this.props.handleOrgUpdate(org);
        console.log('org: ', org);
      });
    }
  }


  render() {
    return(
      <div >
      </div>
    )

  }
};


export default withFirestore(OrganizationHandler);
