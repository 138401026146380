import React, { Component, location, history, useContext } from 'react';
import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import { InfiniteHits } from 'react-instantsearch-dom';
import InfiniteHitsScroll from './components/feed/CustomInfiniteHitsScroll';
import {observable, computed} from 'mobx';
import { observer } from "mobx-react"
import { withRouter} from "react-router-dom";
import  { Redirect } from 'react-router-dom';
import { Placeholder, Segment, Dimmer, Loader } from 'semantic-ui-react';
import CheeseburgerMenu from 'cheeseburger-menu';


// import { observer } from 'mobx-react';

import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
} from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import './App.css';
import Nav from './components/page_elements/nav/Nav';
import Hero from './components/page_elements/hero/Hero';
import FeedHeading from './components/feed/FeedHeading';
import Viewer from './components/viewer/Viewer';
import ViewerMini from './components/viewer/viewer_elements/ViewerMini';
import SideBar from './components/SideBar/SideBar';
import withFirebaseAuth from 'react-with-firebase-auth';
import ViewerContext from './context/ViewerContext';
import Preview from './components/preview/preview';
import joyRideStarterTemplates from './components/helpers/joyrideHelpers/joyrideTutorialTemplates';


// const this.context = new Environmentthis.context();

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  'DSARB83COY',
  '1e1748b97732b49386fb7e2f2bcc8ff2',
);

const createURL = state => {
  console.log('CREATING URL');
  const query = state.query;
  const page = state.page;
  return `/search/${query}`;
  // return `?${qs.stringify(state)}`;
}

const searchStateToUrl = (props, searchState) =>
  searchState ? `${createURL(searchState)}` : '';

const urlToSearchState = location => {
  query = '';
  // HANDLING REBUILDING OF A USER SNAPSHOT
  var query = location.pathname.replace('search', '').replace('/', '').replace('/', '');
  query = query == "/" ? '' : query;
  // store.viewerMode = 'normal';
  // store.currentSnapshotId = undefined;
  window.analytics.track('search', { query: query });
  return {query: query, page: 1}
  // return qs.parse(location.search.slice(1));
};

class App extends Component {

  static contextType =  ViewerContext; // loading in the Vier Context, its important that this is called contextType

  constructor(props) {
    super(props);
    this.hidePreview = this.hidePreview.bind(this);
    this.setHitPreview = this.setHitPreview.bind(this);
    this.setNewHitsArray = this.setNewHitsArray.bind(this);
    this.state = {
      searchState: {query: "", page: 1},
      lastLocation: this.props.location,
      isHeroVisible: true,
      menuOpen: false,
      isPreviewVisible: false,
      hit: undefined,
      hits: []
    };

  }

  // tour = useContext(ShepherdTourContext);


  state = {
    searchState: urlToSearchState(this.props.location),
    lastLocation: this.props.location,
    isHeroVisible: true,
    menuOpen: false,
  };

  static getDerivedStateFromProps(props, state) {
    console.log('props.location', props.location);
    var store= props.store;
    var startingStore = props.store;

    if(props.location.pathname.split('/sid/')[1] || props.location.pathname === "/sid/"){
      // store.lastSearchLocation = state.lastLocation;
      // console.log(`Last Location: ${JSON.stringify(state.lastLocation)}`);
      // console.log('TODO: handle display new snapshot');
      // window.analytics.page('/sid/');
      store.isViewerVisible = true;
      // store.viewerMode = 'snapshot';
      // store.currentSnapshotId = props.location.pathname.split('/sid/')[1];
      // console.log('I AM HERE');
      // store.shouldBeClosed = false;
      // if(startingStore !== store) {props.updateStore(store)};
    } else if(props.location.pathname === "/dev" || props.location.pathname === "/dev/"){


    }else if(props.location.pathname.split('/template/')[1] || props.location.pathname === "/template/"){
      // store.lastSearchLocation = state.lastLocation;
      // console.log(`Last Location: ${JSON.stringify(state.lastLocation)}`);
      // window.analytics.page('/template/');
      store.isViewerVisible = true; // this is important to close the viewer on back button
      // store.viewerMode = 'normal';
      // console.log(`Should be closed: ${store.shouldBeClosed}`);
      // store.shouldBeClosed = false;
      // store.currentEnvId = props.location.pathname.split('/')[2];
      // if(startingStore !== store) {props.updateStore(store)};
    }
    else {
      // if(!store.lastLocation.pathname.split('sid') && !store.lastLocation.pathname.split('template')){}

      if (props.location !== state.lastLocation) {
        return {
          searchState: urlToSearchState(props.location),
          lastLocation: props.location,
        };
      }

    }

    return null;
  }

  onSearchStateChange = searchState => {
    clearTimeout(this.debouncedSetState);

    this.debouncedSetState = setTimeout(() => {
      this.props.history.push(
        searchStateToUrl(this.props, searchState),
        searchState
      );
    }, DEBOUNCE_TIME);

    if(location){
      if(location.pathname.split('/sid/')[1]){

      }else if(location.pathname === "/dev" || location.pathname === "/dev/"){

      }else {
        this.context.viewerMode = 'normal';
        this.context.currentSnapshotId = undefined;
      }
    }


    const isHeroVisible = !(searchState.query !== undefined && searchState.query);
    this.setState({ searchState, isHeroVisible });
  };



  componentDidMount(){
    window.analytics.page('/');
    const isHeroVisible = !(this.state.searchState.query);
    this.setState({ isHeroVisible: isHeroVisible, searchState: urlToSearchState(this.props.location, this.context) });
  }

  componentDidUpdate(prevProps){
    // if(urlToSearchState(this.props.location, this.context) != this.state.searchState){
    //   clearTimeout(this.debouncedSetState);
    //
    //   this.debouncedSetState = setTimeout(() => {
    //     this.setState({searchState: urlToSearchState(this.props.location, this.context) });
    //   })
    // }
    //
    // if(this.props.location.pathname.split('/')[1] === 'sid'){
    //   console.log('TODO: handle display new snapshot');
    // }else {
    //   if (this.props.location !== prevProps.location) {
    //     return {
    //       searchState: urlToSearchState(this.props.location, this.context),
    //       lastLocation: this.props.location,
    //     };
    //   }
    // }

  }

  openMenu() {
    this.setState({ menuOpen: true })
  }

  closeMenu() {
    this.setState({ menuOpen: false })
  }

  hidePreview(){
    this.setState({isPreviewVisible: false});
  }

  setHitPreview(hit){
    console.log(`hit: ${JSON.stringify(hit)}`);
    this.setState({ hit: hit, isPreviewVisible: true});
  }

  setNewHitsArray(hits){
    this.setState({ hits: hits});
  }

  // interaction
  render() {
    return (
      <div>
        <CheeseburgerMenu
          isOpen={this.state.menuOpen}
          closeCallback={this.closeMenu.bind(this)}>
          <SideBar active={"feed"} closeCallback={this.closeMenu.bind(this)} />
        </CheeseburgerMenu>

        <InstantSearch searchClient={searchClient}
                       indexName={this.props.feed}
                       searchState={this.state.searchState}
                       onSearchStateChange={this.onSearchStateChange}
                       createURL={createURL}
        >
          <Nav user={this.props.firebaseUser} openMenu={this.openMenu.bind(this)} cms={this.props.cms} org={this.props.org} handleUserUpdate={ this.props.handleUserUpdate} firebase={this.props.firebase} />
          <Hero isVisible={this.state.isHeroVisible} query={this.state.searchState.query} />
          {this.state.searchState.query && (
            <FeedHeading  query={this.state.searchState.query} isVisible={true} />
          )}

          {this.props.joyrideRunning && (
            joyRideStarterTemplates(this.setHitPreview, this.setNewHitsArray, this.props.advanceJoyRideStepIndex)
          )}

          <div className="container" id={"sheperd-grid-container"} >
            <div className="search-panel">
              <div className="search-panel__results">
                <InfiniteHitsScroll hits={Hits} store={this.context} setHitPreview={this.setHitPreview} setNewHitsArray={this.setNewHitsArray} />
              </div>
            </div>
          </div>
        </InstantSearch>
        <Preview isActive={this.state.isPreviewVisible} hidePreview={this.hidePreview} hits={this.state.hits} hit={this.state.hit} store={this.context} setHitPreview={this.setHitPreview} joyrideRunning={this.props.joyrideRunning} advanceJoyRideStepIndex={this.props.advanceJoyRideStepIndex} />
      </div>
    );
  }
};




export default withRouter(App);
