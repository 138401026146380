import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore } from 'react-firestore';
import InViewerSettingsElement from './InViewerSettingsElement';

import addSymbol from '../../../../images/noun_add_2664285-1.svg';
import addSymbolColor from '../../../../images/noun_add_2768148.svg';
import binSymbol from '../../../../images/noun_bin_1433555-1.svg';
import gearSymbol from '../../../../images/noun_Settings_2650515.svg';
import transformSymbol from '../../../../images/noun_rotation_2574812.svg';
import moveSymbol from '../../../../images/noun_Move_2451320.svg';
import scaleSymbol from '../../../../images/noun_scale tool_337727.svg';
import previewSymbol from '../../../../images/noun_Before After_576653.svg';


const ViewerMenuTop = observer(class ViewerMenu extends Component {
  constructor(props){
    super(props);
    this.unityContent = this.props.unityContent;
  }

  handleUndo(){
    this.unityContent.send('UndoManager', "undo");
    window.analytics.track('UndoRedoButton', {type: "undo", action: "click"});
  }

  handleRedo(){
    this.unityContent.send('UndoManager', "redo");
    window.analytics.track('UndoRedoButton', {type: "redo", action: "click"});
  }

  render() {
    let styleDiv = this.props.position == 'top right' ? { top: 0, right: 0, left: 'auto', bottom: 'auto', zIndex: 100  }  : {};

    return (
      <div className={" " + (this.props.isHidden ? 'hidden' : '')}>
        {/*<div style={styleDiv} className={"side-menu-in-viewer " + (this.props.isHidden ? 'hidden' : '')}>*/}

          {/*<InViewerSettingsElement isActive={this.props.viewerState.isSettingsVisible} isVisible={true} title={""} symbol={previewSymbol} mouseEnter={() => {this.props.onShowPreview(true); window.analytics.track('Show Comp Preview', {position: "top-right", action:"hover"}); }} mouseLeave={() => this.props.onShowPreview(false)}  />*/}
        {/*</div>*/}
        <div className="undo-redo-container">
          {/*<img src="/images/noun_Undo_2845917.svg" alt="" className="undo-redo-img" onClick={() => {this.handleUndo()} } />*/}
          {/*<img src="/images/noun_redo_2845904.svg" alt="" className="undo-redo-img" onClick={() => {this.handleRedo()} } />*/}
          { window.location.origin !== "https://studio.zerolens.com" && ( <div stlye={{color: "white"}} onClick={() => {this.props.loadSnapshotSlowly()}}>load slowly</div> ) }
        </div>
        {/*<img*/}
          {/*src="/images/noun_help_2302551.svg" className="help-symbol" style={{zIndex: 10}} onClick={ () => {this.props.onPressHelp(); window.analytics.track('Show Viewer Help', {position: "top-left", action:"click"} );} } />*/}

      </div>

    );
  }
});


export default withFirestore(ViewerMenuTop);
