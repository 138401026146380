import React, { Component } from 'react';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import PropTypes from 'prop-types';
import './CustomInfiniteHitsObjects.css';
import Masonry from 'react-masonry-css';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';


import TextOnlyTooltipComponent from '../../helpers/Tooltip/TextOnlyTooltipComponent';



const breakpointColumnsObj = {
  default: 3,
  950: 2,
  700: 2,
  500: 1
};

function convertAssetBundleLinkToNewFormt(assetbundle, type){
  if (assetbundle.includes("http")){
    if(type === 'product'){
      assetbundle = 'products/' +assetbundle.split('/products/')[1];
    }else{
      console.log('type: lifestyleobject');
      assetbundle = 'lifestyleobjects/' + assetbundle.split('/lifestyleobjects/')[1];
    }
  }
  return assetbundle;
}

class InfiniteHitsScroll extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refine: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refine } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refine();
      }
    });
  };

  state = {
    hits: []
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
    this.setState({hits: this.props.hits});
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if((prevProps && prevProps.hits !== this.props.hits) || !prevProps){
      this.setState({hits: this.props.hits});
    }
    if((prevProps && prevProps.loading !== this.props.loading)){
      this.handleLoadingUpdate(this.props.loading);
    }
    if((prevProps && prevProps.stopping !== this.props.stopping)){
      this.handleLoadingStop(this.props.stopping);
    }
  }

  constructor(props) {
    super(props);
    this.unityContent = this.props.unityContent;
  }

  handleLoadingUpdate(argument){
    var argumentArray = argument.split("␞");
    const currId = argumentArray[0];

    const currProgress = argumentArray[2];
    const currType = argumentArray[1];
    console.log('currentIDDOWNLOAD: ', currId);
    console.log('activeId: ', this.currentDownloadId);
    console.log('currType', currType);

    console.log('ARGUMENTARRAY Inside: ', argumentArray);
    // update state of object to loading
    this.updateLoadingAnimationForProductsAndObjects(currId, currType, Number(currProgress), true);
  }

  handleLoadingStop(argument){
    var argumentArray = argument.split("␞");

    const currId = argumentArray[0];
    const currProgress = argumentArray[2];
    const currType = argumentArray[1];
    console.log('ARGUMENTARRAY: ', argumentArray);
    // update state of object to loading
    this.updateLoadingAnimationForProductsAndObjects(currId, currType, false, false);
  }

  updateLoadingAnimationForProductsAndObjects(currId, currType, progress, isLoading){
    if(currType !== "product"){
      console.log('CURR CUR ID: ', currId);
      console.log('STATE: ', this.state.hits);
      var currentObject = this.state.hits.find(x => x.objectID == currId);
      if(currentObject){
        console.log('OBJECT ID: ', currentObject);
        currentObject.isLoading = isLoading;
        currentObject.progress = progress || 0;
        currentObject.objectID = currId;
        currentObject.loadingText = isLoading ? "Loading" : "";
        this.setState({ hits: this.state.hits });
      }
    }
  }


  handleClick = (event, id, assetbundle, type, isSwap, modifiers) => {
    event.preventDefault();
    // checks if assetbundle is already the new type or the old type, if old type, convert to new format
    assetbundle = convertAssetBundleLinkToNewFormt(assetbundle, type);
    console.log('new assetbundle format: ', assetbundle);

    this.currentDownloadId = id;
    if(!this.props.isDev){ this.updateLoadingAnimationForProductsAndObjects(id, type, 0, true) };

    if(!(type === 'product')) {
      type = 'obj';
    }

    const modifierArgument = modifiers ? "␞" + JSON.stringify(modifiers) : "";

    const argument = id + "␞" + assetbundle + "␞" + type + modifierArgument;
    // TODO: ADD SWAP CALL
    this.unityContent.send('SceneLoader', 'loadNewProductOrObject', argument);
    console.log('add obj argument: ', argument);
    window.analytics.track('Add Object', { objectId: id, assetbundle: assetbundle, type: type, modifiers: modifiers});
  };

  render() {
    const { hits } = this.props;
    // var { store } = this.props.store;

    return (
      <div>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {this.state.hits.map( (hit) => (
            <TextOnlyTooltipComponent text={hit.name}
                                      refId={'object-' + hit.objectID}
                                      position={"left"} // default top, can be top, right, left, center
                                      arrowPosition={"center"} // default center
                                      render={() => (
                                        <Segment className="image-element-class" key={hit.objectID} id={'object-' + hit.objectID}
                                                 onClick={ (event) => { this.handleClick(event, hit.objectID, hit.assetbundle, 'obj', false, hit.modifiers) } } >
                                          <Dimmer active={hit.isLoading} disabled={!hit.isLoading} inverted>
                                            <Loader inverted content={Math.round(hit.progress * 100) + " %"} />
                                          </Dimmer>
                                          {hit.isFeatured && (<div className="text-block-40">NEW</div>)}
                                          <div className={'image-element-hover'}></div>
                                          <img src={hit.preview} className="masonry-grid-image" />


                                        </Segment>
                                      )}
            />

          ))}


        </Masonry>
        <span className="ais-InfiniteHits-sentinel" ref={c => (this.sentinel = c)} />
      </div>
    );
  }
}

export default connectInfiniteHits(InfiniteHitsScroll);
