import React, { Component, location, history } from 'react';
import  { Redirect } from 'react-router-dom';
import { Route, Link } from "react-router-dom";
import axios from 'axios';
import { Loader, Segment } from 'semantic-ui-react'


import { observer } from "mobx-react";


import ErrorMessageSignUp from './ErrorMessageSignUp';

// import { observer } from 'mobx-react';

const SignUp = observer(
  class SignUp extends Component {

    state = {
      email: "",
      password: "",
      name: "",
      errorMessage: "",
      isError: false,
      redirectAllowed: false,
      isSuccess: false,
    }

    handleEmailChange(e) {
      this.setState({ email: e.target.value });
    };

    handlePasswordChange(e) {
      this.setState({ password: e.target.value });
    };

    handleNameChange(e) {
      this.setState({ name: e.target.value });
    }

    async handleSignup(){
      try {
        this.setState({ redirectAllowed: false });
        await this.props.firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password);
        const user = this.props.firebase.auth().currentUser;
        if(user){
          window.analytics.track('user sign-up', {
            name: this.state.name,
            email: this.state.email
          });
          await user.updateProfile({ displayName: this.state.name });
          await user.sendEmailVerification();
          const idTokenResult = await user.getIdTokenResult();
          this.setState({ isError: false, redirectAllowed: true });
        }


      } catch (error) {
        this.setState({ isError: true, errorMessage: error.message, redirectAllowed: true});
      }
    }

  //   firebase.auth().createUserWithEmailAndPassword(email, password)
  // .then(function() {
  //     console.log('creating the user');
  //     user = firebase.auth().currentUser;
  //     console.log('should send email now');
  //     analytics.track('user sign-up', {
  //       firstname: firstname,
  //       lastname: lastname,
  //       email: email
  //     });
  //     user.sendEmailVerification();
  //   })
  // .then(function(){
  //     console.log('trying to update user', user);
  //     const displayName = firstname + " " + lastname;
  //     user.updateProfile({
  //       displayName: displayName
  //     });
  //   })
  // .then(function(){
  //     console.log('should redirect now');
  //     // redirectUser(user);
  //   })
  // .catch(function(error) {
  //     // Handle Errors here.
  //     var errorCode = error.code;
  //     var errorMessage = error.message;
  //     console.log('errorCode: ', errorCode);
  //     console.log('errorMessage: ', errorMessage);
  //     $('.w-form-fail div').text(errorMessage);
  //     $('.w-form-fail').fadeIn();
  //     return;
  //   });

    componentDidUpdate(prevProps, prevState, snapshot){
    }

    handleSubmit(){
    }

    // interaction
    render() {

      if(this.props.firebaseUser && this.state.redirectAllowed){
        return (<Redirect to='/new-organization'  />)
      }

      return (
        <div className="full-height">
          <div className="login-panel-right">
            <div className="login-panel-right-header-copy">
              <Link to="/" className="logo-login-container w-inline-block"><img
              src="images/zerolens-logo.svg" alt="" /></Link>
              <Link to="/login" className="link-block w-inline-block">
                <div className="text-block-6">Log In</div>
              </Link>
            </div>
            <div className="div-block-17">
              <div className="div-block-18">
                <h1 className="heading-3">Get your zerolens account now.</h1>
                <div className="w-form">
                  <form id="email-form" name="email-form" data-name="Email Form" onSubmit={ (e) => {e.preventDefault(); this.handleSignup()}}>
                    <div className="div-block-14">
                      <input type="text" className="login-text-field firstname w-input"
                             maxLength="256" name="firstname" data-name="Firstname"
                             placeholder="Full Name" id="firstname" onChange={ (e) => { this.handleNameChange(e)  } }  />
                    </div>
                    <input type="text" className="login-text-field w-input" maxLength="256" name="username"
                           data-name="Username" placeholder="E-mail adress" id="username" onChange={ (e) => { this.handleEmailChange(e)  } }  />
                      <input type="password"
                             className="login-text-field w-input"
                             maxLength="256"
                             name="password"
                             data-name="Password"
                             placeholder="password"
                             id="password"
                             onChange={ (e) => { this.handlePasswordChange(e)  } }
                      />
                      <input
                      type="submit" value="Let&#x27;s Start" data-wait="Please wait..."
                      className="regular-button full-width bold w-button" onClick={ () => { this.handleSignup() } } />
                  </form>


                  <ErrorMessageSignUp isError={this.state.isError} errorMessage={this.state.errorMessage} isSuccess={this.state.isSuccess} />

                </div>
                <div className="text-block-7">We use your email address to send activation emails and information about
                  your account.<br /><br />or simply log in using</div>
                <div className="social-login-buttons"><div className="social-login w-inline-block" onClick={ this.props.signUpWithGoogle }><img
                  src="images/1024px-Google__G__Logo.svg.png" alt="" className="social-logo large" />
                  <div className="text-block-8">Google</div></div></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);





export default (SignUp);
