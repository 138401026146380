import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import ProductPreview from './ProductPreview';
import { Placeholder } from 'semantic-ui-react';
import { Menu } from 'react-instantsearch-dom';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import Masonry from 'react-masonry-css';






import CustomSearchBox from './CustomSearchBox';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  Hits,
  SearchBox,
  Pagination,
  Highlight,
} from 'react-instantsearch-dom';
import InfiniteHitsScrollObjects from './CustomInfiniteHitsScrollObjects';

const DEBOUNCE_TIME = 400;
const searchClient = algoliasearch(
  'DSARB83COY',
  '1e1748b97732b49386fb7e2f2bcc8ff2',
);

const breakpointColumnsObj = {
  default: 3,
  950: 2,
  700: 2,
  500: 1
};

function convertAssetBundleLinkToNewFormt(assetbundle, type){
  if (assetbundle.includes("http")){
    if(type === 'product'){
      assetbundle = 'products/' +assetbundle.split('/products/')[1];
    }else{
      assetbundle = 'lifestyleobjects/' + assetbundle.split('/lifestyleobjects/')[1];
    }
  }
  return assetbundle;
}

const ViewerSettings = observer(class ViewerMenu extends Component {
  state = {
    products: null,
    objects: null,
    devObjects: null,
    searchState: {},
    defaultRefinement: "",
    currenSearch: "",
    devProducts: [],
    objectsLoadingStatus: null,
    objectLoadingStopStatus: null,
  };

  changeSearch(newSearch) {
    this.setState({ currentSearch: newSearch });
  }

  constructor(props) {
    super(props);
    this.unityContent = this.props.unityContent;
    this.data = [];
    this.changeSearch = this.changeSearch.bind(this);
    // this.handleClick = this.handleClick.bind(this);

    this.unityContent.on("ExternalDeselectProducts", () => {

    });


    this.unityContent.on("removeProductAnimation", () => {
      // TODO:
    });


    this.unityContent.on("objectLoadingProgressUpdate", (argument) => {
      var argumentArray = argument.split("␞");
      const currId = argumentArray[0];

      const currProgress = argumentArray[2];
      const currType = argumentArray[1];
      if (currType === "product") {
        // update state of object to loading
        this.updateLoadingAnimationForProductsAndObjects(currId, currType, Number(currProgress), true);

      } else {
        this.setState({ objectsLoadingStatus: argument });
      }

    });


    this.unityContent.on("stopObjectLoadingSpinner", (argument) => {
      var argumentArray = argument.split("␞");

      const currId = argumentArray[0];
      const currProgress = argumentArray[2];
      const currType = argumentArray[1];
      if (currType === "product") {
        console.log('ARGUMENTARRAY MY: ', argumentArray);
        // update state of object to loading
        this.updateLoadingAnimationForProductsAndObjects(currId, currType, false, false);
      } else {
        this.setState({ objectLoadingStopStatus: argument });
      }
    });

    // this.unityContent.on("assetBundleDownloadProgressProductUpdate", (percent) => {
    //   // TODO:
    //   console.log('UNITY: assetBundleDownloadProgressProductUpdate', percent);
    // });
  }

  updateLoadingAnimationForProductsAndObjects(currId, currType, progress, isLoading) {
    console.log('progress: ', progress);
    if (currType == 'product') {
      var currentObject = this.state.products.find(x => x.id === currId);
      if (currentObject) {
        currentObject.isLoading = isLoading;
        currentObject.progress = progress;
        this.setState({ products: this.state.products });
        console.log('PRODUCT FOUND!!!');
      }

      // }else if(currType == "obj"){
      //   var currentObject = this.state.objects.find(x => x.id === currId);
      //   currentObject.isLoading = isLoading;
      //   currentObject.progress = progress;
      //   this.setState({ objects: this.state.objects });
      // }else if(currType = "devobj"){
      //   var currentObject = this.state.devObjects.find(x => x.id === currId);
      //   currentObject.isLoading = isLoading;
      //   currentObject.progress = progress;
      //   this.setState({ devObjects: this.state.devObjects });
      // }
    }
  }


  currentDownloadId = "";
  handleClick = (event, id, assetbundle, type, isSwap, modifiers) => {
    event.preventDefault();
    // checks if assetbundle is already the new type or the old type, if old type, convert to new format
    assetbundle = convertAssetBundleLinkToNewFormt(assetbundle, type);
    console.log('new assetbundle format: ', assetbundle);

    this.currentDownloadId = id;

    // update state of object to loading


    if (!(type === 'product')) {
      type = 'obj';
    } else {
      this.updateLoadingAnimationForProductsAndObjects(id, type, 0, true);
    }

    // this.setState({ products: this.state.products, objects: this.state.objects });

    const modifierArgument = modifiers ? "␞" + JSON.stringify(modifiers) : "";
    const argument = id + "␞" + assetbundle + "␞" + type + modifierArgument;
    // TODO: ADD SWAP CALL
    if (isSwap) {
      console.log('swap mode');
      this.unityContent.send('SceneLoader', "replaceSelectedAndLoadNewProductOrObject", argument);
    } else {
      this.unityContent.send('SceneLoader', 'loadNewProductOrObject', argument);
    }

    window.analytics.track('Add Object', { objectId: id, assetbundle: assetbundle, type: type });

  };




  render() {

    return (
      <div className={(this.props.isActive ? '' : 'hidden')}>

        <div className="objects-container">


          <InstantSearch searchClient={searchClient}
                         indexName={"zerolens_objects"}
            // searchState={this.state.searchState}
            // onSearchStateChange={this.onSearchStateChange}
            //createURL={createURL}
          >
            <CustomSearchBox
              onFocus={() => { this.unityContent.send('Network', 'focusCanvas', "0") }}
              onBlur={() => { this.unityContent.send('Network', 'focusCanvas', "1") }}
              translations={{ placeholder: 'Search for Objects',  }}
              currentSearch={ this.state.currentSearch }
              onChangeFunction={ this.changeSearch }
            />

            <div className="addobjectscategories">
              {
                this.props.cms && this.props.cms.main && this.props.cms.main.ObjectsFilter ?
                  (
                    this.props.cms.main.ObjectsFilter.map(filter => (
                      <div
                        className={"colorlinkcategory " + (this.state.currentSearch === filter.search ? "active" : "")}
                        onClick={() => {
                          this.changeSearch(this.state.currentSearch === filter.search ? "" : filter.search)
                        }}>{filter.title}</div>
                    ))
                  )

                  :

                  (<div></div>)
              }
            </div>

            <div className="scrollable-container">

              {
                Hits  ?
                  (
                    <>
                      <InfiniteHitsScrollObjects hits={Hits} unityContent={this.props.unityContent}
                                                 loading={this.state.objectsLoadingStatus}
                                                 stopping={this.state.objectLoadingStopStatus}/>
                      {
                        window.location.origin !== "https://studio.zerolens.com" ?
                        (
                          <InstantSearch searchClient={searchClient}
                                         indexName={"dev_zerolens_objects"}
                            //searchState={this.state.searchState}
                            //onSearchStateChange={this.onSearchStateChange}
                            //createURL={createURL}
                          >
                            <span>------ DEV OBJECTS ------ </span>
                            <InfiniteHitsScrollObjects hits={Hits} unityContent={this.props.unityContent} isDev={true}
                                                       loading={this.state.objectsLoadingStatus}
                                                       stopping={this.state.objectLoadingStopStatus}/>
                          </InstantSearch>
                        )

                        :

                        (<div></div>)
                      }
                    </>
                  )

                  :

                  (
                    <div className={(this.props.isActive ? '' : 'hidden')}>
                      <Placeholder>
                        <Placeholder.Image square/>
                      </Placeholder>
                      <Placeholder>
                        <Placeholder.Image square/>
                      </Placeholder>
                      <Placeholder>
                        <Placeholder.Image square/>
                      </Placeholder>
                      <Placeholder>
                        <Placeholder.Image square/>
                      </Placeholder>
                      <Placeholder>
                        <Placeholder.Image square/>
                      </Placeholder>

                    </div>
                  )
              }
            </div>
          </InstantSearch>
        </div>
      </div>
    )
  }
});


export default withFirestore(ViewerSettings);
