import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css'
import './index.css';
import './css/normalize.css';
import './css/webflow.css';
import './css/empty-setup.webflow.css';
import './css/custom.css'
import IndexApp from './IndexApp';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import firebase from '@firebase/app';
import '@firebase/firestore';
import '@firebase/auth';
import { FirestoreProvider } from 'react-firestore';



ReactDOM.render(
  <Router>
    <IndexApp />
  </Router>,
  document.getElementById('root')
);


