import React, { Component, location, history } from 'react';
import { Link } from "react-router-dom";
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore } from 'react-firestore';


// import { observer } from 'mobx-react';
import UserMenu from '../UserMenu/UserMenu';


const HeaderBackend = observer(
  class HeaderBackend extends Component {

    // interaction
    render() {
      return (
        <div className="backend-header-container">

          <div className="div-block-58">
            <h1 className="heading-2">{this.props.title}</h1>
            { (this.props.buttonText && this.props.buttonType === 'link' ) &&  (
              <a className="backend-add-button" href={this.props.buttonLink} target="_blank">
                <div>{this.props.buttonText}</div>
              </a>
            )}

          </div>
          <div className="user-backend-container">
            <UserMenu firebase={this.props.firebase} handleUserUpdate={this.props.handleUserUpdate} org={this.props.org} />
            <Link to="#" className="user-image-char w-inline-block">
              <div>{this.props.user ? (this.props.user.displayName ? this.props.user.displayName.charAt(0).toUpperCase() : "U") : "U"}</div>
            </Link>
            <div className="backend-user-name">{ this.props.user ? (this.props.user.displayName ? this.props.user.displayName : "username") : "username"}</div>
          </div>
        </div>
      );
    }
  }
);





export default withFirestore(HeaderBackend);

