import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore } from 'react-firestore';
import { Dimmer, Loader, Image, Segment } from 'semantic-ui-react';
import whiteAddSymbol from './../../../images/noun_add_2664285_1.svg';



const ProductPreview = observer(class ViewerMenu extends Component {

  render() {
    const { onPress } = this.props;
    return (
        <Segment className="object-container" onClick={onPress}>
          <Dimmer active={this.props.isLoading} disabled={!this.props.isLoading} inverted>
            <Loader inverted content={this.props.loadingText + "... " + Math.round(this.props.progress * 100) + " %"} />
          </Dimmer>
          <img src={this.props.preview} alt="" className="image-8"/>
          <div className="object-title-container">
            <div className="object-title-text">{this.props.name}</div>
          </div>
          <div className="object-add-hover">
            {this.props.isSwapModeOn && (
              <div className="add-normally-button" onClick={this.props.onPressAdd}><img src={whiteAddSymbol} alt="" className="image-10" />
                <div className="text-block-25">add without swap</div>
              </div>
            )}
          </div>
        </Segment>
    );
  }
});


export default ProductPreview;
