import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import InfiniteScroll from 'react-infinite-scroller';
import SVG from 'react-inlinesvg';
import ReactTimeAgo from 'react-time-ago'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'



import ColorPicker from './../../helpers/ColorPicker/color-picker';
import { parseColor } from './../../helpers/ColorPicker/utils';


// import { observer } from 'mobx-react';
const NUMBER_OF_DOCS = 10;


const ProductDetail = observer(
  class ProductDetail extends Component {


    state = {
      newColor: parseColor('#999999'),
      isColorPickerOpen: false,
      modalOpen: false,
      modalColor: undefined
    }

    constructor(props){
      super(props);
      this.changeColor = this.changeColor.bind(this);
      this.handleDecline = this.handleDecline.bind(this);
      this.handleAccept = this.handleAccept.bind(this);
      this.handleClose = this.handleClose.bind(this);
      this.handleOpen = this.handleOpen.bind(this);
      this.handleRemoveColor = this.handleRemoveColor.bind(this);
    }

    changeColor(color){
      this.setState({ newColor: color });
    }

    componentDidUpdate(prevProps){
      if(prevProps.activeProduct != this.props.activeProduct){
        this.setState({ isColorPickerOpen: false })
      }
    }

    handleDecline(){
      this.setState({ isColorPickerOpen: false })
    }

    handleAccept(){
      const { firestore } = this.props;
      var newColorArray = this.props.activeProduct.productColorRecommondation;

      if(Array.isArray(newColorArray)){
        newColorArray.push(this.state.newColor.hex.toString());
      }else{
        newColorArray = [this.state.newColor.hex.toString()];
      }


      firestore.collection('Products').doc(this.props.activeProduct.id).update({ productColorRecommondation: newColorArray  })
        .then(() => {
          this.props.updateColorRecOfActiveProduct(newColorArray);
          this.setState({ isColorPickerOpen: false });
        }
      )
    }

    handleOpen(color){
      this.setState({ modalOpen: true, modalColor: color  });
    }

    handleClose(){
      this.setState({ modalOpen: false });
    }

    handleRemoveColor(color){
      const { firestore } = this.props;

      var newColorArray = this.props.activeProduct.productColorRecommondation || [];
      console.log('NEW COLOR ARRAY: color', color);
      console.log('NEW COLOR ARRAY: ', newColorArray);

      var index = newColorArray.indexOf(color);
      if (index !== -1) newColorArray.splice(index, 1);

      console.log('NEW COLOR ARRAY: ', newColorArray);

      firestore.collection('Products').doc(this.props.activeProduct.id).update({ productColorRecommondation: newColorArray  })
        .then(() => {
            this.props.updateColorRecOfActiveProduct(newColorArray);
            this.setState({ isColorPickerOpen: false });
            this.handleClose();
          }
        )
    }





    // interaction
    render() {
      return (
        <div className="product-detail-panel">
          <div className="text-block-52 center">{this.props.activeProduct.name}</div>
          <img src={this.props.activeProduct.preview} alt="" className="product-preview-detail-view" />
          <div className="product-colors-container-detail-view" style={{ position: 'relative' }}>
            { (this.props.activeProduct.productColorRecommondation || []).map(color => (
              <div className="product-single-color-swatch-detail-view " style={{backgroundColor: color}}>
                <SVG className={"delte-color-swatch"}  src= './svgLoader/bin.svg' onClick={() => {this.handleOpen(color)}} />
              </div>
            ))
            }

            { !this.state.isColorPickerOpen ?
              (
                <div className="new-color-detail-view-button" onClick={() => {this.setState({isColorPickerOpen: true})}}>
                  <div className="text-block-55">New Color +</div>
                </div>
              ) :
              (<div className="product-single-color-swatch-detail-view " style={{backgroundColor: this.state.newColor.hex}}></div>)
            }


            { this.state.isColorPickerOpen && (
              <div style={{position: 'absolute', left: '20px', bottom: '10px'}}>
                <div className={"zl-tooltip light"}>
                  <ColorPicker
                    color={this.state.newColor}
                    onChange={this.changeColor}
                    recommendedColors={[]}
                    showAcceptScreen={true}
                    onDecline={this.handleDecline}
                    onAccept={this.handleAccept}
                    onClose={this.handleDecline}
                  />
                </div>
              </div>
            )
            }
          </div>

          <Modal basic size='small'
                 open={this.state.modalOpen}
                 onClose={this.handleClose}
          >
            <Header icon='archive' content='Delete Color Swatch' />
            <Modal.Content>

              <p>
                <span className="product-single-color-swatch" style={{backgroundColor: this.state.modalColor, width: '50px', height: '20px', marginRight: '15px', display: 'inline-block', marginBottom: '-5px'}}></span>
                Do you want to delete this color?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color='red' inverted onClick={() => {this.handleClose()}}>
                <Icon name='remove' /> No
              </Button>
              <Button color='green' inverted onClick={() => {this.handleRemoveColor(this.state.modalColor)}}>
                <Icon name='checkmark' /> Yes
              </Button>
            </Modal.Actions>
          </Modal>



        </div>
      );
    }
  }
);





export default withFirestore(ProductDetail);

