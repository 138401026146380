import React, { Component, location, history } from 'react';
import { observer } from 'mobx-react/dist/mobx-react';
import { withFirestore, FirestoreCollection } from 'react-firestore';
import InfiniteScroll from 'react-infinite-scroller';
import { Route, Link } from "react-router-dom";

import SelectedOverlay from './SelectedOverlay';
import NoPhotosYet from './NoPhotosYet';
import TextToInputField from '../../helpers/TextToInputField/TextToInputField'

import axios from 'axios/index';

// import { observer } from 'mobx-react';
const NUMBER_OF_DOCS = 10;

const PhotoElements = observer(
  class PhotoElements extends Component {


    currLastRef = null;
    stillNewSnapshots = true;

    currentListenerPhotos = undefined;

    store = this.props.store;

    constructor(props){
      super(props);
      this.handleSnapshotReload = this.handleSnapshotReload.bind(this);
      this.handleNameChange = this.handleNameChange.bind(this);

      this.state = {
        elements: [],
        stillNewSnapshots: true,
        waitForPrevRequestToFinish: false
      };
    }

    componentDidUpdate(prevProps, prevState, snapshot){
      if(this.props.deleteOn && !prevProps.deleteOn){
        //this.props.stopDeleteModus
        this.deleteSelectedSnapshots();
        this.props.stopDeleteModus();
      }

      if(this.props.reload){
        this.handleSnapshotReload();
        this.props.snapshotsReloadedFunction();
      }

      if(prevProps){
        if(this.props.orgSearch != prevProps.orgSearch){
          this.setState({
            elements: [],
          });
          this.currLastRef = false;
          this.fetchSnapshots();
        }
      }

    }

    handleSnapshotReload(){
      this.setState({
        elements: [],
      });
      this.currLastRef = false;
      if(this.currentListenerPhotos){this.currentListenerPhotos()};

      this.fetchSnapshots();
    }

    selectCurrSnapshot(id){
      var currElements = this.state.elements;
      var currElement = currElements.filter(obj => {
        return obj.id === id
      });
      currElement[0].isSelected = !currElement[0].isSelected;

      this.setState({ elements: currElements });
    }

    handleNameChange(newName, snapshot){
      const { firestore } = this.props;
      const snapshotId = snapshot.id;
      var metadata = snapshot.data().metadata;
      metadata.name = newName;
      firestore.collection('Snapshots').doc(snapshotId).update({metadata: metadata});
    }


    renderSnapshotInHighQuality(snapshotData){
      // TODO change Snapshot format to include supposed Quality if draft == true;
      snapshotData.image.quality = snapshotData.image.supposedQuality;
      this.setState({sendingSnapshotDataOverlay: true});
      axios.post('/api/snapshot/', snapshotData, { withCredentials: true })
        .then((response) => {
          this.setState({
            sendingSnapshotDataOverlay: false
          });
        })
        .catch((error) => {
          console.log('error: ', error);
        });
    }

    deleteSelectedSnapshots(page){
      const { firestore } = this.props;
      var currElements = this.state.elements;
      // var arrayOfIdsToDelete = [];
      currElements.forEach((snapshot) => {
        if (snapshot.isSelected) {

          snapshot.localStatus = "deleted";
          // arrayOfIdsToDelete.push(snapshot.id);
          firestore.collection('Snapshots').doc(snapshot.id).update({status: "deleted"});
        }
      });

      this.setState({ elements: currElements });
    }

    handlePhotoOVerlay(snapshot, e){
      const doNothingIfHasClass = 'backend-photo-information-header';
      console.log('TARGET: ', e.target.className.includes(doNothingIfHasClass));
      if(!e.target.className.includes(doNothingIfHasClass)){
        this.props.selectModus ? this.selectCurrSnapshot(snapshot.id) : this.props.onPress(snapshot || false);
      }{
        // e.preventDefault();
      }
    }

    componentWillUnmount(){
      if(this.currentListenerPhotos){this.currentListenerPhotos()};
    }

    async fetchSnapshots(){
      var self = this;
      const { firestore } = this.props;

      if(this.props.user){

        var currSearchTerm = this.props.user ? this.props.user.uid : "";
        var searchProperty = "uid";
        if(this.props.isOrg){
          // console.log('USER: ', this.props.firebase.auth().currentUser.getIdTokenResult.idTokenResult.claims);

          currSearchTerm =  this.props.user ? (this.props.user.claims.oid || this.props.user.oid)  : "" ;
          searchProperty = "oid";
        }


        if(this.stillNewSnapshots && !this.state.waitForPrevRequestToFinish){
          this.setState({ waitForPrevRequestToFinish: true });
          var currElementsArray = self.state.elements;
          if(!this.currLastRef){
            currElementsArray = [];

            this.currentListenerPhotos =  firestore.collection('Snapshots').where(searchProperty, "==", currSearchTerm).orderBy('createdAt', 'desc').limit(NUMBER_OF_DOCS).onSnapshot(snapshot => {
              const initialLoad = currElementsArray.length == 0 ? true : false;
              snapshot.docs.forEach((doc) => {
                console.log("setting state: first: : ", doc.data());
                var currDoc = doc.data();
                currDoc.id = doc.id;

                var found = currElementsArray.findIndex((element) => element && element.id === currDoc.id);

                // check if this element is only an update
                if(found != -1){
                  if(doc.data().status !== "deleted"){
                    currElementsArray[found] = doc;
                  }else{
                    delete currElementsArray[found];
                  }
                }else{
                  if(doc.data().status !== "deleted"){
                    initialLoad ? currElementsArray.push(doc) : currElementsArray.unshift(doc);
                  }
                }

              });
              this.currLastRef = snapshot.docs[snapshot.docs.length - 1];

              this.setState({ elements: currElementsArray, stillNewSnapshots: snapshot.docs.length < NUMBER_OF_DOCS ? false : true, waitForPrevRequestToFinish: false });
              this.waitForPrevRequestToFinish = false;
            });

            // firestore.collection('Snapshots').where(searchProperty, "==", currSearchTerm).orderBy('createdAt', 'desc').limit(NUMBER_OF_DOCS).get().then(snapshot => {
            //   snapshot.docs.forEach((doc) => {
            //     console.log("setting state: first: : ", doc.data());
            //     var currDoc = doc.data();
            //     currDoc.id = doc.id;
            //     if(doc.data().status !== "deleted"){
            //       currElementsArray.push(doc);
            //     }
            //   });
            //   this.currLastRef = snapshot.docs[snapshot.docs.length - 1];
            //
            //   this.setState({ elements: currElementsArray, stillNewSnapshots: snapshot.docs.length < NUMBER_OF_DOCS ? false : true, waitForPrevRequestToFinish: false });
            //   this.waitForPrevRequestToFinish = false;
            // });
          }else {
            firestore.collection('Snapshots').where(searchProperty, "==", currSearchTerm).orderBy('createdAt', 'desc').startAfter(this.currLastRef).limit(NUMBER_OF_DOCS).get().then(snapshot => {
              snapshot.docs.forEach((doc) => {
                var currDoc = doc.data();
                currDoc.id = doc.id;
                if(doc.data().status !== "deleted"){
                  currElementsArray.push(doc);
                }

              });
              this.currLastRef = snapshot.docs[snapshot.docs.length - 1];

              this.setState({ elements: currElementsArray, stillNewSnapshots: snapshot.docs.length < NUMBER_OF_DOCS ? false : true, waitForPrevRequestToFinish: false });
            });
          }
        }
      }
    }


    // interaction
    render() {
      return (
        <div className="backend-photos-container">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.fetchSnapshots.bind(this)}
            hasMore={ (this.state.stillNewSnapshots && !this.state.waitForPrevRequestToFinish )}
            loader={<div className="loader" key={0}>Loading ...</div>}
          >
            {this.state.elements.length === 0 && (
              <NoPhotosYet />
            )}
            <div className={"div-block-11 " + this.props.listType }>
              {this.state.elements.map((snapshot, i) => (

                <div className={"backend-photo-card " + this.props.listType + (snapshot.localStatus === "deleted" ? " hidden" : "") + " " + "backend-photo-card-" + i } onClick={ (e) => { this.handlePhotoOVerlay(snapshot, e) } }>

                  { (snapshot.data().isDraft && snapshot.data().status === "completed" ) && (
                    <div className={ "div-block-12 square-image-container " + this.props.listType } style={{backgroundSize: "cover", backgroundPosition: "center center", backgroundImage: "url(" + snapshot.data().renderinfo.image_url + ")"}}>
                      { (snapshot.isSelected && this.props.selectModus && this.props.isSelectModusVisible) && (<SelectedOverlay />)}
                      <div className="unlock-container">
                        <div className="locked-symbol w-embed">
                          <span dangerouslySetInnerHTML={{__html: "<svg height=\"100%\" width=\"100%\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" style=\"enable-background:new 0 0 100 100;\" xml:space=\"preserve\"><g><path d=\"M75.8,40.4H37.9V27.7c0-8,6.5-14.5,14.5-14.5c3.9,0,7.5,1.5,10.2,4.3c2.3,2.3,3.8,5.3,4.1,8.5l0,0l10.7-4   C76.3,17.3,74,13,70.5,9.6C65.7,4.7,59.2,2,52.4,2C38.2,2,26.7,13.5,26.7,27.7v12.9c-4,1-7,4.6-7,9v39.1c0,5.1,4.2,9.3,9.3,9.3   h46.9c5.1,0,9.3-4.2,9.3-9.3V49.6C85.1,44.5,80.9,40.4,75.8,40.4z M55.8,71.1c0,0.2,0,0.3,0,0.5v6.6c0,1.9-1.5,3.5-3.5,3.5   c-1.9,0-3.5-1.5-3.5-3.5v-6.6c0-0.2,0-0.3,0-0.5c-1.9-1.2-3.2-3.3-3.2-5.7c0-3.7,3-6.6,6.6-6.6c3.7,0,6.6,3,6.6,6.6   C59,67.9,57.7,70,55.8,71.1z\"></path></g></svg>" }} />
                        </div>
                        <div className="text-block-46">Click to See <br />Unlock Options</div>
                      </div>

                      <Link className={"editLinkPhoto"} to={"/sid/" + snapshot.id} className={"edit-render w-inline-block "  + this.props.listType } onClick={ () => {  window.analytics.track('Edit Snapshot', { sid: snapshot.id }); }}>
                        <img
                          src="https://uploads-ssl.webflow.com/5c586a9cecb8b00a18b8fd1c/5d7fa697cbe7bd07087583c3_noun_edit_1360261.svg"
                          alt="" />
                      </Link>
                    </div>
                  )}

                  {( snapshot.data().status === "completed" && !snapshot.data().isDraft ) && (
                    <div className={ "div-block-12 square-image-container " + this.props.listType } style={{backgroundSize: "cover", backgroundPosition: "center center", backgroundImage: "url(" + snapshot.data().renderinfo.image_url + ")"}}>
                      { (snapshot.isSelected && this.props.selectModus && this.props.isSelectModusVisible) && (<SelectedOverlay />)}
                      <Link to={"/sid/" + snapshot.id} className={"edit-render w-inline-block "  + this.props.listType } onClick={ () => {  window.analytics.track('Edit Snapshot', { sid: snapshot.id }); }}>
                        <img
                          src="https://uploads-ssl.webflow.com/5c586a9cecb8b00a18b8fd1c/5d7fa697cbe7bd07087583c3_noun_edit_1360261.svg"
                          alt="" />
                      </Link>
                    </div>
                  )}
                  {snapshot.data().status === "created" && (
                    ///
                    <div className={"div-block-12 square-image-container "  + this.props.listType } >
                      <div className={"backend-other-status-container "  + this.props.listType }>
                        <div className="text-block-29">on Waitlist</div>
                        <div className={"previewImgRender"} style={{backgroundSize: "cover", backgroundPosition: "center center", backgroundImage: "url(" + snapshot.data().rawImg + ")"}} >

                        </div>
                      </div>
                        <Link to={"/sid/" + snapshot.id} className={"edit-render w-inline-block " + this.props.listType } onClick={ () => { window.analytics.track('Edit Snapshot', { sid: snapshot.id }); }}>
                          <img
                            src="https://uploads-ssl.webflow.com/5c586a9cecb8b00a18b8fd1c/5d7fa697cbe7bd07087583c3_noun_edit_1360261.svg"
                            alt="" />
                        </Link>
                    </div>

                  )}
                  {snapshot.data().status === "rendering" && (
                    <div className={"div-block-12 square-image-container "  + this.props.listType } >
                      <div className={ "backend-other-status-container "  + this.props.listType + " rendering" }>
                        <div className="text-block-29">Processing</div>
                        <div className={"previewImgRender"} style={{backgroundSize: "cover", backgroundPosition: "center center", backgroundImage: "url(" + snapshot.data().rawImg + ")"}} >

                        </div>
                      </div>
                      <Link to={"/sid/" + snapshot.id} className={"edit-render w-inline-block " + this.props.listType } onClick={ () => { window.analytics.track('Edit Snapshot', { sid: snapshot.id }); }}>
                        <img
                          src="https://uploads-ssl.webflow.com/5c586a9cecb8b00a18b8fd1c/5d7fa697cbe7bd07087583c3_noun_edit_1360261.svg"
                          alt="" />
                      </Link>
                    </div>
                  )}





                  {snapshot.data().isDraft ?  (
                    <div className={"backend-photo-information-conatiner locked  "   + this.props.listType + " " + " "} >
                      <div className="div-block-41">
                        <div className={"backend-photo-information-img "   + this.props.listType + " " + (snapshot.data().status === "created" ? "waitlist" : "") + (snapshot.data().status === "rendering" ? "rendering" : "" )} style={{backgroundImage: "url(" + snapshot.data().renderinfo.image_url + ")"}}></div>
                        <div className={"div-block-10" + this.props.listType }>
                          <div className={"backend-photo-information-header "  + this.props.listType }><TextToInputField text={snapshot.data().metadata.name ? snapshot.data().metadata.name : snapshot.data().metadata.title} /></div>
                          <div className={ "backend-photo-information-subtext "  + this.props.listType }>{snapshot.data().image.quality} {this.props.orgSearch ? "| " + snapshot.data().renderinfo.email : ""}</div>
                        </div>
                      </div>
                      <div className="unlock-cloud-button w-embed">
                        <span dangerouslySetInnerHTML={{__html: "<svg height=\"100%\" width=\"100%\" fill=\"currentColor\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" version=\"1.1\" x=\"0px\" y=\"0px\" viewBox=\"0 0 100 100\" style=\"enable-background:new 0 0 100 100;\" xml:space=\"preserve\"><g><path d=\"M78.1,40.7v-13c0-6.9-2.7-13.3-7.5-18.2C65.7,4.7,59.2,2,52.4,2C38.2,2,26.7,13.5,26.7,27.7v13   c-4,1-7,4.6-7,9v39.1c0,5.1,4.2,9.3,9.3,9.3h46.9c5.1,0,9.3-4.2,9.3-9.3V49.6C85.1,45.3,82.1,41.7,78.1,40.7z M52.4,13.2   c3.9,0,7.5,1.5,10.2,4.2c2.7,2.7,4.2,6.4,4.2,10.2v12.7h-29V27.7C37.9,19.7,44.4,13.2,52.4,13.2z M55.8,71.1c0,0.2,0,0.3,0,0.5v6.6   c0,1.9-1.5,3.5-3.5,3.5c-1.9,0-3.5-1.5-3.5-3.5v-6.6c0-0.2,0-0.3,0-0.5c-1.9-1.2-3.2-3.3-3.2-5.7c0-3.7,3-6.6,6.6-6.6   c3.7,0,6.6,3,6.6,6.6C59,67.9,57.7,70,55.8,71.1z\"></path></g></svg>" }} />
                      </div>
                    </div>

                    ) :
                      (
                        <div className={"backend-photo-information-conatiner "   + this.props.listType }>
                          <div className={"backend-photo-information-img "   + this.props.listType + " " + (snapshot.data().status === "created" ? "waitlist" : "") + (snapshot.data().status === "rendering" ? "rendering" : "" )} style={{backgroundImage: "url(" + snapshot.data().renderinfo.image_url + ")"}}></div>
                          <div className={"div-block-10"    + this.props.listType }>
                            <div className={"backend-photo-information-header "  + this.props.listType }><TextToInputField changeHandler={this.handleNameChange} changeHandlerProps={snapshot} text={snapshot.data().metadata.name ? snapshot.data().metadata.name : (snapshot.data().metadata.title || 'no name' ) } class={'backend-photo-information-header'} /></div>
                            <div className={ "backend-photo-information-subtext "  + this.props.listType }>{snapshot.data().image.quality} {this.props.orgSearch ? "| " + snapshot.data().renderinfo.email : ""}</div>
                          </div>
                        </div>
                      )}
                </div>
              ))}
            </div>
          </InfiniteScroll>

        </div>
      );
    }
  }
);





export default withFirestore(PhotoElements);

